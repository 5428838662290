const transformFormData = ({ values, userId, disabled, numRef }) => {
	const codeudores = [];
	for (let i = 0; i < disabled.length; i++) {
		if (disabled[i]) continue;
		codeudores.push({
			nombre: values[`nombres_cod${i}`],
			apellido: values[`apellidos_cod${i}`],
			fechaNacimiento: values[`fecha_nacimiento_cod${i}`],
			documento: values[`documento_cod${i}`],
			fechaExpedicion: values[`fecha_expedicion_cod${i}`],
			celular: values[`celular_cod${i}`],
			telefono: values[`telefono_cod${i}`],
			correoElectronico: values[`correo_electronico_cod${i}`],
			direccion: values[`direccion_cod${i}`],
			ciudadExpedicionCod: values[`ciudad_expedicion_cod${i}`],
			estadoCivilId: values[`estado_civil_cod${i}`],
			tipoDocumentoId: values[`tipo_documento_cod${i}`],
			parentescoId: values[`parentesco_cod${i}`],
			ciudadResidenciaCod: values[`ciudad_residencia_cod${i}`],
			...(values[`tipo_documento_cod${i}`].toString() !== '3' && {
				ciudadNacimientoCod: values[`ciudad_nacimiento_cod${i}`],
			}),
			actividadEconomica: {
				ingresos: values[`ingresos_cod${i}`],
				fuenteIngresosAlt: values[`fuente_ingresos_alt_cod${i}`],
				ingresosAlt: values[`ingresos_alt_cod${i}`],
				egresos: values[`egresos_cod${i}`],
				ocupacionId: values[`ocupacion_cod${i}`],
			},
		});
	}
	const referencias = [];
	for (let i = 0; i < numRef; i++) {
		const tipoReferenciaId = values[`tipo_ref${i}`];
		if (!tipoReferenciaId || tipoReferenciaId === '') continue;
		referencias.push({
			nombre: values[`nombre_ref${i}`],
			celular: values[`celular_ref${i}`],
			correo: values[`correo_ref${i}`],
			tipoReferenciaId,
		});
	}
	values.trabaja = values.tipo_documento.toString() === '2' ? 'false' : values.tipo_documento.toString() === '1' && values.trabaja.toString() === 'false'? 'false' : 'true';
	const data = {
		credito: {
			usuarioId: userId,
			...(values.programa.toString() !== 'otro' && {
				...(values.periodo_cursa && {
					continuaPrograma: values.periodo_cursa > 1,
					periodoCursa: values.periodo_cursa,
				}),
				programaCod: values.programa,
				jornadaId: values.jornada,
			}),
			plazo: values.plazo,
			nivelAcademicoId: values.nivel,
			universidadId: values.universidad,
			lineaId: values.linea,
			taxCategory: values.tax_category,
			valorMatricula: values.valor_matricula,
			valorFinanciar: values.valor_financiar,
			estudiante: {
				nombre: values.nombres,
				apellido: values.apellidos,
				fechaNacimiento: values.fecha_nacimiento,
				documento: values.documento,
				fechaExpedicion: values.fecha_expedicion,
				celular: values.celular,
				telefono: values.telefono,
				correoElectronico: values.correo_electronico,
				direccion: values.direccion,
				estadoCivilId: values.estado_civil,
				tipoDocumentoId: values.tipo_documento,
				ciudadExpedicionCod: values.ciudad_expedicion,
				ciudadResidenciaCod: values.ciudad_residencia,
				...(values.tipo_documento.toString() !== '3' && {
					ciudadNacimientoCod: values.ciudad_nacimiento,
				}),
				...(values.trabaja.toString() === 'true'
					? {
							actividadEconomica: {
								ingresos: values.ingresos,
								fuenteIngresosAlt: values.fuente_ingresos_alt,
								ingresosAlt: values.ingresos_alt,
								egresos: values.egresos,
								ocupacionId: values.ocupacion,
							},
					  }
					: {
							responsableEconomicoId: values.responsable_economico,
					  }),
			},
			codeudores,
			referencias,
		},
	};
	// console.log(JSON.stringify(data, null, 4))
	return data;
};

export default transformFormData;
