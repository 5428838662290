import React from 'react';

const Dialog = ({
  isOpen,
  bgImage,
  children,
  devInfo,
  actions,
  textBelowActions,
  className,
  spacing = true,
  
}) =>
  isOpen ? (
    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center overflow-y-scroll bg-[#EDEDEDB2] bg-opacity-70 p-4 backdrop-blur-sm backdrop-filter dark:bg-custom-gray dark:bg-opacity-10">
      <div
        className={`rounded-3xl bg-cover bg-center shadow-light-active dark:shadow-dark-active ${
          bgImage
            ? 'bg-custom-green text-dark bg-blend-multiply dark:bg-custom-purple'
            : 'bg-light text-input-light dark:bg-dark dark:text-input-dark'
        }`}
        style={bgImage ? { backgroundImage: `url(${bgImage})` } : {}}
      >
        <div
          className={`w-full max-w-4xl mx-auto text-light dark:text-dark ${
            spacing ? 'p-8 md:p-16 space-y-4' : ''
          } ${className || ''}`}
        >
          {children}
          {devInfo && (
            <div className="rounded-lg border p-2 text-2xs leading-tight opacity-75">
              <p className="">• Información para el desarrollador:</p>
              <p className="indent-4">{devInfo}</p>
              <p className="text-right opacity-50">
                {new Date().toLocaleString()}
              </p>
            </div>
          )}
        </div>
        {actions && (
          <div className="flex w-full justify-center space-x-2 pb-8">
            {actions}
          </div>
        )}
        {textBelowActions && (
          <div >
            {textBelowActions}
          </div>
        )}
      </div>
      
    </div>
  ) : null;

export default Dialog;
