import { API } from 'aws-amplify';

const postOpenCoop = usuarioId =>
	API.post('estrella', '/consult-credit', {
		body: { nro_identificacion: usuarioId },
	});

const planPaymentsOpenCoop = (usuarioId) =>
	API.post('estrella', '/down-payment-plan', {
		body: { nro_identificacion: usuarioId },
	});
const FinancialStatementService = {
	postOpenCoop,
	planPaymentsOpenCoop
};

export default FinancialStatementService;
