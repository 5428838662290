import React from 'react';

// This component can be used to display any value with the styles of an input field
const DisplayValue = ({ title, value }) => {
  return (
    <div className="relative" title={title}>
      {/* Label */}
      <span className="pointer-events-none absolute inset-y-0 flex h-full w-full origin-left -translate-y-3/4 items-center text-2xs text-custom-green">
        {title}
      </span>
      {/* Value */}
      <span className="block w-full flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-light bg-opacity-100 px-4 py-2 shadow-light transition duration-500 dark:bg-dark dark:shadow-dark">
        {value}
      </span>
    </div>
  );
};

export default DisplayValue;
