import { useState } from 'react';
import ArchivoService from '../services/file/archivo-service';
import { FILE_STATE } from '../constants/files/fileState';
import { Storage } from 'aws-amplify';

const useUpload = ({ loanId, fileId, fileName, prefix }) => {
  const [uploadProgress, setUploadProgress] = useState();
  const [fileData, setFileData] = useState();
  const [fileInfo, setFileInfo] = useState({
    loanId,
    fileId,
    fileName,
    prefix,
  });

  const upload = async (props = {}) => {
    const currentFileInfo = { ...fileInfo, ...props };
    setFileInfo(currentFileInfo);
    if (
      currentFileInfo.loanId === undefined ||
      currentFileInfo.fileName === undefined
    ) {
      throw new Error(
        'Debes proporcionar la información del archivo antes de enviar'
      );
    }
    console.log('pasa');
    if (fileData === undefined) {
      throw new Error('Debes cargar un archivo antes de enviar');
    }
    let response;
    try {
      const { key } = await Storage.put(
        (currentFileInfo.prefix ? currentFileInfo.prefix + '/' : '') +
          currentFileInfo.fileName +
          '.pdf',
        fileData,
        {
          contentType: 'application/pdf',
          cacheControl: 'no-cache',
          customPrefix: {
            public: `creditos/${currentFileInfo.loanId}/`,
          },
          progressCallback: (progress) => {
            setUploadProgress((progress.loaded / progress.total) * 100);
          },
        }
      );
      if (currentFileInfo.fileId !== undefined)
        await ArchivoService.update({
          archivoId: currentFileInfo.fileId,
          nombre: currentFileInfo.fileName + '.pdf',
          nombreOriginal: fileData.name,
          ruta: key,
          estado: FILE_STATE.UNDER_REVIEW,
        });
      response = key;
    } catch (error) {
      console.error(error);
      throw error;
    }
    setUploadProgress();
    return response;
  };

  const load = (fileData) => setFileData(fileData);

  const clearData = () => setFileData();

  return { upload, load, clearData, uploadProgress, fileData, setFileInfo };
};

export default useUpload;
