import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Spinner from '../shared/Spinner';

const Input = ({
  name,
  placeholder,
  type = 'text',
  value,
  className,
  register,
  validation,
  errors,
  disabled = false,
  loading = false,
  preventCopy = false,
  preventPaste = false,
  ...otherProps
}) => {
  const [active, setActive] = useState(loading || value?.length > 0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setActive(loading || value?.length > 0);
  }, [loading]);

  // input props
  const props = {
    ...otherProps,
    ...(typeof register === 'function'
      ? register(name, validation)
      : { value }),
  };
  const onBlur = props.onBlur;
  props.onBlur = (event) => {
    setActive(loading || event.target.value.length > 0);
    if (typeof onBlur === 'function') onBlur(event);
  };
  props.onFocus = () => setActive(true);
  props.onAnimationStart = (e) => {
    if (e.animationName === 'onAutoFillStart') setActive(true);
  };
  if (preventPaste)
    props.onPaste = (e) => {
      e.preventDefault();
      return false;
    };
  if (preventCopy)
    props.onCopy = (e) => {
      e.preventDefault();
      return false;
    };

  const iconProps = (show) => {
    return {
      className: `w-4 fill-current ${show ? 'block' : 'hidden'}`,
      onClick: () => {
        setShow((current) => !current);
      },
    };
  };
  return (
    <div
      className={`relative ${
        errors ? 'text-error' : 'text-input-light dark:text-input-dark'
      } ${className}`}
    >
      <input
        id={name}
        title={placeholder}
        type={type === 'password' && show ? 'text' : type}
        disabled={loading || disabled}
        {...props}
        className={`form-Input block min-h-full w-full flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-light bg-opacity-100 py-2 pl-4 pr-10 shadow-light transition duration-500 dark:bg-dark dark:shadow-dark ${
          disabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
      />

      {/* Loading spinner */}
      {loading ? (
        <div className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-6" />
        </div>
      ) : undefined}

      {/* Label */}
      <div
        className={`pointer-events-none absolute flex h-full w-full origin-left items-center transition-all duration-500 ${
          active
            ? `-inset-y-2 -translate-y-1/2 text-2xs ${
                errors ? '' : 'text-custom-green'
              }`
            : `inset-y-0 px-4 ${type === 'password' ? 'pr-10' : ''} ${
                disabled ? 'opacity-50' : ''
              }`
        }`}
      >
        <label
          htmlFor={name}
          className="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {placeholder}
        </label>
      </div>
      {/* Show password */}
      {type === 'password' && (
        <div className="absolute inset-y-0 right-2 flex items-center pr-3 text-sm leading-5">
          <FaEye {...iconProps(show)} />
          <FaEyeSlash {...iconProps(!show)} />
        </div>
      )}
    </div>
  );
};

export default Input;
