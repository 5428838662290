import React from 'react';
import SimuladorInformationApi from './SimulatorInformationApi';

const SimuladorExternadoOrden = ({ infoOrdenMatricula }) => {
  return (
    <>
      <section>
        <SimuladorInformationApi infoOrdenMatricula={infoOrdenMatricula} />
      </section>
    </>
  );
};

export default SimuladorExternadoOrden;
