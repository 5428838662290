import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import { PROGRAMA_FINANCIAR } from '../../../constants/form/validations';
import { DIALOG } from '../../../constants/dialog/dialog';
import { useDialog } from '../../../context/dialog-context';
import ConstantesSolicitudService from '../../../services/shared/constantes-solicitud-service';
import FormInput from '../../../components/Input/FormInput';
import useInput from '../../../hooks/use-input';
// import { useAuth } from '../../../context/auth-context';
import ComponenteConBotones from '../../../components/DynamicForm/ButtonLine';
// import OpenCoopCrmService from '../../../services/verification/open-coop-service';

const ProgramaFinanciar = ({
  formConstants,
  onSeleccionChange,
  showMessage,
  fetchedConsultDataOpen,
  creditRenovationInfo
}) => {
  // const { user } = useAuth();
  const isMounted = useRef(false);
  const [jornadas, setJornadas] = useState();
  const [periodos, setPeriodos] = useState();
  const [tipoPeriodo, setTipoPeriodo] = useState();
  // const [fetchedConsultDataOpen, setFetchedConsultDataOpen] = useState();
  const handleSeleccionChange = (nuevaSeleccion) => {
    onSeleccionChange(nuevaSeleccion);
  };
  // Array to handle the state of PRO line temporarily
  const estadoPro = ['abierto', 'limitado', 'cerrado'];
  const idStatusPro = 0;
  const dialog = useDialog();
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const watchUniversidad = watch('universidad');
  const watchPrograma = watch('programa');
  const watchNivel = watch('nivel');
  const watchLinea = watch('linea');
  const watchPeriodo = watch('periodo_cursa');
  const isOtroPrograma = watchPrograma?.toString() === 'otro';
  const isTechNivel = watchNivel?.toString() === '8';
  console.log(watchPrograma, isTechNivel);
  const showPeriodos = tipoPeriodo?.toString() !== 'Hora';
  const programas = Cache.getItem(
    `PROGRAMAS_${watchUniversidad}_${watchNivel}`
  );

  // esto es temporar ya que necesitamos solo subir los simuladores :D
  const universidadSinUTB = [];
  formConstants.UNIVERSIDAD.forEach((element) => {
    if (element.value === 1 || element.value === 2 || element.value === 3) {
      universidadSinUTB.push(element);
    }
  });
  let nivelAcademico = [];
  if (Number(watchUniversidad) === 1 || Number(watchUniversidad) === 3) {
    formConstants.NIVEL_ACADEMICO.forEach((element) => {
      if (
        element.value === 2 ||
        element.value === 3 ||
        element.value === 4 ||
        element.value === 7
      ) {
        nivelAcademico.push(element);
      }
    });
  } else if (Number(watchUniversidad) === 2) {
    formConstants.NIVEL_ACADEMICO.forEach((element) => {
      if (element.value !== 5) {
        nivelAcademico.push(element);
      }
    });
  } else {
    nivelAcademico = formConstants.NIVEL_ACADEMICO;
  }
  const watchTieneFinanciacion = watch('tiene_financiacion');
  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: PROGRAMA_FINANCIAR,
  });
  const lineasArray = useMemo(() => {
    const tipoEstudiante = fetchedConsultDataOpen ? 'RE' : 'nuevo';
    let options = [];
    if (Number(watchUniversidad) === 4) {
      options = [
        {
          value: '6',
          label: 'Línea Tech',
          url: 'https://www.youtube.com/embed/7WYOhHXfEAU',
        },
      ];
    } else {
      options = [
        {
          value: '1',
          label: 'Línea ESTRELLA',
          url: 'https://www.youtube.com/embed/AvY3gNcU9Y0',
        },
      ];
    }
    // check nivel pregrado
    if (watchNivel?.toString() === '4') {
      // check estado PRO is not closed
      if (estadoPro[idStatusPro] !== 'cerrado') {
        // check universidad externado
        if (watchUniversidad?.toString() === '2') {
          // check if estado PRO is limited
          if (estadoPro[idStatusPro] === 'limitado') {
            if (watchPeriodo > 1 && tipoEstudiante === 'RE') {
              options.push({
                value: '2',
                label: 'Línea PRO',
                url: 'https://www.youtube.com/embed/5Ncm1ZkMI7o',
              });
            }
          } else if (estadoPro[idStatusPro] === 'abierto' && tipoEstudiante === 'RE') {
            options.push({
              value: '2',
              label: 'Línea PRO',
              url: 'https://www.youtube.com/embed/5Ncm1ZkMI7o',
            });
          } else if (tipoEstudiante === 'nuevo') {
            options.push({
              value:'9',
              label: 'Línea Andina',
              url: 'https://www.youtube.com/embed/6u0vIzO3hKc'
            });
          } else if ( tipoEstudiante === 'RE' && creditRenovationInfo.lineaAnterior.nombre === 'Línea Andina') {
            options.push({
              value:'9',
              label: 'Línea Andina',
              url: 'https://www.youtube.com/embed/6u0vIzO3hKc'
            });
          }
        }
      }
      // check universidad usb
      if (
        watchUniversidad?.toString() === '1'
        // && watchTieneFinanciacion?.toString() === 'false'
      ) {
        // options.push({value: '3', label: 'Tasa CERO', url: 'https://www.youtube.com/embed/cfUrgjRXEtQ'});
        // if(Number(watchPrograma)  === 5 || Number(watchPrograma)  === 2 || Number(watchPrograma)  === 67){
        //     options.push({value: '7', label: 'Línea Pacífico', url: 'https://www.youtube.com/embed/R7-5LuyID2M'});
        // }
        options.push({
          value: '7',
          label: 'Línea Pacífico',
          url: 'https://www.youtube.com/embed/zZY3f319KLk',
        });
      }
    }
    if (
      watchUniversidad?.toString() === '1' 
    ) {
      if (
        Number(watchNivel) === 7 && Number(watchPrograma) === 484
      ) {
        options[0].url = 'https://www.youtube.com/embed/MNU8WArZojE';
      } else {
        options.push({
          value: '8',
          label: 'Línea Sultana',
          url: 'https://www.youtube.com/embed/MNU8WArZojE',
        });
      }
      options[0].url = 'https://www.youtube.com/embed/uKcCDc4U4xM';
    }
    
    // check universidad utb
    if (watchUniversidad?.toString() === '3') {
      if (Number(watchNivel) === 4) {
        options.push({
          value: '5',
          label: 'Línea Heroica',
          url: 'https://www.youtube.com/embed/fhtmKKbOiRE',
        });
      }
      options.push({
        value: '4',
        label: 'Línea Caribe',
        url: 'https://www.youtube.com/embed/bOiFbZVhGpw',
      });
      options[0].url = 'https://www.youtube.com/embed/OlrT6FPlFMU';
    }
    const updateLinea = [];
    for (const li of options) {
      const optionLinea = li.label.replace(/Línea/g, '');
      updateLinea.push({
        id: li.value,
        title: li.label,
        hide: false,
        src: li.url,
        option: optionLinea.replace(/\s/g, ''),
      });
    }
    return updateLinea;
  }, [
    watchUniversidad,
    watchNivel,
    watchTieneFinanciacion,
    watchPeriodo,
    watchPrograma,
  ]);

  let programaNotOther = programas;
  if (Number(watchNivel) !== 1) {
    const valuesToRemove = ['otro'];

    if (programas) {
      const filteredOptions = programas.filter(
        (option) => !valuesToRemove.includes(option.value)
      );
      programaNotOther = filteredOptions;
    }
  }

  /* tenemos que conectar a un servicio */
  const fetchProgramas = async () => {
    if (watchUniversidad && watchNivel && programas === null) {
      setLoading('programa', true);
      await ConstantesSolicitudService.getProgramasByNivelAcademico(
        watchUniversidad,
        watchNivel
      )
        .then((response) =>
          Cache.setItem(
            `PROGRAMAS_${watchUniversidad}_${watchNivel}`,
            Number(watchNivel) === 1 && response
              ? response.concat([
                  {
                    value: 'otro',
                    label: 'Otro',
                  },
                ])
              : response
          )
        )
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
      setLoading('programa', false);
      setValue('programa', getValues('programa'));
    }
  };

  useEffect(() => {
    fetchProgramas();
  }, [watchUniversidad, watchNivel]);

  // useEffect(() => {
  //   const fetchOpenCoopData = async () => {
  //     const body = {
  //       nro_identificacion: user['custom:document'],
  //       rol: 'consultaOpenCreditosServicio',
  //     };
  //     const resul = await OpenCoopCrmService.postOpenCoop(body);
  //     let consultDataOpenResult = resul === null ? [] : resul?.listCustomer;
    
  //     let consultDataOpen = [];
  //         if (Array.isArray(consultDataOpenResult)) {
  //           consultDataOpen = consultDataOpenResult.map(
  //             subArray => 
  //             subArray.sort((a, b) => new Date(b.fecha_apertura) - new Date(a.fecha_apertura))
  //           );
    
  //           if (consultDataOpen.length > 0 ) {
  //             consultDataOpenResult = consultDataOpen.length === 0 ? null : consultDataOpen[0][0];
  //             // setFetchedConsultDataOpen(consultDataOpenResult);
  //           }
  //         }
  //   };
  //   fetchOpenCoopData();
  // }, [user, fetchedConsultDataOpen]);



  const fetchPeriodoAndJornada = async () => {
    setPeriodos();
    setJornadas();
    setTipoPeriodo();
    if (watchPrograma && !isOtroPrograma) {
      setLoading('periodo_cursa', true);
      setLoading('jornada', true);
      await Promise.all([
        ConstantesSolicitudService.getPrograma(
          Number(watchPrograma) === 0 ? 1 : watchPrograma
        )
          .then((response) => {
            setTipoPeriodo(response.tipoPeriodo);
            const periodos = [];
            for (let i = 0; i < response.numeroPeriodos; i++)
              periodos.push({
                value: i + 1,
                label: i + 1,
              });
            setPeriodos(periodos);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
        ConstantesSolicitudService.getJornadasByPrograma(watchPrograma)
          .then((response) => {
            setJornadas(response);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
      ]);
    }
  };

  useEffect(() => {
    fetchPeriodoAndJornada();
  }, [watchPrograma]);

  useEffect(() => {
    if (periodos) {
      setValue('periodo_cursa', getValues('periodo_cursa'));
      setLoading('periodo_cursa', false);
    }
  }, [periodos]);

  useEffect(() => {
    if (jornadas) {
      setValue('jornada', getValues('jornada'));
      setLoading('jornada', false);
    }
  }, [jornadas]);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const taxCategory = useMemo(() => {
    if( watchUniversidad?.toString() === '2' && tipoPeriodo === 'Trimestre' && watchNivel?.toString() !== '4') {
      return 'estrella_trimestre';
    }

    if (watchLinea === '2') {
      if (tipoPeriodo === 'Año') return 'pro_annual';
      return 'pro';
    } else if (watchLinea === '3') {
      return 'zero';
    } else if (watchLinea === '4') {
      return 'caribe';
    } else if (watchLinea === '5') {
      return 'heroica';
    } else if (watchLinea === '6') {
      return 'tech';
    } else if (watchLinea === '7') {
      return 'pacifico';
    } else if (watchLinea === '8') {
      return 'sultana';
    } else if (watchLinea === '9') {
      return 'andina';
    } else if (tipoPeriodo === 'Año') {
      return 'annual';
    } else return 'estrella';
  }, [watchLinea, tipoPeriodo]);

  useEffect(() => {
    setValue('tax_category', taxCategory);
  }, [taxCategory]);

  useEffect(() => {
    const selectElement = document.getElementById('universidadSelect');
    if (selectElement) {
      selectElement.value = '0';
    }
  }, []);

  return (
    <>
      <section>
        <h4 className="font-bold">
          Información del programa o carrera que te apasiona
        </h4>
        <div className="my-4 grid grid-cols-1 gap-4 gap-y-6 lg:my-6 lg:grid-cols-1 lg:gap-6">
          <div className="col-span-2 grid grid-cols-2 gap-4 gap-y-6 lg:gap-6">
            <FormInput
              {...InputProps('universidad')}
              placeholder="Universidad"
              type="select"
              options={universidadSinUTB}
              id={'universidadSelect'}
            />
            <FormInput
              {...InputProps('nivel')}
              placeholder="Nivel académico"
              type="select"
              options={nivelAcademico || formConstants.NIVEL_ACADEMICO}
            />
            {!isTechNivel && (
              <>
                <FormInput
                  {...InputProps('programa')}
                  placeholder="Programa"
                  type="select"
                  options={programaNotOther || []}
                  className="col-span-2"
                />
                {!isOtroPrograma && (
                  <>
                    <FormInput
                      {...InputProps('jornada')}
                      placeholder="Jornada"
                      type="select"
                      options={jornadas}
                    />
                    {showPeriodos && (
                      <FormInput
                        {...InputProps('periodo_cursa')}
                        placeholder={(tipoPeriodo || 'Periodo') + ' a cursar'}
                        type="select"
                        options={periodos}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <h4 className="font-bold">
          Conoce y elige la línea de financiación para ti
        </h4>
        <ComponenteConBotones
          lineas={lineasArray}
          onSeleccionChange={handleSeleccionChange}
        />
        {showMessage && (
          <>
            <p className="text-center disabled">
              Estamos conectando con tu Universidad, esto podría tomar algunos
              segundos...
            </p>
            <br />
          </>
        )}
      </section>
    </>
  );
};

export default ProgramaFinanciar;
