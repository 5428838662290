import Button from '../components/DynamicForm/Button';
import { useAuth } from '../context/auth-context';
import { Cache } from 'aws-amplify';
import Emoji from '../components/shared/Emoji';
import { useNavigate } from 'react-router-dom';
import ZapsignService from '../services/process/zapsign-service';
import { useDialog } from '../context/dialog-context';
import React, { useEffect, useState } from 'react';
import EtapaService from '../services/stage/etapa-service';

const ChangeCodeudores = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dialog = useDialog();
  const [codeudor, setCodeudor] = useState('');

  const cacheCredit = Cache.getItem('requestCredit');
  const sendChangeCodeudorStudent = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 7,
      mensaje:
        'Aceptado por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeCodeudorStudent',
    };

    await ZapsignService.postZapsign(body)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const sendChangeCodeudorStudentCancel = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 6,
      mensaje:
        'Desistido por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeCodeudorStudent',
    };

    await ZapsignService.postZapsign(body)
      .then(() => {
        navigate('/solicitar-financiacion');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const showDialog = (message, callback) => {
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p className="font-bold">{message}</p>
      </div>,
      { onConfirm: callback }
    );
  };

  useEffect(() => {
    const getChangeCodeudor = async () => {
      if (cacheCredit && user) {
        await EtapaService.get({ usuarioId: user.sub })
          .then(async (response) => {
            if (typeof response.loanId === 'undefined') {
              navigate('/solicitar-financiacion');
            } else {
              const body = {
                creditId: cacheCredit.numero,
                rol: 'getChangeCodeudor',
              };
              await ZapsignService.postZapsign(body)
                .then((res) => {
                  setCodeudor(res[0].updaterUserId);
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            return response;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
    getChangeCodeudor();
  }, []);
  return (
    <>
      <section>
        <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
          ¿Aceptas el cambio de codeudor(es)?
        </h2>
        <h2 className="pb-6 text-center text-xl font-bold">
          Información importante para ti
        </h2>
        <h4 className=" text-center">
          Tu solicitud de financiación no fue aprobada, sin embargo, hemos
          habilitado para ti la posibilidad de generar una nueva solicitud,
          siempre que cambies los siguientes codeudores:
        </h4>

        <h4 className=" text-center">
          <br />
          <b>{codeudor}</b>
          <br />
          <br />
        </h4>

        <h4 className=" text-center">
          Por favor registra en el sistema otra persona que pueda respaldarte al
          hacer tu nueva solicitud, y continúa trabajando con la vista fija en
          el sueño que tienes frente a ti.
          <br />
          <br />
          #EstamosJuntosEnEsto
          <br />
          <br />
        </h4>

        <div className="my-4 text-center">
          <Button
            className="mr-4"
            onClick={() =>
              showDialog(
                '¿Confirmas el DESISTIMIENTO de tu solicitud?',
                sendChangeCodeudorStudentCancel
              )
            }
          >
            Desistir Solicitud
          </Button>
          <Button
            onClick={() =>
              showDialog(
                '¿Confirmas EL CAMBIO DE CODEUDOR?',
                sendChangeCodeudorStudent
              )
            }
          >
            Aceptar cambio Codeudor
          </Button>
        </div>
      </section>
    </>
  );
};

export default ChangeCodeudores;
