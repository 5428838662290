import { FORM_ITEMS } from './formItems';

const FORM = (line) => {
  const templateInfo = [
    {
      title: 'Queremos conocer cómo vives',
      items: [
        {
          questionId: 1,
          title: '¿Con quién vives?',
          subTitle: 'Marca todas las personas que viven contigo',
          type: FORM_ITEMS.MULTIPLE_CHOICE,
          options: [
            'Mamá',
            'Papá',
            'Hermano',
            'Hermana',
            'Hijo',
            'Hija',
            'Abuelo materno',
            'Abuela materna',
            'Abuelo paterno',
            'Abuela paterna',
            'Tío',
            'Tía',
            'Primo(s)',
            'Otro familiar',
            'Roommate',
            'Solo(a)',
          ],
          cols: 4,
          colSpan: 4,
        },
        {
          questionId: 2,
          title: '¿Con cuantas personas vives?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['0', '1', '2', '3', '4', '5', '+5'],
          cols: 3,
        },
        {
          questionId: 3,
          title: '¿Cuántos hermanos tienes?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['0', '1', '2', '3', '4', '5', '+5'],
          cols: 3,
        },
        {
          questionId: 4,
          title:
            'En el lugar donde vives, ¿Qué estrato aparece en el recibo de energía?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['1', '2', '3', '4', '5', '6'],
          cols: 3,
          required: true,
        },
        {
          questionId: 5,
          title: '¿Quiénes aportan económicamente en tu casa?',
          subTitle:
            'Marca todas las personas que aportan económicamente en tu casa',
          type: FORM_ITEMS.MULTIPLE_CHOICE,
          options: [
            'Mamá',
            'Papá',
            'Hermano',
            'Hermana',
            'Abuelo materno',
            'Abuela materna',
            'Abuelo paterno',
            'Abuela paterna',
            'Tío',
            'Tía',
            'Primo(s)',
            'Otro familiar',
            'Yo',
          ],
          cols: 4,
          colSpan: 4,
        },
        {
          questionId: 6,
          title: '¿Quiénes son las personas que pagan tu financiación? Marca todas las necesarias',
          type: FORM_ITEMS.MULTIPLE_CHOICE,
          options: [
            'Mamá',
            'Papá',
            'Hermano',
            'Hermana',
            'Hijo',
            'Hija',
            'Abuelo materno',
            'Abuela materna',
            'Abuelo paterno',
            'Abuela paterna',
            'Tío',
            'Tía',
            'Primo(s)',
            'Otro familiar'
          ],
          cols: 4,
          colSpan: 4,
        },
        {
          questionId: 7,
          title: '¿La vivienda en la que vives es?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Arrendada', 'Propia'],
          cols: 1,
        },
        {
          questionId: 8,
          title: '¿En que tipo de zona esta ubicada tu vivienda?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Urbano', 'Rural'],
          cols: 1,
        },
        {
          questionId: 9,
          title: '¿Cuántas habitaciones tiene la vivienda en la que vives?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['0', '1', '2', '3', '4', '5', '+5'],
          cols: 3,
        },
        {
          questionId: 10,
          title: '¿Cuántos baños tiene la vivienda en la que vives?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['0', '1', '2', '3', '4', '5', '+5'],
          cols: 3,
        },
        {
          questionId: 11,
          title: '¿En tu vivienda cuentan con servicio de internet?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Si', 'No'],
          cols: 1,
        },
        {
          questionId: 12,
          title: 'Localidad donde se ubica tu vivienda',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: [
            'Usaquén',
            'Chapinero',
            'Santa Fe',
            'San Cristóbal',
            'Usme',
            'Tunjuelito',
            'Bosa',
            'Kennedy',
            'Fontibón',
            'Engativá',
            'Suba',
            'Barrios Unidos',
            'Teusaquillo',
            'Los Mártires',
            'Antonio Nariño',
            'Puente Aranda',
            'Candelaria',
            'Rafael Uribe Uribe',
            'Ciudad Bolívar',
            'Sumapaz',
          ],
          cols: 4,
          colSpan: 4,
        },
        {
          questionId: 13,
          title: 'Barrio donde se ubica tu vivienda',
          type: FORM_ITEMS.TEXT,
          required: true,
        },
        {
          questionId: 14,
          title:
            'En el recorrido de tu casa o lugar de vivienda hasta la universidad, ¿Cuántos medios de transporte debes tomar?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['1', '2', '3', '+3'],
          cols: 1,
        },
        {
          questionId: 15,
          title:
            'El recorrido de tu casa o lugar de vivienda hasta la universidad en tiempo',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: [
            '- 30 minutos',
            '30 minutos',
            '1 hora',
            '2 horas',
            '+ de 2 horas',
          ],
          cols: 1,
        },
        {
          questionId: 16,
          title: '¿Perteneces a alguna de las siguientes poblaciones?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Afro', 'Indígenas', 'Gitanos', 'Sin pertenencia étnica'],
          cols: 1,
        },
        {
          questionId: 17,
          title: '¿En tu familia han sido victimas del conflicto armado?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Sí', 'No'],
          cols: 1,
        },
        {
          questionId: 18,
          title: '¿Tu familia es beneficiaria de algún programa del estado?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Sí', 'No'],
          cols: 1,
        },
      ],
    },
    {
      title: 'Orientación Vocacional',
      items: [
        {
          questionId: 19,
          title: '¿La elección de tu carrera estuvo relacionada con?',
          type: FORM_ITEMS.MULTIPLE_CHOICE,
          options: [
            'Proceso de orientación vocacional dirigida por un profesional',
            'Tradición familiar',
            'Me gustaban las materias relacionadas con esta carrera en el colegio',
            'No estoy seguro de las razones por las que elegí la carrera',
            'Influencia social',
            'Beneficios económicos a futuro',
            'Influencia familiar',
            'El negocio o la empresa familiar',
          ],
          cols: 4,
          colSpan: 4,
        },
        {
          questionId: 20,
          title:
            '¿Siento que lo que estoy estudiando me proveerá bienestar para mi vida?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Sí', 'No'],
          required: true,
        },
        {
          questionId: 21,
          title:
            '¿Considero que tengo las habilidades necesarias para afrontar este reto académico?',
          type: FORM_ITEMS.SINGLE_CHOICE,
          options: ['Sí', 'No'],
          required: true,
        },
      ],
    },
    {
      title: 'Queremos conocer tus intereses',
      items: [
        {
          questionId: 22,
          title: '¿Tienes tu resultado en las Pruebas Saber Pro (ICFES)? (Si eres renovación marca NO)',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'saber_pro',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos el por qué de este resultado:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Puntaje',
              type: FORM_ITEMS.TEXT,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 23,
          title: '¿Tienes algún reconocimiento ACADÉMICO?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'reconocimiento_academico',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos de qué se trata el reconocimiento:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Nombre del reconocimiento',
              type: FORM_ITEMS.TEXT,
            },
            {
              title: 'Quién te lo otorgó',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: ['Colegio', 'Universidad', 'Gobierno', 'Otro'],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 24,
          title: '¿Tienes algún reconocimiento EXTRA ACADÉMICO?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'reconocimiento_extra_academico',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos de qué se trata el reconocimiento:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Nombre del reconocimiento',
              type: FORM_ITEMS.TEXT,
            },
            {
              title: 'Área o disciplina',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: ['Deportivo', 'Artístico', 'Cultural', 'Otro'],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 25,
          title: '¿Participas activamente en VOLUNTARIADO SOCIAL?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'voluntariado_social',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos en qué consiste y el rol que desempeñas:',
          descriptionLimit: 250,
          items: [
            {
              title: 'De qué tipo',
              type: FORM_ITEMS.TEXT,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 26,
          title: '¿Practicas o has practicado DEPORTE a nivel competitivo?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'deporte_competitivo',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos sobre tu practica:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Cuál deporte',
              type: FORM_ITEMS.TEXT,
            },
            {
              title: 'Nivel de práctica',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: [
                'Representación local',
                'Representación regional',
                'Representación nacional',
                'Otro',
              ],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 27,
          title:
            '¿Practicas o has practicado ACTIVIDAD ARTÍSTICA a nivel representativo?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'actividad_artistica',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos sobre tu practica:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Cuál actividad artística',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: [
                'Representación local',
                'Representación regional',
                'Representación nacional',
                'Otro',
              ],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 28,
          title:
            '¿Practicas o has practicado ACTIVIDAD CULTURAL a nivel representativo?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'actividad_cultural',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos sobre tu practica:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Cuál actividad cultural',
              type: FORM_ITEMS.TEXT,
            },
            {
              title: 'Nivel de práctica:',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: [
                'Representación local',
                'Representación regional',
                'Representación nacional',
                'Otro',
              ],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },
        {
          questionId: 29,
          title:
            '¿Practicas o has practicado OTRA DISCIPLINA a nivel representativo?',
          type: FORM_ITEMS.SUPPORT_FILE,
          name: 'otra_disciplina',
          displayName: 'Documento oficial o certificación con membrete',
          descriptionPlaceholder:
            'En tus palabras, cuéntanos sobre tu practica:',
          descriptionLimit: 250,
          items: [
            {
              title: 'Cuál disciplina',
              type: FORM_ITEMS.TEXT,
            },
            {
              title: 'Nivel de práctica:',
              type: FORM_ITEMS.SINGLE_CHOICE,
              options: [
                'Representación local',
                'Representación regional',
                'Representación nacional',
                'Otro',
              ],
              cols: 1,
            },
          ],
          colSpan: 2,
          required: false,
        },        
      ],
    },
    {
      title: line === 4 ? '' : '¡Por ÚLTIMO! Queremos verte y escucharte',
      items: [
        {
          questionId: 30,
          title:
            '¿Por qué necesitas o te gustaría tener el beneficio de Línea Tasa 0%? (Si eres renovación puedes enviar el mismo link de la aplicación anterior)',
          type: FORM_ITEMS.ITEMS,
          items: [
            {
              type: FORM_ITEMS.RAW_TEXT,
              body: 'Respóndenos en un corto video personal de máximo 1 minuto y 30 segundos, 1. ¿Con quién vives?, 2. ¿De quién dependes económicamente? y 3 ¿Por qué requieres ser beneficiario de la línea tasa cero?',
            },
            {
              type: FORM_ITEMS.RAW_TEXT,
              body: 'Enlace de YouTube, puedes subirlo privado y compartir tu link con nosotros',
            },
            {
              title: 'Pega tu link aquí:',
              type: FORM_ITEMS.TEXT,
            },
          ],
          cols: 3,
          colSpan: 4,
        },
      ],
    },
  ];
  if (line === 4) {
    const titlesToRemove = [
      '¿por qué necesitas o te gustaría tener el beneficio de Línea Pro (Antiguo Beca Crédito) (Si eres renovación puedes enviar el mismo link de la aplicación anterior)',
      '¡Por ÚLTIMO! Queremos verte y escucharte',
      'Localidad donde se ubica tu vivienda',
      '¿por qué necesitas o te gustaría tener el beneficio de Línea Pro (Antiguo Beca Crédito)? (Si eres renovación puedes enviar el mismo link de la aplicación anterior)',
    ];

    templateInfo.forEach((section) => {
      section.items = section.items.filter((item) => {
        return !titlesToRemove.includes(item.title); // Necesitas retornar el resultado de esta expresión
      });
    });
  }
  return {
    formId: '1',
    universityId: '2',
    template: templateInfo,
  };
};

export { FORM_ITEMS, FORM };
