import React, { useEffect, useState } from 'react';
import LegalizationProgressBar from '../../components/FinancingRequest/LegalizationProgressBar';
import ContractoSeguroVida from './ContractoSeguroVida';
import Emoji from '../../components/shared/Emoji';
import { useAuth } from '../../context/auth-context';
import SeguroVida from './SeguroVida';
import Deceval from './Deceval';
import { Cache } from 'aws-amplify';
import ZapsignService from '../../services/process/zapsign-service';

function Legalization() {
  const [progress, setProgress] = useState(0);
  const [progreDeceval, setDeceval] = useState(false);
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  const { user } = useAuth();
  useEffect(() => {
    setProgress(0);
  }, []);
  const handleContractoSeguroVidaComplete = () => {
    if (progress === 0) {
      setProgress(1);
    }
  };
  const handleSeguroVidaComplete = async () => {
    if (progreDeceval !== true) {
      const body = {
        creditId: cacheCredit.numero,
        rol: 'create',
      };
      await ZapsignService.postDeceval(body).then((res) => {
        if (res.result === true) {
          setDeceval(true);
        }
      });
    }
  };
  return (
    <div>
      <div className="flex-1 space-y-8">
        <div className="text-center text-2xl font-bold">
          <p>
            {`¡Hola ${user.given_name}`}
            <Emoji
              symbol="waving-hand-light-skin-tone"
              className="inline h-8"
            />
            <Emoji
              symbol="smiling-face-with-smiling-eyes"
              className="inline h-8"
            />
            <Emoji symbol="white-exclamation-mark" className="inline h-8" />
          </p>
          <p>
            ¡Último paso para terminar!{' '}
            <Emoji symbol="party-popper" className="inline h-6" />
          </p>
        </div>
        <div className="flex justify-center">
          <p>
            <b>Tú y tus codeudores deben firmar digitalmente</b> los siguientes
            documentos
          </p>
        </div>
        {progreDeceval === false && progress === 0 ? (
          <div>
            <LegalizationProgressBar
              className="mx-auto max-w-screen-lg"
              progress={0}
            />
            <ContractoSeguroVida
              onComplete={handleContractoSeguroVidaComplete}
            />
          </div>
        ) : progreDeceval === false && progress === 1 ? (
          <div>
            <LegalizationProgressBar
              className="mx-auto max-w-screen-lg"
              progress={0.5}
            />
            <SeguroVida onComplete={handleSeguroVidaComplete} />
          </div>
        ) : (
          progreDeceval === true && (
            <div>
              <LegalizationProgressBar
                className="mx-auto max-w-screen-lg"
                progress={1}
              />
              <Deceval />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Legalization;
