import React from 'react';
import CoheteImage from '../../assets/images/financing-request/cohete.png';

const FormApplication = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8 text-center">
      <div className="relative mb-5">
        <div className="px-12 pb-4 dark:opacity-50">
          <img src={CoheteImage} alt="cohete" />
        </div>
        <div className="absolute bottom-0 w-full text-center">
          <p className="font-bold ">
            Para generar tu solicitud es necesario un
          </p>
          <p className="text-3xl font-bold text-pink-700">¡PASO ADICIONAL!</p>
          <p className="font-bold">Es importante que lo hagas MUY PRONTO.</p>
        </div>
      </div>
      <p className="text-xl font-bold">
        Formulario de aplicación a la línea de financiación con beneficio Tasa
        0% de interés de tu Universidad como #MiembroDeEducaciónEstrella.
      </p>
    </div>
  );
};

export default FormApplication;
