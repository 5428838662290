import React, { createContext, useContext, useState } from 'react';
import Dialog from '../components/dialog/Dialog';
import Button from '../components/DynamicForm/Button';
import { DIALOG_TYPES } from '../constants/dialog/dialogType';

const DialogContext = createContext(() => {});

export function DialogProvider({ children }) {
  const [store, setStore] = useState();
  const { dialogType, dialogProps } = store || {};

  const close = () => {
    setStore((current) => ({
      ...current,
      dialogType: null,
      dialogProps: {},
    }));
  };

  const renderDialog = () => {
    switch (dialogType) {
      case DIALOG_TYPES.INFO:
        return (
          <Dialog
            isOpen={true}
            actions={
              <Button
                onClick={async () => {
                  if (typeof dialogProps.onConfirm === 'function')
                    await dialogProps.onConfirm();
                  close();
                }}
              >
                {dialogProps.confirmText || 'Ok'}
              </Button>
            }
            {...dialogProps}
          />
        );
      case DIALOG_TYPES.CONFIRMATION:
        return (
          <Dialog
            isOpen={true}
            actions={
              <>
                <Button
                  onClick={async () => {
                    if (typeof dialogProps.onCancel === 'function')
                      await dialogProps.onCancel();
                    close();
                  }}
                  customColor="bg-custom-red"
                >
                  {dialogProps.cancelText || 'Cancelar'}
                </Button>
                <Button
                  onClick={async () => {
                    if (typeof dialogProps.onConfirm === 'function')
                      await dialogProps.onConfirm();
                    close();
                  }}
                >
                  {dialogProps.confirmText || 'Ok'}
                </Button>
              </>
            }
            textBelowActions={<>{dialogProps.textBelowActions}</>}
            {...dialogProps}
          />
        );
      case DIALOG_TYPES.CUSTOM:
        return <Dialog isOpen={true} {...dialogProps} />;
      default:
        return null;
    }
  };

  return (
    <DialogContext.Provider value={setStore}>
      {renderDialog()}
      {children}
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const setStore = useContext(DialogContext);
  if (!setStore) {
    throw new Error('useDialog must be used within DialogProvider');
  }
  const showDialog = (dialogType, dialogProps = {}) => {
    setStore((current) => ({
      ...current,
      dialogType,
      dialogProps,
    }));
  };
  const close = () => {
    setStore((current) => ({
      ...current,
      dialogType: null,
      dialogProps: {},
    }));
  };
  return {
    close,
    info: (children, options) =>
      showDialog(DIALOG_TYPES.INFO, { children, ...options }),
    confirmation: (children, options) =>
      showDialog(DIALOG_TYPES.CONFIRMATION, { children, ...options }),
    custom: (children, options) =>
      showDialog(DIALOG_TYPES.CUSTOM, { children, ...options }),
  };
}
