import React, { createContext, useState, useContext } from 'react';

const InstallmentContext = createContext();

export function InstallmentProvider({ children }) {
  const [installments, setInstallments] = useState([]);
  const value = { installments, setInstallments };
  return (
    <InstallmentContext.Provider value={value}>
      {children}
    </InstallmentContext.Provider>
  );
}

export function useInstallment() {
  return useContext(InstallmentContext);
}
