import Button from '../../../components/DynamicForm/Button';
import { ReactComponent as IdentityIcon } from '../../../assets/images/identity/identity-pending.svg';
import { ReactComponent as IdentityCursoIcon } from '../../../assets/images/identity/identity.svg';
import { ReactComponent as IdentityIconS } from '../../../assets/images/identity/success-user.svg';
import { useDialog } from '../../../context/dialog-context';
import Emoji from '../../../components/shared/Emoji';
import React, { useState, useEffect } from 'react';
import ZapsignService from '../../../services/process/zapsign-service';
import { Cache } from 'aws-amplify';
import ShareURL from '../../../components/Identity/ShareURL';
import Loading from '../../../components/shared/Loading';
import RequestFormDoc from '../RequestFormDoc';
import FormApplication from '../../../components/FinancingRequest/FormApplication';
import { useNavigate } from 'react-router-dom';
import FormService from '../../../services/form/form-service';
import { DIALOG } from '../../../constants/dialog/dialog';
import HubspotCrmService from '../../../services/autorization/hubspotCrm-service';
import ConsultGeneral from '../../../services/consult/consult-general';
import { useStep } from '../../../context/step-context';

const Autorizacion = () => {
  const dialog = useDialog();
  const navigate = useNavigate();
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const [creditInfo, setCreditInfo] = useState([]);
  const [lineProFirma, setLineProFirma] = useState(true);
  const {
    step: { stages },
  } = useStep();
  const updateEtapaOneByStatus = async () => {
    const body = {
      creditId: cacheCredit.numero,
      message: 'Paso 1 estado 6 No actualizó automáticamente',
      status: 3,
      etapa: 1,
      rol: 'updateEtapaOneByStatus',
    };
    await ZapsignService.postZapsign(body)
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchCreditData = async () => {
    let allNew = true;
    const abortController = new AbortController(); // Crear un controlador de aborto
    if (cacheCredit.numero !== null && loadingForm === true) {
      const body = {
        creditId: cacheCredit.numero,
        rol: 'consultRegister',
      };
      await ZapsignService.postZapsign(body) // Pasar la señal del controlador de aborto a la llamada de ZapsignService.postZapsign
        .then(async (res) => {
          if (res.listZapsignUser.length > 0 ) {
            const infos = [];
            let re = res.listZapsignUser.filter(
              (r) => r.docNumber.toString() === cacheCredit.estudiante.documento
            );
            const inf = {
              name:
                cacheCredit.estudiante.nombre +
                ' ' +
                cacheCredit.estudiante.apellido,
              documento: cacheCredit.estudiante.documento,
              status: re[0].status,
              signUrl: re[0].signUrl,
              type: 'studiant',
            };
            if (re[0].status !== 'signed') {
              allNew = false;
            }
            infos.push(inf);
            if (cacheCredit?.codeudores[0]?.id !== undefined) {
              re = res.listZapsignUser.filter((r) => {
                console.log(r);
                console.log(
                  r.docNumber.toString() === cacheCredit.codeudores[0].documento
                );
                console.log(r.docNumber.toString());
                console.log(typeof r.docNumber.toString());
                console.log(cacheCredit.codeudores[0].documento);
                console.log(typeof cacheCredit.codeudores[0].documento);
                return (
                  r.docNumber.toString() === cacheCredit.codeudores[0].documento
                );
              });
              const inf = {
                name:
                  cacheCredit.codeudores[0].nombre +
                  ' ' +
                  cacheCredit.codeudores[0].apellido,
                documento: cacheCredit.codeudores[0].documento,
                status: re[0].status,
                signUrl: re[0].signUrl,
                type: 'codeudor',
              };
              if (re[0].status !== 'signed') {
                allNew = false;
              }
              infos.push(inf);
            }
            if (cacheCredit?.codeudores[1]?.id !== undefined) {
              re = res.listZapsignUser.filter(
                (r) =>
                  r.docNumber.toString() === cacheCredit.codeudores[1].documento
              );
              const inf = {
                name:
                  cacheCredit.codeudores[1].nombre +
                  ' ' +
                  cacheCredit.codeudores[1].apellido,
                documento: cacheCredit.codeudores[1].documento,
                status: re[0].status,
                signUrl: re[0].signUrl,
                type: 'codeudor',
              };
              if (re[0].status !== 'signed') {
                allNew = false;
              }
              infos.push(inf);
            }
            setCreditInfo(infos);
            setLoading(false);
            if (allNew) {
              // Cancelar la consulta si allNew es verdadero
              abortController.abort();
              // TODO: hacer la navegacion de react
              // consultar la orden de matricula
              if (res.ordenMatriculaStatus.length > 0) {
                if (
                  res.ordenMatriculaStatus[0].toState === 2 ||
                  res.ordenMatriculaStatus[0].toState === 1
                ) {
                  //  window.location.reload()
                  const body = {
                    creditId: cacheCredit.numero,
                    rol: 'updateDeal',
                  };
                  await HubspotCrmService.postHubspot(body);
                  await updateEtapaOneByStatus();
                  navigate('/solicitar-financiacion');
                }
              }
              // setLoadingForm(false);
            }else if (!allNew && stages[1].estado === 6 && stages[2].estado === 1) {
              
              console.log('====================================');
              console.log(allNew);
              console.log('====================================');
              const body = {
                creditId: cacheCredit.numero,
                message: 'Cerrado automatico para abrir paso 2',
                status: 2,
                etapa: 1,
                rol: 'createHistorialEstadoEtapas',
              };
              await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
                .then((res) => {
                  if (res) {
                    console.log(res);
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
             // await createHistorialEstadoEtapas();
             navigate('/solicitar-financiacion');
            }
            
          }
          setLoading(false);
          setLoadingForm(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    let linea =
      (cacheCredit.lineaId === '2' && cacheCredit.universidadId === '2') ||
      (cacheCredit.lineaId === '5' && cacheCredit.universidadId === '3');
    if (
      cacheCredit.linea === 'Línea Pro' ||
      cacheCredit.linea === 'Línea Heroica'
    ) {
      linea = true;
    }
    if (linea) {
      FormService.getAnswers(cacheCredit.numero)
        .then((response) => {
          if (!(response.data?.length > 0)) {
            showFormApplicationDialog();
          } else {
            setLineProFirma(false);
          }
        })
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
    } else {
      setLineProFirma(false);
    }
  };
  // useEffect(() => {
  //     fetchCreditData();
  //     let intervalId = null; // Initialize intervalId variable

  //     if (loadingForm) {
  //     intervalId = setInterval(fetchCreditData, 10000); // Assign the interval ID to intervalId
  //     }
  //     return () => {
  //         if (intervalId !== null) {
  //           clearInterval(intervalId); // Clear the interval using the interval ID
  //         }
  //       };
  // }, [loadingForm]);
  useEffect(() => {
    const viewData = async () => {
      await fetchCreditData();
    };
    viewData();
  }, []);

  const showDialog = (url) =>
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p>
          No olvides REGRESAR a este punto luego de completar tu firma y de
          compartir con tus codeudores el enlace.
        </p>
        <p className="font-bold">
          ¡Todavía falta continuar y completar tu proceso!
        </p>
      </div>,
      { onConfirm: () => window.open(url, '_blank') }
    );
  const showFormApplicationDialog = () =>
    dialog.custom(<FormApplication />, {
      className: 'p-2',
      spacing: false,
      actions: (
        <Button
          customColor={'bg-custom-green'}
          onClick={() => {
            if (
              cacheCredit.lineaId === '2' ||
              cacheCredit.linea === 'Línea Pro'
            ) {
              navigate('./registro/linea-pro');
            } else if (
              cacheCredit.lineaId === '5' ||
              cacheCredit.linea === 'Línea Heroica'
            ) {
              navigate('./registro/linea-heroica');
            }
            dialog.close();
          }}
        >
          Responder Ahora
        </Button>
      ),
    });
  return loading ? (
    <Loading />
  ) : loadingForm ? (
    <section>
      <h3 className="font-bold items-center text-center text-[#40E0D0]">
        ¡Paso final de tu solicitud!
      </h3>
      <h4 className="font-bold items-center text-center">
        Autorización de consulta a centrales de información
      </h4>
      <div className="mt-4 grid grid-cols-1 divide-x-0 divide-y-2 lg:grid-cols-3 lg:divide-x-2 lg:divide-y-0">
        {creditInfo.map((req, index) => {
          const isStudent = req.type === 'studiant';
          return (
            <div
              key={index}
              className="flex flex-col space-y-3 px-14 py-4 text-center lg:py-0"
            >
              {isStudent ? (
                <>
                  <div className="font-bold">
                    <p>1. {req.name}</p>
                    <p>{req.documento}</p>
                  </div>
                  <p className="flex items-center">
                    Firma el siguiente documento digital. (Se te pedirá una
                    selfie y una foto de tu documento de identidad)
                  </p>
                  <div className="mt-5">
                    <Button
                      id="firma_documento"
                      disabled={lineProFirma}
                      onClick={() => showDialog(req.signUrl)}
                    >
                      ¡Firmar Documento!
                    </Button>
                  </div>
                  {req.status === 'pending' || req.status === 'link-opened' ? (
                    // Si req.status es 'pending'
                    <IdentityIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : req.status === 'new' ? (
                    // Si req.status es 'new'
                    <IdentityCursoIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : (
                    // Si req.status es otra cosa
                    <IdentityIconS className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  )}
                  <div>
                    <p>Estado de la firma:</p>
                    {req.status === 'pending' ||
                    req.status === 'link-opened' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Pendiente
                      </p>
                    ) : req.status === 'new' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Nuevo
                      </p>
                    ) : (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Exitosa
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="font-bold">
                    <p>
                      {index + 1}. {req.name}
                    </p>
                    <p>{req.documento}</p>
                  </div>
                  <p className="flex items-center">
                    <div>
                      Tu codeudor debe firmar el siguiente documento digital.
                      <span className="font-bold underline">
                        (Compártele el enlace
                      </span>
                      , se le pedirá una selfie y una foto de su documento de
                      identidad)
                    </div>
                  </p>
                  <div className="flex justify-center">
                    <ShareURL URL={req.signUrl} />
                  </div>
                  {req.status === 'pending' || req.status === 'link-opened' ? (
                    // Si req.status es 'pending'
                    <IdentityIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : req.status === 'new' ? (
                    // Si req.status es 'new'
                    <IdentityCursoIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : (
                    // Si req.status es otra cosa
                    <IdentityIconS className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  )}
                  <div>
                    <p className="w-auto">Estado de la firma:</p>
                    {req.status === 'pending' ||
                    req.status === 'link-opened' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Pendiente
                      </p>
                    ) : req.status === 'new' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Nuevo
                      </p>
                    ) : (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Exitosa
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <p className="text-[#40E0D0] text-center mt-5 font-bold">
        <span className="underline">Dale clic al botón “Actualizar”</span> para
        recargar el estado de la firma luego de realizarla
      </p>
      <div className="mx-auto my-8 space-y-8 text-center lg:max-w-2xl">
        <div>
          <Button
            customColor="bg-custom-red"
            id="firma_documento"
            disabled={lineProFirma}
            onClick={() => fetchCreditData()}
          >
            Actualizar
          </Button>
        </div>
        <p>
          Comprueba que tu firma y la de tus codeudores diga
          <span className="font-bold"> “Estado de la firma Exitosa”, </span>
          <span className="underline">sólo hasta ese momento</span>
          <span className="font-bold">
            {' '}
            habrás completado tu solicitud e iniciará nuestro proceso de
            análisis.
          </span>
        </p>
      </div>
    </section>
  ) : (
    <RequestFormDoc />
  );
};

export default Autorizacion;
