import React, { useState, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/DatePicker.css';
import { Controller } from 'react-hook-form';
import es from 'date-fns/locale/es';
import Spinner from '../shared/Spinner';
registerLocale('es', es);

const DatePicker = ({
  name,
  placeholder,
  type,
  value,
  className,
  control,
  validation,
  errors,
  disabled = false,
  loading = false,
}) => {
  const [active, setActive] = useState(loading || (value && true));
  useEffect(() => {
    setActive(loading || (value && true));
  }, [loading]);
  return (
    <div
      className={`relative ${
        errors ? 'text-error' : 'text-input-light dark:text-input-dark'
      } ${className}`}
    >
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            onChange={onChange}
            onBlur={(event) => {
              onBlur(event);
              setActive(loading || (event.target.value && true));
            }}
            onFocus={() => setActive(true)}
            selected={value ? new Date(value) : value}
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            showYearDropdown
            showMonthDropdown
            locale="es"
            dropdownMode="select"
            name={name}
            maxDate={new Date()}
            autoComplete={'off'}
            className={`form-Input block min-h-full w-full flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-light bg-opacity-100 py-2 pl-4 pr-10 shadow-light transition duration-500 dark:bg-dark dark:shadow-dark ${
              disabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            {...(type === 'year' && {
              showYearPicker: true,
              dateFormat: 'yyyy',
            })}
          />
        )}
      />

      {/* Loading spinner */}
      {loading ? (
        <div className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-6" />
        </div>
      ) : undefined}

      {/* Label */}
      <div
        className={`pointer-events-none absolute flex h-full w-full  origin-left items-center transition-all duration-500 ${
          active
            ? `-inset-y-2 -translate-y-1/2 text-2xs ${
                errors ? '' : 'text-custom-green'
              }`
            : `inset-y-0 px-4 ${disabled ? 'opacity-50' : ''}`
        }`}
      >
        <label
          htmlFor={name}
          className="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default DatePicker;
