import { API } from 'aws-amplify';

const get = ({ creditoNumero, usuarioId }) =>
  API.get('estrella', '/etapas', {
    queryStringParameters: {
      ...(creditoNumero && { creditoNumero }),
      ...(usuarioId && { usuarioId }),
    },
  });
  const update = ({ creditoNumero, etapas }) =>
    API.post('estrella', '/etapas', {
      body: {
        creditoNumero,
        etapas,
      },
    });

const EtapaService = {
  get,
  update,
};

export default EtapaService;
