import React, { useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/images/documents/selectorden.svg';
import { formatCurrency } from '../../utils/format';

function ButtonOrden({ ordenArray, onSeleccionChange }) {
  const [seleccion, setSeleccion] = useState(null);
  const handleClick = (id, orden) => {
    setSeleccion((prevState) => {
      const nuevaSeleccion = prevState === id ? null : id;
      if (id) {
        onSeleccionChange(orden);
      } else {
        onSeleccionChange(id);
      }
      return nuevaSeleccion;
    });
  };
  return (
    <div className="my-4 grid grid-cols-1 gap-4 gap-y-6 lg:my-6 lg:grid-cols-1 lg:gap-6">
      <div className="col-span-2 grid grid-cols-2 gap-8">
        {ordenArray.map((orden) => {
          if (orden.hide) {
            return null;
          }
          return (
            <div
              key={orden.id}
              className={`p-4 border-4 ${
                seleccion === orden.id
                  ? 'border-[10px] border-[#40e0d0]'
                  : 'border-gray-300'
              } rounded-lg`}
              onClick={() => {
                const newSeleccion = seleccion === orden.id ? null : orden.id;
                handleClick(newSeleccion, orden);
              }}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <UploadIcon />
                <p>Tipo de Orden: {orden.typeOrigen}</p>
                <p>Nombre: {orden.typeOrigenText}</p>
                <p>N° Orden: {orden.nOrdenMatricula}</p>
                <p>Fecha prevista de pago: {orden.fecha}</p>
                <p className="col-start-1 font-bold text-custom-purple dark:text-custom-green">
                  Valor a pagar: {formatCurrency(parseInt(orden.valorPago))}
                </p>
                <button onClick={() => handleClick(orden.id, orden)}></button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ButtonOrden;
