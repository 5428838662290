import { API } from 'aws-amplify';

const getAll = ({ etapaId, creditoNumero, usuarioId, tipoId }) =>
  API.get('estrella', '/archivos', {
    queryStringParameters: {
      ...(etapaId && { etapaId }),
      ...(creditoNumero && { creditoNumero }),
      ...(usuarioId && { usuarioId }),
      ...(tipoId && { tipoId }),
    },
  });

const update = ({
  archivoId,
  nombre,
  nombreOriginal,
  ruta,
  rutaEnBlanco,
  tipoId,
  estado,
  mensaje,
}) => {
  API.put('estrella', `/archivos/${archivoId}`, {
    body: {
      archivo: {
        ...(nombre && { nombre }),
        ...(nombreOriginal && { nombreOriginal }),
        ...(ruta && { ruta }),
        ...(rutaEnBlanco && { rutaEnBlanco }),
        ...(tipoId && { tipoId }),
      },
      ...(estado && { estado }),
      ...(mensaje && { mensaje }),
    },
  });
};
const postArchivoCreate = (creditoNumero, tipoId) =>
  API.post('estrella', '/archivos', {
    body: {
      creditoNumero: creditoNumero,
      tipoId: tipoId,
      codeudorId: null,
    },
  });

const ArchivoService = {
  getAll,
  update,
  postArchivoCreate,
};

export default ArchivoService;
