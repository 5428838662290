import React from 'react';
import Emoji from '../../components/shared/Emoji';

export const DIALOG = {
  N1: (
    <p className="text-justify">
      ¡Oh oh! ¡Lo sentimos!{' '}
      <Emoji symbol="confused-face" className="inline h-5" /> Nuestro Modelo de
      Acompañamiento Financiero, Profesional y Humano,{' '}
      <b>actualmente sólo está disponible para nacionales colombianos</b>; razón
      por la cual no puedes continuar con tu solicitud de financiación, pero
      gracias por estar aquí y permitirnos conocer tu interés en lo que hacemos.
    </p>
  ),
  N2: (
    <p className="text-justify">
      ¡Hola <Emoji symbol="waving-hand" className="inline h-5" />
      <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-5" />
      <Emoji symbol="white-exclamation-mark" className="inline h-5" /> De
      acuerdo con la información que registraste, el sistema nos indica que{' '}
      <b>es necesario añadir un codeudor a tu solicitud</b>.
    </p>
  ),
  N3: (
    <p className="text-justify">
      ¡Oh oh! ¡Lo sentimos!{' '}
      <Emoji symbol="confused-face" className="inline h-5" /> Tu y tus
      codeudores no superaron los procesos de análisis iniciales, por favor
      registra en el sistema otra(s) persona(s) que pueda(n) respaldarte.
    </p>
  ),
  N4: (
    <p className="text-justify">
      ¡Hola <Emoji symbol="waving-hand" className="inline h-5" />
      <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-5" />
      <Emoji symbol="white-exclamation-mark" className="inline h-5" /> Para
      continuar con tu solicitud de financiación,{' '}
      <b>es necesario que tu codeudor tenga nacionalidad colombiana</b>.
    </p>
  ),
  N5: (
    <p className="text-justify">
      ¡Hola <Emoji symbol="waving-hand" className="inline h-5" />
      <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-5" />
      <Emoji symbol="white-exclamation-mark" className="inline h-5" /> De
      acuerdo con la información que registras en el sistema,{' '}
      <b>es necesario incluir un codeudor más</b> a tu solicitud{' '}
      <b>o presentar otro</b> que pueda acreditar mayor solvencia.
    </p>
  ),
  N6: (
    <div>
      <p className="text-justify">
        Llegó el momento de hacer las verificaciones de seguridad, antes de ser
        redireccionado recuerda tener a la mano:
      </p>
      <div className="my-5 leading-none">
        <p>
          <b>1.</b> Tu celular 📲
        </p>
        <p>
          <b>2.</b> Tu documento de identidad 👤
        </p>
        <p>
          <b>3.</b> Acceso a tu correo electrónico 📥 Paciencia, la verificación
        </p>
      </div>
      <p>puede tardar unos minutos ⏰</p>
    </div>
  ),
  N7: (
    <p className="text-justify">
      <b>
        Por favor revisa <Emoji symbol="eyes" className="inline h-5" /> y carga
        de nuevo los documentos
      </b>{' '}
      resaltados en color rojo y marcados con asterisco.
    </p>
  ),
  N8: (
    <p className="text-center font-bold">
      ¡Nos acelera poder darte la bienvenida a Educación Estrella! Tu solicitud
      de financiación ha sido aprobada. Equipo Estrella{' '}
      <Emoji symbol="vulcan-salute" className="inline h-5" />
      <Emoji symbol="sparkles" className="inline h-5" />
      <Emoji symbol="star" className="inline h-5" />.
    </p>
  ),
  N9: (
    <p className="text-center font-bold">
      Tu solicitud de financiación no superó los procesos de análisis, te
      animamos a continuar trabajando con la vista fija en el sueño que tienes
      por delante.
    </p>
  ),
  N10: (
    <p className="text-center font-bold">
      Tu experiencia con Educación Estrella es muy importante para nosotros,
      actualmente estamos revisando tu solicitud de financiación y en el menor
      tiempo posible serás notificado del resultado{' '}
      <Emoji symbol="crossed-fingers" className="inline h-5" /> . Equipo
      Estrella <Emoji symbol="vulcan-salute" className="inline h-5" />
      <Emoji symbol="sparkles" className="inline h-5" />
      <Emoji symbol="star" className="inline h-5" /> .
    </p>
  ),
  N10_1: (
    <p className="text-center font-bold">
      Tu experiencia con Educación Estrella es muy importante para nosotros,
      actualmente estamos revisando la legalización de tu financiación y en el
      menor tiempo posible serás notificado del resultado{' '}
      <Emoji symbol="crossed-fingers" className="inline h-5" /> . Equipo
      Estrella <Emoji symbol="vulcan-salute" className="inline h-5" />
      <Emoji symbol="sparkles" className="inline h-5" />
      <Emoji symbol="star" className="inline h-5" /> .
    </p>
  ),
  N11: (
    <p className="text-center">
      ¡Oh oh! ¡Lo sentimos!{' '}
      <Emoji symbol="flushed-face" className="inline h-5" /> Parece que algo se
      desconectó, por favor vuelve a seleccionar nuevamente, los campos que
      habías elegido de las listas, o comunícate con nosotros HACIENDO{' '}
      <a
        href="https://educacionestrella.digiturno5.com:9023/Player.aspx?Recurso=EducacionEstrella_V3&MCT=0&MWA=0&MFN=1"
        target="_blank"
        rel="noreferrer"
        className="text-custom-purple hover:underline dark:text-custom-green"
      >
        CLIC AQUÍ
      </a>{' '}
      para que podamos ayudarte.
    </p>
  ),
  N12: (
    <div className="text-center">
      <p>
        ¡Uff! Utilizar acelerador todo el tiempo, a veces demanda una recarga
        rápida de apenas segundos{' '}
        <Emoji symbol="stopwatch" className="inline h-5" /> ¡Te pedimos por fa
        que esperes mientras hacemos el &quot;refill&quot; y te aparece en
        pantalla tu mensaje de confirmación.
      </p>
      <p>Gracias por ser paciente con buena onda.</p>
      <p>
        Un abrazo, Equipo Estrella{' '}
        <Emoji symbol="vulcan-salute" className="inline h-5" />
        <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-5" />
        .
      </p>
    </div>
  ),
  N13: (
    <div className="space-y-4 text-center">
      <p className="font-bold">
        ¡Muy importante
        <Emoji symbol="exclamation-mark" className="inline h-5" />
      </p>
      <p>
        Recuerda que TODA la información y todos los datos que registras con
        nosotros, serán verificados y contrastados en bases de datos oficiales,
        por eso es muy importante que sean VERACES Y VERIFICABLES.
      </p>
    </div>
  ),
  N14: (
    <div className="space-y-4 text-center">
      <p className="font-bold">
        ¡Muy importante
        <Emoji symbol="exclamation-mark" className="inline h-5" />
      </p>
      <p>
        Este será el valor final que desembolsaremos. Recuerda tener en cuenta
        todo lo que dice tu Orden de Matrícula, descuentos, becas, beneficios,
        costos adicionales o cualquier otro rubro para el pago de tus estudios.
      </p>
    </div>
  ),
  N15: (
    <div className="space-y-4 text-center">
      <p className="font-bold">
        ¡Muy importante
        <Emoji symbol="exclamation-mark" className="inline h-5" />
      </p>
      <p>Recuerda...</p>
      <p className="font-bold">
        1. ¡Más adelante este valor NO podrá ser modificado!
        <br />
        2. Lee las recomendaciones al lado del valor a financiar
      </p>
    </div>
  ),
  N16: (
    <p className="text-center">
      ¡Oh oh! ¡Lo sentimos!{' '}
      <Emoji symbol="flushed-face" className="inline h-5" /> Parece que algo se
      desconectó, por favor vuelve a seleccionar nuevamente, los campos que
      habías elegido de las listas, o comunícate con nosotros HACIENDO{' '}
      <a
        href="https://educacionestrella.digiturno5.com:9023/Player.aspx?Recurso=EducacionEstrella_V3&MCT=0&MWA=0&MFN=1"
        target="_blank"
        rel="noreferrer"
        className="text-custom-purple hover:underline dark:text-custom-green"
      >
        CLIC AQUÍ
      </a>{' '}
      para que podamos ayudarte.
    </p>
  ),
  UserNotFoundException: (
    <p className="text-center">
      El correo electrónico no se encuentra registrado.
    </p>
  ),
  NotAuthorizedException: (
    <p className="text-center">
      El correo electrónico o la contraseña son incorrectos.
    </p>
  ),
  InvalidPasswordException: (
    <p className="text-center">La contraseña es inválida.</p>
  ),
  UserNotConfirmedException: (
    <p className="text-center">
      No has confirmado tu correo electrónico, por favor ingresa el código que
      te fue enviado.
    </p>
  ),
  PasswordResetRequiredException: (
    <p className="text-center">
      Debes cambiar tu contraseña, por favor ingresa el código que te fue
      enviado.
    </p>
  ),
  CodeMismatchException: (
    <p className="text-center">
      El código de verificación es inválido. Vuelva a intentarlo.
    </p>
  ),
  CodeExpiredException: (
    <p className="text-center">
      El código de verificación ha vencido. Vuelva a intentarlo.
    </p>
  ),
  UsernameExistsException: (
    <p className="text-center">
      Ya existe una cuenta con este correo electrónico.
    </p>
  ),
  SuccessfulVerification: (
    <p className="text-center">
      La verificación fue exitosa, ahora puedes iniciar sesión.
    </p>
  ),
  PhoneNumberExistsException: (
    <p className="text-center">Ya existe una cuenta con este numero celular.</p>
  ),
  DocumentExistsException: (
    <p className="text-center">Ya existe una cuenta con este documento.</p>
  ),
  TemporaryPasswordExpiredException: (
    <p className="text-center">
      Tu contraseña temporal expiró, por favor comunícate con nosotros al{' '}
      <a
        href="https://api.whatsapp.com/send?phone=573225123511"
        target="_blank"
        rel="noreferrer"
        className="text-custom-purple hover:underline dark:text-custom-green"
      >
        WhatsApp Estrella 322 512 2322
      </a>{' '}
      para que podamos ayudarte.
    </p>
  ),
  SelectLineaProcess: (
    <p className="text-center">Selecciona una línea para continuar</p>
  ),
  SelectOMProcess: (
    <p className="text-center">Selecciona una Orden de Pago para continuar</p>
  ),
  SelectUniversity: <p className="text-center">Selecciona una Universidad</p>,
  UpdateArchiveProcess: (
    <p className="text-center">Recuerda, necesitas tu orden de matrícula</p>
  ),
  // se deja doble ya que posiblemente mas adelante va a cambiar
  UpdateArchiveProcessAPI: (
    <p className="text-center">Recuerda, necesitas tu orden de matrícula</p>
  ),
};
