import React from 'react';
import ButtonOrden from '../DynamicForm/ButtonOrden';

const ConsultApiOrden = ({ lineasArray, onSeleccionChange }) => {
  const handleSeleccionChange = (nuevaSeleccion) => {
    onSeleccionChange(nuevaSeleccion);
  };
  return (
    <>
      <div className="text-center">
        <h1 className="text-center text-3xl font-bold">¡Hola 👋😊❕</h1>
        <br />
        <p className="font-bold">
          ¡Wow! Acabamos de conectarnos con tu Universidad
        </p>
        <br />
        <p className="font-bold">
          Elige la orden de matrícula que necesitas financiar con nosotros
        </p>
      </div>
      <ButtonOrden
        ordenArray={lineasArray}
        onSeleccionChange={handleSeleccionChange}
      />
    </>
  );
};
export default ConsultApiOrden;
