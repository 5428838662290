import React from 'react';

const Tuple = ({ title, value }) => {
  return (
    <div className="flex space-x-4">
      <div className="font-bold">{title}</div>
      <div>{value}</div>
    </div>
  );
};

export default Tuple;
