import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import ProgressBar from '../shared/ProgressBar';
import { FILE_STATE } from '../../constants/files/fileState';
import prettyBytes from 'pretty-bytes';
import { BsCloudUpload } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

const FileDrop = ({
  title,
  text,
  Icon,
  originalFileName,
  fileState,
  message,
  uploadProps,
}) => {
  // Upload hook
  const { load, clearData, uploadProgress, fileData } = uploadProps;

  // Load file
  const onDrop = useCallback((acceptedFiles) => load(acceptedFiles[0]), []);

  const disabled = useMemo(
    () =>
      (fileState !== FILE_STATE.TO_BE_SENT &&
        fileState !== FILE_STATE.REJECTED) ||
      fileData !== undefined,
    [fileState, fileData]
  );

  // Dropzone definition
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDropAccepted: onDrop,
    maxFiles: 1,
    accept: { 'application/pdf': ['.pdf'] },
    disabled,
  });

  const renderUpload = (
    <div
      {...getRootProps()}
      className={`flex h-full w-full flex-col items-center justify-center p-2 ${
        isDragReject ? 'text-error' : ''
      } ${!disabled ? 'cursor-pointer' : ''}`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        isDragAccept ? (
          <>
            <BsCloudUpload className="h-12 w-auto fill-current" />
            <p className="mt-4 font-bold">{title}</p>
            <p className="text-sm">Suelta aquí....</p>
          </>
        ) : (
          <>
            <MdClose className="h-12 w-auto fill-current" />
            <p className="mt-4 font-bold">{title}</p>
            <p className="text-sm">Documento inválido</p>
          </>
        )
      ) : fileData ? (
        <>
          <Icon className="h-12 fill-current" />
          <p className="mt-4 font-bold">{title}</p>
          <div className="mt-2 inline-flex max-w-full space-x-2">
            <p className="overflow-hidden text-clip">
              {fileData.path} - {prettyBytes(fileData.size, {})}
            </p>
            <button
              onClick={() => clearData()}
              type="button"
              disabled={uploadProgress !== undefined}
            >
              <MdClose className="h-6 w-auto fill-current text-error" />
            </button>
          </div>
        </>
      ) : (
        <div
          className={`flex flex-col items-center ${
            fileState === FILE_STATE.REJECTED ? 'text-error' : ''
          }`}
        >
          <Icon className="h-8 fill-current" />
          <p className="mt-0 font-bold text-custom-gray">{title}</p>
          <p className="text-xs text-custom-gray">
            {fileState === FILE_STATE.REJECTED
              ? `*Volver a cargar el archivo${
                  message ? ' por: ' + message : ''
                }`
              : text}
          </p>
          {fileState === FILE_STATE.REJECTED ? (
            originalFileName && (
              <p className="mt-2">{`(${originalFileName})`}</p>
            )
          ) : (
            <p className="mt-2 text-custom-green">Selecciona o arrastra aquí</p>
          )}
        </div>
      )}
      {uploadProgress && (
        <div className="absolute bottom-0 left-0 w-full px-4 py-2">
          <ProgressBar progress={uploadProgress}></ProgressBar>
        </div>
      )}
    </div>
  );

  const uploadState = useMemo(() => {
    switch (fileState) {
      case FILE_STATE.TO_BE_SENT:
      case FILE_STATE.REJECTED:
        return true;
      case FILE_STATE.UNDER_REVIEW:
      case FILE_STATE.VERIFIED:
      case FILE_STATE.APPROVED:
        return false;
      default:
        break;
    }
  }, [fileState]);

  const renderBody = () => {
    return uploadState ? (
      renderUpload
    ) : (
      <div
        className={`flex h-full w-full flex-col items-center justify-center p-4 ${
          fileState === FILE_STATE.APPROVED ? 'text-custom-green' : ''
        }`}
      >
        <Icon
          className={`h-12 fill-current ${
            fileState !== FILE_STATE.APPROVED ? 'text-custom-green' : ''
          }`}
        />
        <p className="mt-4 font-bold text-custom-green">{title}</p>
        <p className="text-xs">
          {fileState !== FILE_STATE.APPROVED
            ? '*Archivo cargado y en revisión'
            : '*Archivo aprobado'}
        </p>
        {originalFileName && <p className="mt-2">{`(${originalFileName})`}</p>}
      </div>
    );
  };

  return (
    <div className="relative h-full w-full rounded-3xl bg-light text-center shadow-light transition duration-500 dark:bg-dark dark:shadow-dark">
      {renderBody()}
    </div>
  );
};

export default FileDrop;
