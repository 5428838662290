const formatDate = (
	dateString,
	{ yearOnly = false, short = false } = { yearOnly: false, short: false }
) => {
	const options = yearOnly
		? { year: 'numeric' }
		: { year: 'numeric', month: short ? 'short' : 'long', day: 'numeric' };
	const locale = short ? 'es' : undefined;
	return isNaN(dateString)
		? new Date(dateString).toLocaleDateString(locale, options)
		: '-';
};

const formatDateSummary = (
	dateString,
	{ short = false } = { yearOnly: false, short: false }
) => {
	const locale = 'es-ES';
	const date = isNaN(dateString) ? new Date(dateString) : null;

	if (date && !isNaN(date.getTime())) {
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const month = short
			? date.toLocaleString(locale, { month: 'short' })
			: date.toLocaleString(locale, { month: 'long' });

		return `${day} ${month} ${year}`;
	} else {
		return '-';
	}
};

const formatBool = bool => (bool === undefined ? '-' : bool ? 'Si' : 'No');

const formatNumber = number => (number ? (+number).toLocaleString() : '-');

const formatCurrency = currency =>
	currency ? '$ ' + (+currency).toLocaleString() : '-';

const formatCurrencyES = currency =>
	currency ? '$ ' + (+currency).toLocaleString('es-ES', {
		minimumFractionDigits: 0, // El número mínimo de decimales
		maximumFractionDigits: 0, // El número máximo de decimales
	  }) : '-';

const formatCurrencySummary = currency =>
	currency
		? '$ ' +
		  (+currency).toLocaleString('es', {
				useGrouping: true,
				maximumFractionDigits: 0,
		  })
		: '-';

const formatPercentage = percentage =>
	percentage ? formatNumber(+percentage * 100) + ' %' : '-';

export {
	formatDate,
	formatDateSummary,
	formatBool,
	formatNumber,
	formatCurrency,
	formatCurrencyES,
	formatCurrencySummary,
	formatPercentage,
};
