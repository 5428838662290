export const PAYMENT_STATUS = {
  4: {
    value: 4,
    label: 'Aprobada',
  },
  6: {
    value: 6,
    label: 'Declinada',
  },
  104: {
    value: 104,
    label: 'Error',
  },
  5: {
    value: 5,
    label: 'Expirada',
  },
  7: {
    value: 7,
    label: 'Pendiente',
  },
};
