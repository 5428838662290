import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Layout/Header';
import Navbar from '../components/Layout/Navbar';

const Layout = () => {
  return (
    <div className="flex h-screen flex-col-reverse overflow-hidden bg-light font-montserrat text-sm text-light transition duration-500 dark:bg-dark dark:text-dark lg:flex-row lg:text-base">
      <Navbar />
      <div className="flex flex-1 flex-col overflow-y-auto px-4 lg:px-32">
        <Header />
        <main className="flex flex-1 flex-col">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
