import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth-context';
import { useDialog } from './dialog-context';
import EtapaService from '../services/stage/etapa-service';
import { DIALOG } from '../constants/dialog/dialog';
import CreditoService from '../services/credit/credito-service';
import { Cache } from 'aws-amplify';

const StepContext = createContext({
  step: {},
  setStep: () => {},
  fetchStep: () => {},
});

export function StepProvider({ children }) {
  const [step, setStep] = useState({});
  const dialog = useDialog();
  const { user } = useAuth();
  async function consultCredit(loanId) {
    if (loanId !== undefined) {
      await CreditoService.get(loanId)
        .then((res) => {
          // se deberia validar si la cache existe y si la data de la cache es la misma para no remplazar o posiblemente quitarnos una consulta
          Cache.setItem('requestCredit', res);
          Cache.setItem('cacheCredit', res);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  const fetchStep = () =>
    EtapaService.get({ usuarioId: user.sub })
      .then((response) => {
        setStep(response);
        consultCredit(response.loanId);
        return response;
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });
  useEffect(() => {
    if (user) fetchStep();
    else setStep({});
  }, [user]);
  const value = { step, setStep, fetchStep };
  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
}

export function useStep() {
  const context = useContext(StepContext);
  if (!context) throw new Error('useStep must be used within StepProvider');
  return context;
}
