import React from 'react';

function Checkbox({
  name,
  placeholder,
  validation,
  register,
  disabled = false,
  value,
  onChange,
  checked,
}) {
  return (
    <div className="flex flex-row items-center" title={placeholder}>
      <input
        type="checkbox"
        disabled={disabled}
        className="-mr-8 h-8 w-8 shrink-0 opacity-0"
        {...((register && register(name, validation)) || {
          value,
          onChange,
          checked,
        })}
      />
      <div
        className={
          'mr-2 h-8 w-8 shrink-0 rounded-full border-6 bg-white focus-within:border-custom-green' +
          ` ${disabled ? 'cursor-not-allowed opacity-50' : ''}`
        }
      />
      <label className="overflow-hidden text-ellipsis">{placeholder}</label>
    </div>
  );
}

export default Checkbox;
