import { API } from 'aws-amplify';

const get = ({ universityId, amount, taxCategory }) =>
	API.get('estrella', `/guest/loan-simulations/universities/${universityId}`, {
		queryStringParameters: { amount, taxCategory },
	});

const create = ({
	academicLevelId,
	amount,
	email,
	lineId,
	name,
	period,
	phone,
	programCod,
	taxCategory,
	universityId,
	university,
	program,
}) =>
	API.post('estrella', '/guest/loan-simulations', {
		body: {
			academicLevelId,
			amount,
			email,
			lineId,
			name,
			period,
			phone,
			programCod,
			taxCategory,
			universityId,
			university,
			program,
		},
	});

const LoanSimulationService = {
	get,
	create,
};

export default LoanSimulationService;
