import { ReactComponent as SignIcon } from '../../assets/images/documents/sign.svg';
import { ReactComponent as OrderIcon } from '../../assets/images/documents/order.svg';

export const FILES = {
  // Soporte
  DOCUMENTO_IDENTIDAD: {
    title: 'Documento de Identidad',
    text: '(Escaneado en alta resolución y con todos los bordes visibles)',
    Icon: SignIcon,
    fileName: 'documento_de_identidad',
  },
  ORDEN_MATRICULA: {
    title: 'Orden de Matrícula',
    text: '(Generada por la Universidad)',
    Icon: OrderIcon,
    fileName: 'orden_de_matricula',
  },
  AUTORIZACION_CENTRALES_RIESGO: {
    title: 'Autorización Centrales de Riesgo',
    text: '(Escaneada en alta resolución, con firma y huella)',
    Icon: SignIcon,
    fileName: 'autorizacion_centrales_de_riesgo',
    rutaEnBlanco: 'autorizacion_centrales_de_riesgo.pdf',
    customPrefix: 'plantillas/',
  },
  SABER_11: {
    title: 'Saber 11°',
    text: '(Certificado de resultados)',
    Icon: SignIcon,
    fileName: 'saber_11',
  },
  CERTIFICADO_LABORAL_PENSION: {
    title: 'Certificado laboral',
    text: '(Si eres pensionado, certificado de pensión)',
    Icon: SignIcon,
    fileName: 'certificado_laboral_o_pension',
  },
  DESPRENDIBLES_PAGO: {
    title: 'Últimos 3 desprendibles de pago',
    text: '(En un solo archivo sin contraseña)',
    Icon: SignIcon,
    fileName: 'desprendibles_de_pago',
  },
  CERTIFICADO_INGRESOS_CONTADOR: {
    title: 'Certificado de ingresos contador',
    text: '(Dependiento de la ocupación)',
    Icon: SignIcon,
    fileName: 'certificado_de_ingresos_contador',
  },
  DOCUMENTOS_CONTADOR: {
    title: 'Documentos contador',
    text: '(Cédula, tarjeta profesional y antecedentes disciplinarios)',
    Icon: SignIcon,
    fileName: 'documentos_contador',
  },
  EXTRACTOS_BANCARIOS: {
    title: 'Últimos 3 extractos bancarios',
    text: '(En un solo archivo sin contraseña)',
    Icon: SignIcon,
    fileName: 'extractos_bancarios',
  },
  DECLARACION_RENTA: {
    title: 'Última declaración de renta',
    text: '(En caso de contar con ingresos de más de 4 SMMLV)',
    Icon: SignIcon,
    fileName: 'ultima_declaracion_de_renta',
  },
  // Legalizacion
  CONTRATO_CREDITO: {
    title: 'Contrato de crédito',
    text: '(Escaneado en alta resolución, con firmas y huellas visibles)',
    Icon: SignIcon,
    fileName: 'contrato_de_credito',
  },
  SOLICITUD_DESEMBOLSO: {
    title: 'Solicitud de desembolso',
    text: '(Escaneado en alta resolución, con firmas y huellas visibles)',
    Icon: SignIcon,
    fileName: 'solicitud_de_desembolso',
  },
  FORMATO_ASEGURADORA: {
    title: 'Formato aseguradora',
    text: '(Solicitud certificado seguro de vida diligenciado por estudiante y codeudor)',
    Icon: SignIcon,
    fileName: 'formato_aseguradora',
  },
  CERTIFICADO_NOTAS: {
    title: 'Certificado de notas',
    text: '(Último semestre cursado)',
    Icon: SignIcon,
    fileName: 'certificado_notas',
  },
  TABLA_AMORTIZACION: {
    title: 'Tabla de Amortización',
    text: '*Este documento es informativo para ti, no debes firmarlo, ni enviarlo de vuelta',
    Icon: SignIcon,
    fileName: 'tabla_amortizacion',
  },
};
