import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import BannerImage from '../assets/images/landing-payu/realizaste_pago.jpg';
import { PAYMENT_STATUS } from '../constants/payment/paymentStatus';
import { PAYMENT_METHODS } from '../constants/payment/paymentMethods';
import Button from '../components/DynamicForm/Button';
import { formatCurrency } from '../utils/format';

const LandingPayu = () => {
  const [searchParams] = useSearchParams();
  const transactionState = searchParams.get('transactionState');
  const referenceCode = searchParams.get('referenceCode');
  const referencePol = searchParams.get('reference_pol');
  const txValue = searchParams.get('TX_VALUE');
  const processingDate = searchParams.get('processingDate');
  const polPaymentMethodType = searchParams.get('polPaymentMethodType');
  const description = searchParams.get('description');

  return (
    <div className="flex flex-col items-center">
      <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
        Estado del pago
      </h2>
      <p className="pb-4 text-center text-lg">
        <b>Resumen general</b>
      </p>
      <div className="space-y-5">
        <div className="flex flex-1 items-center justify-center">
          <img src={BannerImage} alt="banner, realizaste un pago estrella" />
        </div>
        <p>
          <b>
            Tu transacción ha sido: {PAYMENT_STATUS[transactionState]?.label}
          </b>
        </p>
        <p>
          <b>Descripción:</b> payment for loan with id {referenceCode} -{' '}
          {referencePol} - {description}
        </p>
        <p>
          <b>Referencia:</b> {referenceCode} - {referencePol}
        </p>
        <p>
          <b>Valor:</b> {formatCurrency(txValue)}
        </p>
        <p>
          <b>Fecha:</b> {processingDate}
        </p>
        <p>
          <b>Medio de pago/Franquicia:</b>{' '}
          {PAYMENT_METHODS[polPaymentMethodType]?.label}
        </p>
      </div>
      <Button>
        <Link to={'/'}>Cerrar ventana</Link>
      </Button>
    </div>
  );
};

export default LandingPayu;
