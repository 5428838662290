export const FORM_ITEMS = {
  SINGLE_CHOICE: 'single_choice',
  MULTIPLE_CHOICE: 'multiple_choice',
  SUPPORT_FILE: 'support_file',
  ENTITY_LIST: 'entity_list',
  SELECT: 'select',
  TEXT: 'text',
  CURRENCY: 'currency',
  ITEMS: 'items',
  RAW_TEXT: 'raw_text',
};
