import { useState } from 'react';

const useInput = ({
  errors,
  getValues,
  register,
  control,
  validations,
  disabled,
}) => {
  const [loadings, setLoadings] = useState({});
  const setLoading = (name, value) =>
    setLoadings((current) => ({ ...current, [name]: value }));
  const InputProps = (name) => ({
    name,
    errors: errors[name],
    register,
    control,
    value: getValues(name),
    validation: validations[name],
    loading: loadings[name] ?? false,
    disabled,
  });
  return { InputProps, setLoading };
};

export default useInput;
