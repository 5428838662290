import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import * as CONSTANTS from '../../../constants/form/request-form';
import { INFO_ESTUDIANTE } from '../../../constants/form/validations';
import { DIALOG } from '../../../constants/dialog/dialog';
import { useDialog } from '../../../context/dialog-context';
import ConstantesSolicitudService from '../../../services/shared/constantes-solicitud-service';
import FormInput from '../../../components/Input/FormInput';
import useInput from '../../../hooks/use-input';
import ChangeWords from '../../../utils/changeWords';

const InfoEstudiante = ({ formConstants }) => {
  const isMounted = useRef(false);
  const dialog = useDialog();
  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: INFO_ESTUDIANTE,
  });

  const departamentos = [
    'departamento_nacimiento',
    'departamento_expedicion',
    'departamento_residencia',
  ];
  const ciudades = [
    'ciudad_nacimiento',
    'ciudad_expedicion',
    'ciudad_residencia',
  ];
  const watchDepartamentos = watch(departamentos);
  const watchTrabaja = watch('trabaja');
  const extranjero = watch('tipo_documento')?.toString() === '3';
  const menorEdad = watch('tipo_documento')?.toString() === '2';
  watch(ciudades);

  const fetchCiuades = async (index) => {
    const departamento = watchDepartamentos[index];
    if (departamento && !Cache.getItem(`CIUDADES_${departamento}`)) {
      setLoading(ciudades[index], true);
      await ConstantesSolicitudService.getCiudades(departamento)
        .then((response) => Cache.setItem(`CIUDADES_${departamento}`, response))
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
      setLoading(ciudades[index], false);
      setValue(ciudades[index], getValues(ciudades[index]));
    }
  };

  // creates a useEffect for each departamento in watchDepartamentos
  for (let i = 0; i < watchDepartamentos.length; i++) {
    useEffect(() => {
      fetchCiuades(i);
    }, [watchDepartamentos[i]]);
  }

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const [formValues, setFormValues] = useState({
    nombres: watch('nombres'),
    apellidos: watch('apellidos'),
    correo_electronico: watch('correo_electronico'),
  });

  const handleChangeToUpperCase = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: ChangeWords.changeFirstInUpperCase(value),
    }));
  };

  const handleChangeToLowerCase = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: ChangeWords.ChangeAllInLowerCase(value),
    }));
  };

  return (
    <>
      <section>
        <h4 className="font-bold">
          Información exclusiva del Estudiante Estrella
        </h4>
        <div className="my-4 grid grid-cols-4 gap-4 gap-y-6 lg:my-6 lg:grid-cols-12 lg:gap-6">
          <FormInput
            {...InputProps('nombres')}
            placeholder="Nombres"
            type="textChangeWord"
            className="col-span-4 lg:col-span-3"
            value={formValues.nombres}
            onChange={handleChangeToUpperCase}
          />
          <FormInput
            {...InputProps('apellidos')}
            placeholder="Apellidos"
            type="textChangeWord"
            className="col-span-2 lg:col-span-3"
            value={formValues.apellidos}
            onChange={handleChangeToUpperCase}
          />
          <FormInput
            {...InputProps('tipo_documento')}
            placeholder="Tipo documento"
            type="select"
            options={formConstants.TIPO_DOCUMENTO}
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps('documento')}
            placeholder="No. Documento"
            type="document"
            readOnly={true}
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps('fecha_expedicion')}
            placeholder="Fecha expedición"
            type="date"
            className="col-span-2"
          />
          <FormInput
            {...InputProps('departamento_expedicion')}
            placeholder="Departamento de expedición"
            type="select"
            options={formConstants.DEPARTAMENTO}
            className="col-span-2"
          />
          <FormInput
            {...InputProps('ciudad_expedicion')}
            placeholder="Ciudad expedición"
            type="select"
            options={
              Cache.getItem(
                `CIUDADES_${getValues('departamento_expedicion')}`
              ) ?? []
            }
            className="col-span-2"
          />
          <FormInput
            {...InputProps('fecha_nacimiento')}
            placeholder="Fecha nacimiento"
            type="date"
            className="col-span-2 lg:col-span-3"
          />
          {!extranjero && (
            <>
              <FormInput
                {...InputProps('departamento_nacimiento')}
                placeholder="Departamento nacimiento"
                type="select"
                options={formConstants.DEPARTAMENTO}
                className="col-span-2 lg:col-span-3"
              />
              <FormInput
                {...InputProps('ciudad_nacimiento')}
                placeholder="Ciudad nacimiento"
                type="select"
                options={
                  Cache.getItem(
                    `CIUDADES_${getValues('departamento_nacimiento')}`
                  ) ?? []
                }
                className="col-span-2 "
              />
            </>
          )}
          <FormInput
            {...InputProps('direccion')}
            placeholder="Dirección residencia"
            type="text"
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps('departamento_residencia')}
            placeholder="Departamento residencia"
            type="select"
            options={formConstants.DEPARTAMENTO}
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps('ciudad_residencia')}
            placeholder="Ciudad residencia"
            type="select"
            options={
              Cache.getItem(
                `CIUDADES_${getValues('departamento_residencia')}`
              ) ?? []
            }
            className="col-span-2"
          />
          <FormInput
            {...InputProps('telefono')}
            placeholder="Teléfono fijo"
            type="home-phone"
            className="col-span-2"
          />
          <FormInput
            {...InputProps('estado_civil')}
            placeholder="Estado civil"
            type="select"
            options={formConstants.ESTADO_CIVIL}
            className="col-span-2"
          />
          <FormInput
            {...InputProps('celular')}
            placeholder="Celular"
            type="cell-phone"
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps('correo_electronico')}
            placeholder="Correo"
            type="textChangeWord"
            className="col-span-2 lg:col-span-3"
            value={formValues.correo_electronico}
            onChange={handleChangeToLowerCase}
          />
          {!menorEdad && (
            <>
              <FormInput
                {...InputProps('trabaja')}
                placeholder="¿Trabajas actualmente?"
                type="select"
                options={CONSTANTS.BINARIO}
                className="col-span-2"
              />
            </>
          )}
          {watchTrabaja?.toString() === 'false' && (
            <FormInput
              {...InputProps('responsable_economico')}
              placeholder="¿Quién responde por ti económicamente?"
              type="select"
              options={formConstants.RESPONSABLE_ECONOMICO}
              className="col-span-4 lg:col-span-3"
            />
          )}
        </div>
      </section>
    </>
  );
};

export default InfoEstudiante;
