export const PAYMENT_METHODS = {
  2: {
    value: 2,
    label: 'Tarjeta de Crédito',
  },
  4: {
    value: 4,
    label: 'Transferencias bancarias PSE',
  },
  5: {
    value: 5,
    label: 'Débitos ACH',
  },
  6: {
    value: 6,
    label: 'Tarjeta débito',
  },
  7: {
    value: 7,
    label: 'Efectivo',
  },
  8: {
    value: 8,
    label: 'Referencia de pago',
  },
  10: {
    value: 10,
    label: 'Pago en bancos',
  },
  14: {
    value: 14,
    label: 'Transferencias bancarias SPEI',
  },
};
