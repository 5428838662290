import React from 'react';
import './styles/App.css';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { DarkModeProvider } from './context/dark-mode-context';
import { DialogProvider } from './context/dialog-context';
import { AuthProvider } from './context/auth-context';
import { StepProvider } from './context/step-context';
import { NavbarProvider } from './context/navbar-context';
import AppRouter from './pages/AppRouter';
import { useForm, FormProvider } from 'react-hook-form';
// import ReactGA from "react-ga4";

Amplify.configure(awsExports);
// ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
// ReactGA.send("pageview");

function App() {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <DarkModeProvider>
        <NavbarProvider>
          <AuthProvider>
            <DialogProvider>
              <StepProvider>
                <div className="App">
                  <AppRouter />
                </div>
              </StepProvider>
            </DialogProvider>
          </AuthProvider>
        </NavbarProvider>
      </DarkModeProvider>
    </FormProvider>
  );
}

export default App;
