import { API } from 'aws-amplify';

const get = () => API.get('estrella', '/constantes/solicitud', {});

const getCiudades = (departamentoCod) =>
  API.get('estrella', '/constantes/ciudades', {
    queryStringParameters: { departamentoCod },
  });

const getProgramas = (universidadId) =>
  API.get('estrella', '/constantes/programas', {
    queryStringParameters: { universidadId },
  });

const getPrograma = (programaCod) =>
  API.get('estrella', `/constantes/programas/${programaCod}`, {});

const getProgramasByNivelAcademico = (universidadId, nivelAcademicoId) =>
  API.get('estrella', '/constantes/programas', {
    queryStringParameters: {
      universidadId,
      nivelAcademicoId,
    },
  });

const getJornadasByPrograma = (programaCod) =>
  API.get('estrella', '/constantes/jornadas', {
    queryStringParameters: { programaCod },
  });

const getGuest = () => API.get('estrella', '/guest/constants', {});

const getGuestProgramas = (universidadId) =>
  API.get('estrella', '/guest/constants/programs', {
    queryStringParameters: { universityId: universidadId },
  });

const getGuestPrograma = (programaCod) =>
  API.get('estrella', `/guest/constants/programs/${programaCod}`, {});

const getGuestProgramasByNivelAcademico = (universidadId, nivelAcademicoId) =>
  API.get('estrella', '/guest/constants/programs', {
    queryStringParameters: {
      universityId: universidadId,
      academicLevelId: nivelAcademicoId,
    },
  });

const getGuestJornadasByPrograma = (programaCod) =>
  API.get('estrella', '/guest/constants/shifts', {
    queryStringParameters: { programCod: programaCod },
  });

const getGuestUniversities = () =>
  API.get('estrella', '/guest/constants/universities', {});

const ConstantesSolicitudService = {
  get,
  getCiudades,
  getProgramas,
  getPrograma,
  getProgramasByNivelAcademico,
  getJornadasByPrograma,
  guest: {
    get: getGuest,
    getProgramas: getGuestProgramas,
    getPrograma: getGuestPrograma,
    getProgramasByNivelAcademico: getGuestProgramasByNivelAcademico,
    getJornadasByPrograma: getGuestJornadasByPrograma,
    getUniversidades: getGuestUniversities,
  },
};

export default ConstantesSolicitudService;
