import React, { useState, useEffect } from 'react';
import Checkbox from '../Input/Checkbox';
import { FORM_ITEMS } from '../../constants/form/dynamic-form';

const OptionList = ({ type, options, cols = 1, onChange }) => {
  const [checkedList, setCheckedList] = useState(
    options.reduce((current, option) => ({ ...current, [option]: false }), {})
  );

  const handleCheckedList = (value) => {
    if (type === FORM_ITEMS.MULTIPLE_CHOICE) {
      setCheckedList((current) => ({
        ...current,
        [value]: !checkedList[value],
      }));
    } else {
      const check = options.reduce(
        (current, option) => ({ ...current, [option]: false }),
        {}
      );
      check[value] = true;
      setCheckedList(check);
    }
  };

  useEffect(() => {
    const checked = Object.keys(checkedList).filter((key) => checkedList[key]);
    onChange(
      checked.length
        ? type === FORM_ITEMS.MULTIPLE_CHOICE
          ? checked
          : checked[0]
        : undefined
    );
  }, [checkedList]);

  const gridCols = {
    1: 'xl:grid-cols-1 grid-cols-1',
    2: 'xl:grid-cols-2 grid-cols-2',
    3: 'xl:grid-cols-3 grid-cols-2',
    4: 'xl:grid-cols-4 grid-cols-2',
  };

  return (
    <div className={`grid gap-x-4 gap-y-2 ${gridCols[cols]}`}>
      {options?.map((option, index) => {
        return (
          <Checkbox
            key={index}
            placeholder={option}
            value={option}
            checked={checkedList[option]}
            onChange={(e) => handleCheckedList(e.target.value)}
          />
        );
      })}
    </div>
  );
};

export default OptionList;
