import React, { useState, useEffect, useMemo } from 'react';
import { GoTriangleDown } from 'react-icons/go';
import Spinner from '../shared/Spinner';

const Select = ({
  name,
  placeholder,
  options,
  value,
  className,
  register,
  validation,
  errors,
  disabled = false,
  loading = false,
  id,
}) => {
  const inOptions = (value) =>
    options?.some((option) => option.value.toString() === value?.toString());
  const isValid = useMemo(
    () => inOptions(value),
    [JSON.stringify(options), value]
  );
  const [active, setActive] = useState(loading || isValid);
  useEffect(() => {
    setActive(loading || isValid);
  }, [loading, value, isValid]);
  validation = validation
    ? {
        ...validation,
        validate: {
          ...validation.validate,
          notDefault: (value) =>
            validation.required?.value === false ||
            disabled ||
            (value !== undefined && value !== '') ||
            validation.required?.message,
          notValid: (value) =>
            validation.required?.value === false ||
            disabled ||
            inOptions(value) ||
            validation.required?.message,
        },
      }
    : {};
  const props = register(name, validation);
  const onBlurRegister = props.onBlur;
  props.onBlur = (event) => {
    setActive(loading || inOptions(event.target.value));
    onBlurRegister(event);
  };
  props.onFocus = () => setActive(true);

  return (
    <div
      className={`relative ${
        errors ? 'text-error' : 'text-input-light dark:text-input-dark'
      } ${className}`}
    >
      <GoTriangleDown className="pointer-events-none absolute right-3 top-1/2 w-3 -translate-y-1/2 transform fill-current" />
      <select
        id={id}
        title={placeholder}
        disabled={disabled}
        {...props}
        className={`form-Input block min-h-full w-full flex-grow appearance-none overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-light bg-opacity-100 py-2 pl-4 pr-10 shadow-light transition duration-500 dark:bg-dark dark:shadow-dark ${
          disabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
      >
        <option value="0" />
        {options &&
          options.map(({ value, label }) => (
            <option
              className="text-form-light dark:text-form-dark"
              key={value}
              value={value}
            >
              {label}
            </option>
          ))}
      </select>

      {/* Loading spinner */}
      {loading ? (
        <div className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-6" />
        </div>
      ) : undefined}

      {/* Label */}
      <div
        className={`pointer-events-none absolute flex h-full w-full origin-left items-center transition-all duration-500 ${
          active
            ? `-inset-y-2 -translate-y-1/2 text-2xs ${
                errors ? '' : 'text-custom-green'
              }`
            : `inset-y-0 pl-4 pr-8 ${disabled ? 'opacity-50' : ''}`
        }`}
      >
        <label
          htmlFor={name}
          className="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default Select;
