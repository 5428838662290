import { API } from 'aws-amplify';

const postOpenCoop = (body) =>
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'financiero',
    },
  });

const OpenCoopCrmService = {
  postOpenCoop,
};



export default OpenCoopCrmService;
