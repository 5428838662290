import React, { useState, useEffect, useRef } from 'react';
import Spinner from './Spinner';

const LoadingComponent = ({
  children,
  onClick,
  className,
  spinnerClassName,
}) => {
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return loading ? (
    <Spinner className={`fill-current ${spinnerClassName}`} />
  ) : (
    <span
      onClick={async () => {
        if (onClick) {
          setLoading(true);
          await onClick();
          if (isMounted.current) setLoading(false);
        }
      }}
      className={className}
    >
      {children}
    </span>
  );
};

export default LoadingComponent;
