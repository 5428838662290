import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../shared/Spinner';

function Button({
  onClick,
  type = 'button',
  disabled = false,
  loading = false,
  children,
  className,
  customColor,
  id,
}) {
  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <button
      className={
        'relative rounded-full py-2  px-7 text-button disabled:opacity-50' +
        ` ${customColor ?? 'bg-custom-purple dark:bg-custom-green'}` +
        ` ${
          disabled
            ? 'cursor-not-allowed'
            : isLoading
            ? 'cursor-progress'
            : 'cursor-pointer'
        }` +
        ` ${className}`
      }
      onClick={async () => {
        if (onClick) {
          setIsLoading(true);
          await onClick();
          if (isMounted.current) setIsLoading(false);
        }
      }}
      type={type}
      disabled={disabled || isLoading}
      id={id}
    >
      {isLoading ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-6" />
        </div>
      ) : undefined}
      <span className={isLoading ? 'invisible' : ''}>{children}</span>
    </button>
  );
}

export default Button;
