import React, { useState } from 'react';

const Textarea = ({ placeholder, onChange, value = '', limit = 100 }) => {
  const [count, setCount] = useState(value.length);

  const handleChange = (e) => {
    const { value } = e.target;
    setCount(value.length);
    onChange(value);
  };

  return (
    <div>
      <textarea
        className="aspect-video w-full resize-none rounded-3xl bg-inherit py-2 px-4 shadow-light dark:shadow-dark"
        id={placeholder}
        title={placeholder}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={value}
        maxLength={limit}
      />
      <p className="pointer-events-none -mt-7 mr-3 text-right text-xs opacity-50">
        {count}/{limit}
      </p>
    </div>
  );
};

export default Textarea;
