import { API } from 'aws-amplify';

const postHubspot = (body) =>
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'crmhubspot',
    },
  });

const HubspotCrmService = {
  postHubspot,
};

export default HubspotCrmService;
