import React from 'react';
import Input from './Input';
import InputChangeWord from './InputChangeWord';
import Select from './Select';
import DatePicker from './DatePicker';
import Checkbox from './Checkbox';
import FormattedInput from './FormattedInput';

const FormInput = (props) => {
  let CurrentInput;
  switch (props.type) {
    case 'date':
    case 'year':
      CurrentInput = DatePicker;
      break;
    case 'select':
      CurrentInput = Select;
      break;
    case 'checkbox':
      CurrentInput = Checkbox;
      break;
    case 'textChangeWord':
      CurrentInput = InputChangeWord;
      break;
    case 'cell-phone':
    case 'home-phone':
    case 'phone':
    case 'currency':
    case 'document':
      CurrentInput = FormattedInput;
      props = { ...props, register: undefined };
      break;
    default:
      CurrentInput = Input;
      break;
  }
  const errors = props.loading ? undefined : props.errors;
  const showErrorMessage = props.showErrorMessage ?? true;
  return (
    <div className={props.className}>
      <CurrentInput {...{ ...props, errors }} />
      {showErrorMessage && errors ? (
        <span className="mt-0.5 block px-3 text-xs text-error">
          *{errors.message}
        </span>
      ) : undefined}
    </div>
  );
};

export default FormInput;
