import React from 'react';
import { NumericFormat } from 'react-number-format';

const InputFormat = ({ title: placeholder, onChange, value = '' }) => {
  const currencyProps = {
    prefix: '$ ',
    thousandSeparator: '.',
    decimalSeparator: ',',
    allowNegative: false,
    allowLeadingZeros: false,
    decimalScale: 2,
    valueIsNumericString: true,
  };

  return (
    <NumericFormat
      id={placeholder}
      title={placeholder}
      placeholder={placeholder}
      defaultValue={value}
      type="text"
      onValueChange={(values) => onChange(values.value)}
      className="w-full overflow-hidden text-ellipsis rounded-full bg-inherit py-2 px-4 shadow-light dark:shadow-dark"
      {...currencyProps}
    />
  );
};

export default InputFormat;
