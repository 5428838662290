import React from 'react';
import YoutubeEmbed from '../components/shared/YoutubeEmbed';
import { useDialog } from '../context/dialog-context';
import { MdClose } from 'react-icons/md';

const useVideo = () => {
  const dialog = useDialog();
  const showVideo = (embedId) =>
    dialog.custom(
      <>
        <div className="flex items-center justify-between border-b-1 p-2 pl-4 dark:border-custom-green">
          <span className="font-bold"></span>
          <button onClick={() => dialog.close()}>
            <MdClose className="h-6 w-auto" />
          </button>
        </div>
        <div className="w-[90vw] overflow-hidden rounded-3xl rounded-t-none shadow-light dark:shadow-dark lg:w-[75vw]">
          {embedId ? (
            <YoutubeEmbed embedId={embedId} />
          ) : (
            <div className="flex aspect-video h-auto w-full items-center justify-center">
              <p className="text-4xl font-bold">PRÓXIMAMENTE...</p>
            </div>
          )}
        </div>
      </>,
      { className: 'max-w-none', spacing: false }
    );
  return { showVideo };
};

export default useVideo;
