import React, { createContext, useContext, useState, useEffect } from 'react';

const defaultDarkMode = true;

const DarkModeContext = createContext({
  darkMode: defaultDarkMode,
  setDarkMode: () => {},
});

export function DarkModeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(
    typeof window !== 'undefined'
      ? localStorage.darkMode === undefined
        ? defaultDarkMode
        : localStorage.darkMode === 'true'
      : defaultDarkMode
  );
  const value = { darkMode, setDarkMode };
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(!darkMode ? 'dark' : 'light');
    root.classList.add(darkMode ? 'dark' : 'light');
    if (typeof window !== 'undefined') {
      localStorage.setItem('darkMode', darkMode);
    }
  }, [darkMode]);
  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
}

export function useDarkMode() {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error('useDarkMode must be used within DarkModeProvider');
  }
  return {
    darkMode: context.darkMode,
    toggleDarkMode: () => context.setDarkMode((current) => !current),
  };
}
