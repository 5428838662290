import React from 'react';

const Select = ({ title: placeholder, options, onChange, value }) => {
	return (
		<select
			title={placeholder}
			onChange={e => onChange(e.target.value)}
			className='w-full overflow-hidden text-ellipsis rounded-full bg-light py-2 px-4 shadow-light dark:bg-dark dark:shadow-dark'
			defaultValue=''
		>
			<option value='' disabled>
				{placeholder}
			</option>
			{options &&
				options.map(option => (
					<option key={option} value={value}>
						{option}
					</option>
				))}
		</select>
	);
};

export default Select;
