import React, { useEffect, useState } from 'react';
import Loading from '../components/shared/Loading';
import Emoji from '../components/shared/Emoji';
import { useAuth } from '../context/auth-context';
import background1 from '../assets/images/home/P1.png';
import background2 from '../assets/images/home/P3.png';
import background3 from '../assets/images/home/P2.png';
import background4 from '../assets/images/home/P4.png';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="flex flex-1 flex-col items-center justify-center">
      <h1 className="text-center text-3xl font-bold">
        ¡Hola {user.given_name} bienvenido
        <Emoji symbol="waving-hand-light-skin-tone" className="inline h-8" />
        <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-8" />
        !
      </h1>
      <br />
      {/* <p className="font-light">¡Los héroes tienen muchas capas! La tuya es estudiar ECONOMÍA</p> */}
      <p>Ten en cuenta esta información importante que tenemos para ti:</p>
      <div className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-3">
        <img
          src={background1}
          alt="background"
          className="hidden h-auto w-full sm:col-span-3 sm:flex"
        />
        <img src={background2} alt="background" className="h-auto w-full" />
        <img src={background3} alt="background" className="h-auto w-full" />
        <img src={background4} alt="background" className="h-auto w-full" />
      </div>
    </div>
  );
};

export default Home;
