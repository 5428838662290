import { API } from 'aws-amplify';
const post = (body) => API.post('estrella', '/creditos', { body });
const getLast = (usuarioId) =>
  API.get('estrella', '/creditos/ultimo', {
    queryStringParameters: { usuarioId },
  });

const updateValorFinanciar = (creditoNumero, valor) =>
  API.put('estrella', `/creditos/${creditoNumero}/valorfinanciar`, {
    body: { valorFinanciar: valor },
  });

const getValorFinanciar = (creditoNumero) =>
  API.get('estrella', `/creditos/${creditoNumero}/valorfinanciar`);
const get = (numero) => API.get('estrella', `/creditos/${numero}`, {});

const CreditoService = {
  post,
  updateValorFinanciar,
  getLast,
  get,
  getValorFinanciar,
};

export default CreditoService;
