import React from 'react';
import '../../styles/Spinner.css';

const Spinner = ({ className }) => {
  return (
    <svg
      className={
        'spinner fill-current text-custom-gray dark:text-custom-white' +
        ` ${className}`
      }
      viewBox="0 0 50 50"
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
};

export default Spinner;
