function changeFirstInUpperCase(value = '') {
  let n = value;
  const c = n.toLowerCase().split(' ');
  for (let i = 0; i < c.length; i++) {
    c[i] = c[i].charAt(0).toUpperCase() + c[i].slice(1).toLowerCase();
  }
  n = c.join(' ');
  return n;
}

function ChangeAllInLowerCase(value = '') {
  const nom = value.slice(0).toLowerCase();
  return nom;
}

const ChangeWords = {
  changeFirstInUpperCase,
  ChangeAllInLowerCase,
};

export default ChangeWords;
