import moment from 'moment';
import * as CompanyEmailValidator from 'company-email-validator';

const DEFAULT_MESSAGE = {
  required: 'Requerido',
  maxLength: 'Muy largo',
  minLength: 'Muy corto',
  invalid: 'Invalido',
  companyEmail: 'Correo institucional, no válido',
};

const PATTERN = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  celular: /\d{10}/,
  telefono: /\d{7}/,
};

const LOGIN = (password, document) => ({
  name: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 20, message: DEFAULT_MESSAGE.maxLength },
  },
  lastName: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 20, message: DEFAULT_MESSAGE.maxLength },
  },
  email: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: { value: PATTERN.email, message: DEFAULT_MESSAGE.invalid },
    maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
    validate: (value) =>
      !CompanyEmailValidator.isCompanyEmail(value) ||
      DEFAULT_MESSAGE.companyEmail,
  },
  document: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
  confirmDocument: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: (value) => value === document || 'no. documento no coincide',
  },
  phone: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: { value: PATTERN.celular, message: DEFAULT_MESSAGE.invalid },
  },
  password: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
    minLength: { value: 8, message: DEFAULT_MESSAGE.minLength },
  },
  confirmPassword: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
    minLength: { value: 8, message: DEFAULT_MESSAGE.minLength },
    validate: (value) => value === password || 'contraseña no coincide',
  },
  code: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
  },
  university: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
});

const REQUEST_FORM = {
  terminosCondiciones: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  tratamientoDatos: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  centralesRiesgo: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
};

const INFO_ESTUDIANTE = {
  nombres: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  apellidos: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  fecha_nacimiento: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    validate: {
      menorA70: (value) => {
        const fechaNacimiento = moment(value);
        const edad = moment().diff(fechaNacimiento, 'years');
        if (edad >= 70) {
          return false;
        }
        return true;
      },
    },
  },
  departamento_nacimiento: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  ciudad_nacimiento: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  tipo_documento: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  documento: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
  fecha_expedicion: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  departamento_expedicion: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  ciudad_expedicion: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  departamento_residencia: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  ciudad_residencia: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  direccion: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  estado_civil: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  celular: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.celular,
      message: DEFAULT_MESSAGE.invalid,
    },
  },
  telefono: {
    pattern: {
      value: PATTERN.telefono,
      message: DEFAULT_MESSAGE.invalid,
    },
  },
  correo_electronico: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.email,
      message: DEFAULT_MESSAGE.invalid,
    },
    maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
    validate: (value) =>
      !CompanyEmailValidator.isCompanyEmail(value) ||
      DEFAULT_MESSAGE.companyEmail,
  },
  trabaja: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  responsable_economico: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
};
const ACT_ECO_ESTUDIANTE = (numRef, watchRef, watchTipoRef) => {
  let result = {
    ocupacion: {
      required: { value: true, message: DEFAULT_MESSAGE.required },
    },
    ingresos: {
      required: { value: true, message: DEFAULT_MESSAGE.required },
      maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    },
    egresos: {
      required: { value: true, message: DEFAULT_MESSAGE.required },
      maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    },
    fuente_ingresos_alt: {
      maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    },
    ingresos_alt: {
      maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    },
  };
  for (let i = 0; i < numRef; i++) {
    const required =
      watchRef[i].find((element) => element) ||
      !watchTipoRef.includes(2) ||
      !watchTipoRef.includes(3);
    result = {
      ...result,
      ...{
        [`nombre_ref${i}`]: {
          required: { value: required, message: DEFAULT_MESSAGE.required },
          maxLength: { value: 100, message: DEFAULT_MESSAGE.maxLength },
        },
        [`tipo_ref${i}`]: {
          required: { value: required, message: DEFAULT_MESSAGE.required },
        },
        [`celular_ref${i}`]: {
          required: { value: required, message: DEFAULT_MESSAGE.required },
          pattern: {
            value: PATTERN.celular,
            message: DEFAULT_MESSAGE.invalid,
          },
        },
        [`correo_ref${i}`]: {
          required: { value: required, message: DEFAULT_MESSAGE.required },
          pattern: {
            value: PATTERN.email,
            message: DEFAULT_MESSAGE.invalid,
          },
          maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
        },
      },
    };
  }
  return result;
};

const PROGRAMA_FINANCIAR = {
  universidad: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  nivel: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  programa: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  jornada: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  haveEnrollmentStatusByUniversity: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  semestre_cursa: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  valor_semestre: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) => parseFloat(value) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
  tiene_financiacion: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  linea: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  valor_matricula: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) =>
        parseFloat(
          typeof value === 'string' ? value.replace(/[$.,' ']/g, '') : value
        ) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
  valor_financiar: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) => parseFloat(value) >= 580000 || DEFAULT_MESSAGE.invalid,
    },
  },
  plazo: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    validate: {
      min: (value) => parseInt(value) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
  solicita_impulso_financiero: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
};

const INFO_ACT_ECO_CODEUDOR = (
  number,
  disabled = false,
  documentos,
  correos,
  celulares
) => ({
  [`disabled_cod${number}`]: {},
  [`nombres_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  [`apellidos_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  [`fecha_nacimiento_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    validate: {
      menorA70: (value) => {
        const fechaNacimiento = moment(value);
        const edad = moment().diff(fechaNacimiento, 'years');
        if (edad >= 70) {
          return false;
        }
        return true;
      },
    },
  },
  [`departamento_nacimiento_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`ciudad_nacimiento_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`tipo_documento_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`documento_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: (value) =>
      disabled || !documentos.includes(value) || 'Previamente registrado',
  },
  [`fecha_expedicion_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`departamento_expedicion_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`ciudad_expedicion_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`departamento_residencia_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`ciudad_residencia_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`direccion_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  [`estado_civil_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`celular_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.celular,
      message: DEFAULT_MESSAGE.invalid,
    },
    validate: (value) =>
      disabled || !celulares.includes(value) || 'Previamente registrado',
  },
  [`telefono_cod${number}`]: {
    pattern: {
      value: PATTERN.telefono,
      message: DEFAULT_MESSAGE.invalid,
    },
  },
  [`correo_electronico_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.email,
      message: DEFAULT_MESSAGE.invalid,
    },
    maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      repetido: (value) =>
        disabled || !correos.includes(value) || 'Previamente registrado',
      institucional: (value) =>
        disabled ||
        !CompanyEmailValidator.isCompanyEmail(value) ||
        DEFAULT_MESSAGE.companyEmail,
    },
  },
  [`parentesco_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`ocupacion_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
  },
  [`ingresos_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
  [`egresos_cod${number}`]: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
  [`fuente_ingresos_alt_cod${number}`]: {
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
  [`ingresos_alt_cod${number}`]: {
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
  },
});

const SIMULATOR = {
  name: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 40, message: DEFAULT_MESSAGE.maxLength },
  },
  phone: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.celular,
      message: DEFAULT_MESSAGE.invalid,
    },
  },
  email: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    pattern: {
      value: PATTERN.email,
      message: DEFAULT_MESSAGE.invalid,
    },
    maxLength: { value: 50, message: DEFAULT_MESSAGE.maxLength },
  },
  period: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  hasFinancing: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  lineId: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  registrationAmount: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) =>
        parseFloat(
          typeof value === 'string' ? value.replace(/[$.,' ']/g, '') : value
        ) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
  amount: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) => parseFloat(value) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
  academicLevelId: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  universityId: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
  programCod: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
  },
};

const REQUEST_FORM_DOC = {
  valorFinanciar: {
    required: { value: true, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) => parseFloat(value) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
};

const PAYMENT = (disabled = false) => ({
  amount: {
    required: { value: !disabled, message: DEFAULT_MESSAGE.required },
    maxLength: { value: 15, message: DEFAULT_MESSAGE.maxLength },
    validate: {
      min: (value) =>
        disabled || parseFloat(value) > 0 || DEFAULT_MESSAGE.invalid,
    },
  },
});

export {
  LOGIN,
  REQUEST_FORM,
  INFO_ESTUDIANTE,
  ACT_ECO_ESTUDIANTE,
  PROGRAMA_FINANCIAR,
  INFO_ACT_ECO_CODEUDOR,
  SIMULATOR,
  REQUEST_FORM_DOC,
  PAYMENT,
};
