import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ContadorRegresivo({ tiempo, onClicBoton }) {
  const [segundosRestantes, setSegundosRestantes] = useState(tiempo);

  useEffect(() => {
    if (segundosRestantes > 0) {
      const temporizador = setTimeout(() => {
        setSegundosRestantes(segundosRestantes - 1);
      }, 1000);

      return () => {
        clearTimeout(temporizador);
      };
    } else {
      onClicBoton();
    }
  }, [segundosRestantes, onClicBoton]);

  return (
    <div>
      <b>
        <p>Regresará automático en: {segundosRestantes} segundos</p>
      </b>
    </div>
  );
}

const StepFirstMenssage = () => {
  const navigate = useNavigate();

  const tiempoEspera = 15;
  const [botonClicado, setBotonClicado] = useState(false);

  const manejarClicBoton = () => {
    const miBoton = document.getElementById('returnButton');
    if (miBoton) {
      miBoton.click();
    } else {
      navigate('/solicitar-financiacion');
    }
    setBotonClicado(true);
  };

  return (
    <>
      <div className=" text-center">
        <h2>
          <b>2 Consejos sobre tu orden de pago</b>
        </h2>

        <p className=" text-center">
          <br />
          - Revisa siempre la fecha de expedición y vencimientos. Después del
          tiempo máximo no aparecerá en el sistema.
          <br />
          <br />- Asegúrate que tus datos de registro en nuestra plataforma,
          coincidan con los que tienes en la Universidad, por ejemplo, ¿Tipo y
          número de documento son los mismos en ambas partes? De ser necesario
          actualiza la información correspondiente en tu Universidad, porque si
          no coincide, no aparecerá en el sistema.
        </p>
      </div>
      <div>
        {!botonClicado && (
          <ContadorRegresivo
            tiempo={tiempoEspera}
            onClicBoton={manejarClicBoton}
          />
        )}
      </div>
    </>
  );
};
export default StepFirstMenssage;
