import React, { useState, useEffect } from 'react';
import PairView from './PairView';
import Loading from '../shared/Loading';
import Button from '../DynamicForm/Button';
import { MdClose } from 'react-icons/md';
import { useDialog } from '../../context/dialog-context';
import PaymentModal from '../dialog/PaymentModal';
import FinancialStatementService from '../../services/process/financial-statement-service';
import { useAuth } from '../../context/auth-context';

const FinancialData = ({ req }) => {
  const [loading, setLoading] = useState(true);
  const dialog = useDialog();
  const dialogShowPlan = useDialog();
  const maxWidth = window.innerWidth;
  const { user } = useAuth();

  useEffect(() => {
    if (loading && req) setLoading(false);
  }, [req]);

  const showPayment = () =>
    dialog.custom(
      <>
        <div className="flex justify-end">
          <button onClick={() => dialog.close()}>
            <MdClose className="h-auto w-6" />
          </button>
        </div>
        <PaymentModal
          loanId={req.id ? req.id : req.paymentReference}
          fullAmount={req.amount_paid}
        />
      </>,
      { className: 'p-2', spacing: false }
    );
  const showPlan = async () => {
    const planPaymentsOpenCoop =
      await FinancialStatementService.planPaymentsOpenCoop(
        user['custom:document']
      );
    dialogShowPlan.custom(
      <div
        className="flex flex-col items-center shadow-lg rounded-lg overflow-hidden"
        style={{ width: '100%', maxWidth: '1200px' }}
      >
        <div className="text-center my-6">
          <h2 className="text-lg font-semibold">Plan de pagos inicial</h2>
          <div className="text-sm text-gray-600">
            Tu Plan de Pagos es una{' '}
            <strong style={{ fontWeight: 900, color: '#000' }}>
              estimación inicial
            </strong>
            ,
          </div>
          <div className="text-sm text-gray-600">
            generada{' '}
            <strong style={{ fontWeight: 900, color: '#000' }}>
              antes de que comiences a pagar tu financiación
            </strong>
            .
          </div>
          <div className="text-sm text-gray-600">
            <strong style={{ fontWeight: 900, color: 'red' }}>
              Estos valores podrían ser diferentes
            </strong>
          </div>
          <div className="text-sm text-gray-600">
            a tu Extracto Mensual y a tu Perfil Financiero.
          </div>
          <div className="text-sm text-gray-600 mt-4">
            <strong style={{ fontWeight: 900, color: '#000' }}>
              IMPORTANTE
            </strong>
          </div>
          <div className="text-sm text-gray-600">
            Tu Extracto Mensual y Perfil Financiero, son los espacios donde
          </div>
          <div className="text-sm text-gray-600">
            verás reflejado tu comportamiento de pago:
          </div>
          <ul className="text-sm text-gray-600 list-disc list-inside">
            <li>Valor que pagas cada mes</li>
            <li>Fecha en que pagas cada mes</li>
          </ul>
        </div>
        <div className="w-full overflow-x-auto py-4 sm:px-6 lg:px-8">
          <div className="shadow rounded-lg">
            <table className="min-w-full text-center text-sm font-light">
              <thead>
                <tr className="border-b dark:border-neutral-500">
                  <th className="px-4 py-2">Periodo</th>
                  <th className="px-4 py-2">Fecha de pago</th>
                  <th className="px-4 py-2">Cuota</th>
                  <th className="px-4 py-2">Seguro</th>
                  <th className="px-4 py-2">Abono capital</th>
                  <th className="px-4 py-2">Intereses</th>
                  <th className="px-4 py-2">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {planPaymentsOpenCoop.planPaymentData[0].planPayment.map(
                  (pago, index) => (
                    <tr
                      key={index}
                      className="border-b dark:border-neutral-500"
                    >
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.period}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.date
                          ? new Date(pago.date).toLocaleDateString('es', {
                              month: '2-digit',
                              year: 'numeric',
                            })
                          : ''}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {planPaymentsOpenCoop &&
                        planPaymentsOpenCoop.planPaymentData &&
                        planPaymentsOpenCoop.planPaymentData[0] &&
                        pago &&
                        pago.payment
                          ? Math.ceil(
                              parseFloat(
                                planPaymentsOpenCoop.planPaymentData[0].insuranceFee.replace(
                                  /,/g,
                                  ''
                                )
                              ) + parseFloat(pago.payment.replace(/,/g, ''))
                            ).toString()
                          : ''}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.period === 0
                          ? ''
                          : Math.ceil(
                              parseFloat(
                                planPaymentsOpenCoop.planPaymentData[0].insuranceFee.replace(
                                  /,/g,
                                  ''
                                )
                              )
                            ).toString()}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.monthly_payment
                          ? Math.ceil(
                              parseFloat(pago.monthly_payment.replace(/,/g, ''))
                            ).toString()
                          : ''}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.interest
                          ? Math.ceil(
                              parseFloat(pago.interest.replace(/,/g, ''))
                            ).toString()
                          : ''}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        {pago.balance
                          ? Math.ceil(
                              parseFloat(pago.balance.replace(/,/g, ''))
                            ).toString()
                          : ''}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <button
          onClick={() => {
            dialogShowPlan.close();
          }}
          className="mt-4 bg-custom-purple hover:bg-purple-700 text-white font-bold py-2 px-10 rounded text-lg"
        >
          Cerrar
        </button>
      </div>
    );
  };
  return loading ? (
    <Loading />
  ) : maxWidth <= 1280 ? (
    <>
      <>
        <div className="mb-4 grid grid-cols-1 gap-y-4 gap-x-24 xl:grid-cols-2">
          <>
            <PairView
              title="Valor financiado:"
              value={req.valueFinanced}
              tooltipText={'Valor financiado'}
            />
            <PairView
              title="Fecha de financiación:"
              value={req.startDate}
              tooltipText="Fecha en la que se realizó la financiación."
            />
            <PairView
              title="Cuota mensual:"
              value={req.totalFee}
              tooltipText="Valor de la cuota mensual de tu financiación."
            />
            <PairView
              title="(+) Saldo Capital Actual:"
              value={req.capitalBalance}
              tooltipText="Saldo de capital actualizado a la fecha de tu consulta."
            />
            <PairView
              title="(+) Interés Corriente:"
              value={req.currentContingencyInterest}
              tooltipText={
                'Saldo de intereses que se han causado desde tu última fecha de pago a hoy.'
              }
            />
            <PairView
              title={
                <span className="text-custom-red dark:text-custom-red">
                  Valor a pagar:
                </span>
              }
              value={req.amount_paid}
              tooltipText={'Valor TOTAL a pagar este mes.'}
            />
            <PairView
              title="(+) Interés Mora:"
              value={req.arrearsInterest}
              tooltipText={
                'Saldo de intereses de mora que se han causado porque tus pagos los has realizado de manera posterior a tu fecha de pago oportuno.'
              }
            />
            <PairView />
            <PairView
              title="(+) Cuota Seguro:"
              value={req.insuranceFee}
              tooltipText={
                'El valor mensual del seguro de vida deudores que aplica durante la vigencia de tu plan de pagos.'
              }
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Saldo total de la deuda:
                </span>
              }
              value={req.fullCancellationCredit}
              tooltipText="Saldo total que deberás pagar para cancelar la totalidad de tu financiación."
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Valor total en mora:
                </span>
              }
              value={req.totalValueArrears}
              tooltipText="Saldo que debes pagar para no estar en mora. No incluye el valor de la cuota actual."
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Cuota del mes:
                </span>
              }
              value={req.valuePay}
              tooltipText={'Valor a pagar este mes, de tu cuota mensual.'}
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Fecha máxima de pago:
                </span>
              }
              value={req.paymentMaximumDate}
              tooltipText={
                'Fecha límite para realizar tu pago de cuota mensual.'
              }
            />
          </>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="my-4 items-center">
            {
              <div className="flex flex-col space-y-1 text-center rounded-lg">
                <p className="font-bold">Plan de pagos inicial</p>
                <span>
                  <Button
                    onClick={showPlan}
                    customColor="bg-custom-green"
                    className="px-10 text-lg rounded-full"
                  >
                    Ver plan
                  </Button>
                </span>
              </div>
            }
          </div>
          <div className="my-4 items-center">
            <div className="flex flex-col space-y-1 text-center">
              <p className="font-bold">Para pagar por:</p>
              <p className="text-xs">
                Débito bancario PSE,
                <br /> Efectivo y Puntos Físicos
              </p>
              <span>
                <Button
                  onClick={showPayment}
                  customColor="bg-custom-purple"
                  className="px-10 text-lg"
                >
                  Realizar
                  <br /> Pago
                </Button>
              </span>
            </div>
          </div>
        </div>
      </>
    </>
  ) : (
    <>
      <>
        <div className="mb-4 grid grid-cols-1 gap-y-4 gap-x-24 xl:grid-cols-2">
          <>
            <PairView
              title="Valor financiado:"
              value={req.valueFinanced}
              tooltipText={'Valor financiado'}
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Saldo total de la deuda:
                </span>
              }
              value={req.fullCancellationCredit}
              tooltipText="Saldo total que deberás pagar para cancelar la totalidad de tu financiación."
            />
            <PairView
              title="Fecha de financiación:"
              value={req.startDate}
              tooltipText="Fecha en la que se realizó la financiación."
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Valor total en mora:
                </span>
              }
              value={req.totalValueArrears}
              tooltipText="Saldo que debes pagar para no estar en mora. No incluye el valor de la cuota actual."
            />
            <PairView
              title="Cuota mensual:"
              value={req.totalFee}
              tooltipText="Valor de la cuota mensual de tu financiación."
            />
            <PairView
              title={
                <span className="text-custom-green dark:text-custom-green">
                  Fecha máxima de pago:
                </span>
              }
              value={req.paymentMaximumDate}
              tooltipText={
                'Fecha límite para realizar tu pago de cuota mensual.'
              }
            />
            <PairView
              title="(+) Saldo Capital Actual:"
              value={req.capitalBalance}
              tooltipText="Saldo de capital actualizado a la fecha de tu consulta."
            />
            <PairView
              title={
                <span className="text-custom-red dark:text-custom-red">
                  Valor a pagar:
                </span>
              }
              value={req.amount_paid}
              tooltipText={'Valor TOTAL a pagar este mes.'}
            />
            <PairView
              title="(+) Interés Corriente:"
              value={req.currentContingencyInterest}
              tooltipText={
                'Saldo de intereses que se han causado desde tu última fecha de pago a hoy.'
              }
            />
            <PairView />
            <PairView
              title="(+) Interés Mora:"
              value={req.arrearsInterest}
              tooltipText={
                'Saldo de intereses de mora que se han causado porque tus pagos los has realizado de manera posterior a tu fecha de pago oportuno.'
              }
            />
            <PairView />
            <PairView
              title="(+) Cuota Seguro:"
              value={req.insuranceFee}
              tooltipText={
                'El valor mensual del seguro de vida deudores que aplica durante la vigencia de tu plan de pagos.'
              }
            />
          </>
        </div>
        <div className="grid grid-cols-2">
          <div className="my-4 items-center">
            <div className="flex items-center space-x-4 text-center rounded-lg">
              <p className="font-bold">Plan de pagos inicial</p>
              <span>
                <Button
                  onClick={showPlan}
                  customColor="bg-custom-green"
                  className="px-10 text-lg rounded-full"
                >
                  Ver plan
                </Button>
              </span>
            </div>
            <div className='mt-4'>
              <p className='text-justify'>
                En aquellas financiaciones que tienen un <q>Periodo de gracia</q>, los intereses corrientes comienzan a acumularse 
                desde el momento en que desembolsamos el dinero a la Universidad, y se suman y cobran al inicio de tus pagos, 
                es por eso que, al finalizar el periodo de gracia, tienes dos opciones:<br />
                1. Pagar todos los intereses acumulados junto con tu primera cuota.<br />
                2. Dividir el pago de los intereses acumulados en tres cuotas iguales, 
                extendiendo el plazo de tu financiación por 3 cuotas más.
              </p>
            </div>
          </div>
          <div className="my-4 items-center">
            <div className="flex flex-col space-y-1 text-center">
              <p className="font-bold">Para pagar por:</p>
              <p className="text-xs">
                Débito bancario PSE,
                <br /> Efectivo y Puntos Físicos
              </p>
              <span>
                <Button
                  onClick={showPayment}
                  customColor="bg-custom-purple"
                  className="px-10 text-lg"
                >
                  Realizar
                  <br /> Pago
                </Button>
              </span>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default FinancialData;
