const transformFormDataRenovation = ({ values, userId, fetchedConsultCreditRenovation, numRef }) => {
	const codeudores = [];
	for (let i = 0; i < fetchedConsultCreditRenovation.codeudores.length; i++) {
		codeudores.push({
			nombre: fetchedConsultCreditRenovation.codeudores[i].nombre,
			apellido: fetchedConsultCreditRenovation.codeudores[i].apellido,
			fechaNacimiento: fetchedConsultCreditRenovation.codeudores[i].fechaNacimiento,
			documento: fetchedConsultCreditRenovation.codeudores[i].documento,
			fechaExpedicion: fetchedConsultCreditRenovation.codeudores[i].fechaExpedicion,
			celular: fetchedConsultCreditRenovation.codeudores[i].celular,
			telefono: fetchedConsultCreditRenovation.codeudores[i].telefono,
			correoElectronico: fetchedConsultCreditRenovation.codeudores[i].correoElectronico,
			direccion: fetchedConsultCreditRenovation.codeudores[i].direccion,
			ciudadExpedicionCod: fetchedConsultCreditRenovation.codeudores[i].ciudadExpedicion.cod,
			estadoCivilId: fetchedConsultCreditRenovation.codeudores[i].estadoCivil.id,
			tipoDocumentoId: fetchedConsultCreditRenovation.codeudores[i].tipoDocumento.id,
			parentescoId: fetchedConsultCreditRenovation.codeudores[i].parentesco.id,
			ciudadResidenciaCod: fetchedConsultCreditRenovation.codeudores[i].ciudadResidencia.cod,
			...(String(fetchedConsultCreditRenovation.codeudores[i].tipoDocumento.id) !== '3' && {
				ciudadNacimientoCod: fetchedConsultCreditRenovation.codeudores[i].ciudadNacimiento.cod,
			}),
			actividadEconomica: {
				ingresos:fetchedConsultCreditRenovation.codeudores[i].actividadEconomica.ingresos,
				fuenteIngresosAlt:fetchedConsultCreditRenovation.codeudores[i].actividadEconomica.fuenteIngresosAlt,
				ingresosAlt:fetchedConsultCreditRenovation.codeudores[i].actividadEconomica.ingresosAlt,
				egresos:fetchedConsultCreditRenovation.codeudores[i].actividadEconomica.egresos,
				ocupacionId:fetchedConsultCreditRenovation.codeudores[i].actividadEconomica.ocupacion.id,
			},
		});
	}
	const referencias = [];
	for (let i = 0; i < numRef; i++) {
		const tipoReferenciaId = fetchedConsultCreditRenovation.referencias[i].tipoReferencium.id;
		if (!tipoReferenciaId || tipoReferenciaId === '') continue;
		referencias.push({
			nombre: fetchedConsultCreditRenovation.referencias[i].nombre,
			celular: fetchedConsultCreditRenovation.referencias[i].celular,
			correo: fetchedConsultCreditRenovation.referencias[i].correo,
			tipoReferenciaId,
		});
	}
	fetchedConsultCreditRenovation.periodoCursa = fetchedConsultCreditRenovation.periodoCursa+1;
	fetchedConsultCreditRenovation.trabaja = String(fetchedConsultCreditRenovation.estudiante.tipoDocumento.id) === '2' ? 'false' : String(fetchedConsultCreditRenovation.estudiante.tipoDocumento.id) === '1' && (fetchedConsultCreditRenovation.trabaja === 'false' || fetchedConsultCreditRenovation.trabaja === undefined)? 'false' : 'true';
	const data = {
		credito: {
			usuarioId: userId,
			...(String(fetchedConsultCreditRenovation.programa?.cod) !== 'otro' && {
				...(fetchedConsultCreditRenovation.periodoCursa && {
					continuaPrograma: fetchedConsultCreditRenovation.periodoCursa > 1,
					periodoCursa: fetchedConsultCreditRenovation.periodoCursa,
				}),
				programaCod: fetchedConsultCreditRenovation?.programa?.cod,
				jornadaId: fetchedConsultCreditRenovation?.jornada?.id || 1,
			}),
			plazo: values.plazo,
			nivelAcademicoId: fetchedConsultCreditRenovation.nivelAcademico.id,
			universidadId: fetchedConsultCreditRenovation.universidad.id,
			lineaId: fetchedConsultCreditRenovation.linea.id,
			taxCategory: values.tax_category,// validar si esta bien
			valorMatricula: values.valor_matricula,
			valorFinanciar: values.valor_financiar,
			renovacion: 'true',
			estudiante: {
				nombre: fetchedConsultCreditRenovation.estudiante.nombre,
				apellido: fetchedConsultCreditRenovation.estudiante.apellido,
				fechaNacimiento: fetchedConsultCreditRenovation.estudiante.fechaNacimiento,
				documento: fetchedConsultCreditRenovation.estudiante.documento,
				fechaExpedicion: fetchedConsultCreditRenovation.estudiante.fechaExpedicion,
				celular: fetchedConsultCreditRenovation.estudiante.celular,
				telefono: fetchedConsultCreditRenovation.estudiante.telefono,
				correoElectronico: fetchedConsultCreditRenovation.estudiante.correoElectronico,
				direccion: fetchedConsultCreditRenovation.estudiante.direccion,
				estadoCivilId: fetchedConsultCreditRenovation.estudiante.estadoCivil.id,
				tipoDocumentoId: fetchedConsultCreditRenovation.estudiante.tipoDocumento.id,
				ciudadExpedicionCod: fetchedConsultCreditRenovation.estudiante.ciudadExpedicion.cod,
				ciudadResidenciaCod: fetchedConsultCreditRenovation.estudiante.ciudadResidencia.cod,
				...(String(fetchedConsultCreditRenovation.estudiante.tipoDocumento.id) !== '3' && {
					ciudadNacimientoCod: fetchedConsultCreditRenovation.estudiante.ciudadNacimiento.cod,
				}),
				...(String(fetchedConsultCreditRenovation.trabaja) === 'true'
					? {
							actividadEconomica: {
								ingresos: fetchedConsultCreditRenovation?.estudiante?.actividadEconomica?.ingresos,
								fuenteIngresosAlt: fetchedConsultCreditRenovation?.estudiante?.actividadEconomica?.fuenteIngresosAlt,
								ingresosAlt: fetchedConsultCreditRenovation?.estudiante?.actividadEconomica?.ingresosAlt,
								egresos: fetchedConsultCreditRenovation?.estudiante?.actividadEconomica?.egresos,
								ocupacionId: fetchedConsultCreditRenovation?.estudiante?.actividadEconomica?.ocupacion?.id,
							},
					  }
					: {
							responsableEconomicoId: fetchedConsultCreditRenovation?.estudiante?.responsableEconomico?.id,
					  }),
			},
			codeudores,
			referencias,
		},
	};
	// console.log(JSON.stringify(data, null, 4))
	return data;
};

export default transformFormDataRenovation;
