import React from 'react';
import Tuple from '../FinancialStatement/Tuple';
import { formatCurrency } from '../../utils/format';
import { ReactComponent as InfoIco } from '../../assets/images/info.svg';
import { INFO_TITLE } from '../../constants/shared/infoTitle';

const SimuladorInformationApi = ({ infoOrdenMatricula }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className=" grid text-right grid-cols-6">
        <p className="font-bold text-center text-red-500 col-span-6 m-1">
          Esta es la información de tu orden de matrícula:
        </p>
        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-2">N° Orden:</span>
          </b>
        </div>
        <div className=" m-1 col-span-3">
          <Tuple value={infoOrdenMatricula?.nOrdenMatricula} />
        </div>
        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-3">
              Fecha prevista de pago:
            </span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <Tuple value={infoOrdenMatricula?.fecha} />
        </div>
        <div title={INFO_TITLE.FECHA_PAGO} className=" h-5 w-5 content-center">
          <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
        </div>

        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-2">
              Matrícula:
            </span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <Tuple
            value={formatCurrency(parseInt(infoOrdenMatricula?.matricula))}
          />
        </div>

        <div className=" m-1 col-span-3">
          <b>
            <span className="dark:text-custom-green col-span-2">Seguro:</span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <Tuple value={formatCurrency(infoOrdenMatricula?.seguro)} />
        </div>

        <div className=" m-1 col-span-3">
          <b>
            <span className="text-[#3F2AAD] dark:text-custom-green col-span-2 ">
              Valor a pagar:
            </span>
          </b>
        </div>
        <div className=" m-1 col-span-2">
          <Tuple
            value={formatCurrency(parseInt(infoOrdenMatricula?.valorPago))}
          />
        </div>
      </div>
    </div>
  );
};

export default SimuladorInformationApi;
