import React from 'react';
import { ReactComponent as FisrtIcon } from '../../assets/images/form/first-icon.svg';
import { ReactComponent as ThirdIcon } from '../../assets/images/form/third-icon.svg';
import { ReactComponent as SecondIcon } from '../../assets/images/form/second-icon.svg';
import ProgressBar from '../shared/ProgressBar';

function StepNumber(number, active = false) {
  return (
    <div
      className={`step relative z-10 mx-auto my-1 flex h-9 w-9 items-center justify-center rounded-full bg-light bg-opacity-100 font-nunito text-lg font-bold transition duration-500 dark:bg-dark ${
        active
          ? 'text-custom-purple shadow-light-active dark:text-custom-green dark:shadow-dark-active'
          : 'shadow-primary text-custom-light-gray shadow-light dark:shadow-dark'
      }`}
    >
      {number}
    </div>
  );
}

function StepIcon(FormIcon, active = false) {
  return (
    <div
      className={`relative mx-auto ${
        active
          ? 'text-custom-purple dark:text-custom-green'
          : 'text-custom-light-gray'
      }`}
    >
      <FormIcon className="h-14 w-14 fill-current transition duration-500" />
      <FormIcon
        className={`absolute top-0 left-0 h-14 w-14 fill-current ${
          active ? 'animate-ping-once' : 'invisible'
        }`}
      />
    </div>
  );
}

function StepName(text, active = false) {
  return (
    <span
      className={`relative mx-auto ${
        active ? 'font-bold text-red-500' : 'text-custom-light-gray'
      }`}
    >
      {text}
    </span>
  );
}

function LegalizationProgressBar({ progress = 0, className }) {
  return (
    <div className={className}>
      <div className="grid grid-cols-3 ">
        {StepIcon(FisrtIcon, progress >= 0)}
        {StepIcon(SecondIcon, progress >= 0.5)}
        {StepIcon(ThirdIcon, progress >= 1)}
      </div>
      <div className="relative">
        <div className="grid grid-cols-3">
          {StepNumber(1, progress >= 0)}
          {StepNumber(2, progress >= 0.5)}
          {StepNumber(3, progress >= 1)}
        </div>
        <div className="absolute top-1/2 left-1/6 w-2/3">
          <ProgressBar progress={progress}></ProgressBar>
        </div>
      </div>
      <div className="grid grid-cols-3 text-center text-xs md:text-sm">
        {StepName('Contrato de financiacion', progress >= 0)}
        {StepName('Formato Seguro de Vida', progress >= 0.5)}
        {StepName('Deceval', progress >= 1)}
      </div>
    </div>
  );
}

export default LegalizationProgressBar;
