import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../assets/images/nav/home.svg';
import { ReactComponent as GraduatedIcon } from '../../assets/images/nav/graduated.svg';
import { ReactComponent as HandshakeIcon } from '../../assets/images/nav/handshake.svg';
import { ReactComponent as UserIcon } from '../../assets/images/nav/user.svg';
import { BsArrowBarLeft } from 'react-icons/bs';
import { HiMenu } from 'react-icons/hi';
import { useAuth } from '../../context/auth-context';
import { useDialog } from '../../context/dialog-context';
import { DIALOG } from '../../constants/dialog/dialog';
import { MdClose } from 'react-icons/md';
import { useNavbar } from '../../context/navbar-context';

const Navbar = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { user, signOut } = useAuth();
  const { sidebar, toggleSidebar } = useNavbar();

  const onSignOut = () =>
    signOut().catch((error) => {
      console.error(error);
      if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
      else
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
    });

  const TabLink = ({ to, title, icon: Icon, ...otherProps }) => (
    <NavLink
      to={to}
      title={title}
      className={({ isActive }) =>
        'flex h-full w-full flex-col items-center justify-center space-x-0 space-y-2 whitespace-normal p-1 text-center transition duration-500 lg:flex-row lg:justify-start lg:space-x-6 lg:space-y-0 lg:whitespace-nowrap lg:rounded-l-full lg:py-2 lg:pl-8' +
        (isActive ? ' bg-light text-custom-green dark:bg-dark' : '')
      }
      {...otherProps}
    >
      <Icon className="h-auto w-10 flex-shrink-0 fill-current" />
      <span className="text-xs lg:text-base">{title}</span>
    </NavLink>
  );

  const Profile = ({ className }) => (
    <div
      className={
        'flex-col items-center justify-center space-y-1 p-4' +
        (className ? ` ${className}` : ' flex')
      }
    >
      <UserIcon className="h-auto w-40 flex-shrink-0 fill-current" />
      <span className="text-2xl">{user.given_name}</span>
      <div className="justify-center w-auto">
        <span className="text-justify">{user.email}</span>
      </div>
      <span className=" text-xl">{user['custom:document']}</span>
      <span className="text-xs hover:underline" onClick={() => navigate('#')}>
        ver perfil
      </span>
    </div>
  );

  const tabs = [
    { to: 'inicio', title: 'Inicio', icon: HomeIcon },

    { to: 'mi-financiacion', title: 'Mi financiación', icon: GraduatedIcon },

    {
      to: 'solicitar-financiacion',
      title: 'Solicitar financiación',
      icon: HandshakeIcon,
    },
  ];

  const showProfile = () =>
    dialog.custom(
      <>
        <div className="flex justify-end">
          <button onClick={() => dialog.close()}>
            <MdClose className="h-auto w-6" />
          </button>
        </div>
        <Profile />
        <div className="flex justify-center">
          <button
            className="hover:underline"
            onClick={() => onSignOut().then(() => dialog.close())}
          >
            Cerrar sesión
          </button>
        </div>
      </>,
      { className: 'p-4', spacing: false }
    );

  return (
    <>
      <button className="absolute top-4 left-4 hidden lg:flex">
        <HiMenu className="h-auto w-8 fill-current " onClick={toggleSidebar} />
      </button>
      <nav
        className={
          'z-10 flex shrink-0 flex-col overflow-hidden whitespace-nowrap bg-custom-green text-dark transition-all duration-700' +
          (sidebar ? ' lg:w-72' : ' lg:w-0')
        }
      >
        <button className="mt-4 mr-4 hidden justify-end lg:flex">
          <BsArrowBarLeft
            className="h-auto w-8 flex-shrink-0 fill-current "
            onClick={toggleSidebar}
          />
        </button>
        <Profile className="hidden lg:flex" />
        <ul className="grid flex-1 auto-cols-fr grid-flow-col lg:flex lg:flex-col lg:space-x-0 lg:space-y-10">
          {tabs.map((tab) => (
            <li key={tab.to}>
              <TabLink to={tab.to} title={tab.title} icon={tab.icon} />
            </li>
          ))}
          <li className="flex lg:hidden" onClick={showProfile}>
            <TabLink to="#" title="Perfil / Cerrar sesión" icon={UserIcon} />
          </li>
        </ul>
        <div className="m-4 hidden justify-center lg:flex">
          <button className="hover:underline" onClick={onSignOut}>
            Cerrar sesión
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
