import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Layout/Header';

const Layout = () => {
  return (
    <div className="flex h-screen flex-col overflow-y-auto bg-light px-4 font-montserrat text-sm text-light transition duration-500 dark:bg-dark dark:text-dark lg:px-32 lg:text-base">
      <Header />
      <main className="flex flex-1 flex-col">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
