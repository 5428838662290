import React, { useState, useEffect } from 'react';
import Item from './Item';

const Items = ({ items, cols, onChange }) => {
  const [state, setState] = useState(
    items?.reduce(
      (current, item) =>
        item?.title
          ? {
              ...current,
              [item.title]: undefined,
            }
          : current,
      {}
    )
  );

  const getOnChange = (title) => (value) =>
    setState((current) => ({ ...current, [title]: value }));

  useEffect(() => {
    const required = Object.keys(state).find(
      (key) => state[key] === undefined || state[key] === ''
    );
    onChange(required ? undefined : state);
  }, [state]);

  const gridCols = {
    1: 'xl:grid-cols-1 lg:grid-cols-1 grid-cols-1',
    2: 'xl:grid-cols-2 lg:grid-cols-1 grid-cols-1',
    3: 'xl:grid-cols-3 lg:grid-cols-2 grid-cols-1',
    4: 'xl:grid-cols-4 lg:grid-cols-2 grid-cols-1',
  };

  return (
    <div className={`grid gap-x-12 gap-y-4 ${gridCols[cols]}`}>
      {items?.map((field, fieldIndex) => (
        <div key={fieldIndex}>
          <Item
            {...field}
            onChange={getOnChange(field.title)}
            value={state[field.title]}
          />
        </div>
      ))}
    </div>
  );
};

export default Items;
