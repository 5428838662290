import React from 'react';

const emojis = {
  'waving-hand': '👋',
  warning: '⚠️',
  'confused-face': '😕',
  'smiling-face-with-smiling-eyes': '😊',
  sparkles: '✨',
  star: '⭐',
  'vulcan-salute': '🖖',
  'crossed-fingers': '🤞',
  stopwatch: '⏱',
  'exclamation-mark': '❗',
  'video-camera': '📹',
  'waving-hand-light-skin-tone': '👋🏻',
  link: '🔗',
  'hourglass-done': '⌛',
  'cross-mark': '❌',
  'mobile-phone': '📱',
  'check-mark-button': '✅',
  'party-popper': '🎉',
  'white-exclamation-mark': '❕',
  eyes: '👀',
  'flushed-face': '😳',
};

const Emoji = (props) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
  >
    {emojis[props.symbol]}
  </span>
);
export default Emoji;
