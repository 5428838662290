import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import { SIMULATOR } from '../constants/form/validations';
import { DIALOG } from '../constants/dialog/dialog';
import { useDialog } from '../context/dialog-context';
import ConstantesSolicitudService from '../services/shared/constantes-solicitud-service';
import FormInput from '../components/Input/FormInput';
import useInput from '../hooks/use-input';
import Loading from '../components/shared/Loading';
import Button from '../components/DynamicForm/Button';
import { useAuth } from '../context/auth-context';
import useTermSlider from '../hooks/use-term-slider';
import Emoji from '../components/shared/Emoji';
import DisplayValue from '../components/shared/DisplayValue';
import { formatCurrency, formatCurrencyES } from '../utils/format';
import useVideo from '../hooks/use-video';
import { BINARIO } from '../constants/form/request-form';
import { Link } from 'react-router-dom';

const Simulator = ({ universityId }) => {
  const [periodos, setPeriodos] = useState();
  const [tipoPeriodo, setTipoPeriodo] = useState();
  const dialog = useDialog();
  const [formConstants, setFormConstants] = useState(
    Cache.getItem('guest-form-constants')
  );
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { showVideo } = useVideo();

  const { guest } = ConstantesSolicitudService;

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: user ? user.given_name + ' ' + user.family_name : undefined,
      phone: user?.phone_number.replace(/^(\+57)/, ''),
      email: user?.email,
      universityId,
      term: 7,
    },
  });

  const watchUniversidad = watch('universityId');
  const watchPrograma = watch('programCod');
  const watchNivel = watch('academicLevelId');
  const watchValorMatricula = watch('registrationAmount');
  const watchLinea = watch('lineId');
  const watchTieneFinanciacion = watch('hasFinancing');
  const isOtroPrograma = watchPrograma?.toString() === 'otro';
  const showPeriodos = tipoPeriodo?.toString() !== 'Hora';
  const programas = Cache.getItem(`PROGRAMS_${watchUniversidad}_${watchNivel}`);
  const nameSimulator =
    watchUniversidad?.toString() === '4' ? 'Tech' : 'Estrella';
  const [utbUniversity, SetUtbUniversity] = useState(false);
  const [valueProduct, setValueProduct] = useState();
  const [inputValueProduct] = useState('');

  const { TermSlider, create } = useTermSlider();

  const fetchConstantes = () =>
    guest
      .get()
      .then((response) => {
        setFormConstants(response);
        Cache.setItem('guest-form-constants', response);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    !formConstants && fetchConstantes();
  }, []);

  const { InputProps, setLoading: setInputLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: SIMULATOR,
  });

  const fetchProgramas = async () => {
    if (watchUniversidad && watchNivel && programas === null) {
      setInputLoading('programCod', true);
      await guest
        .getProgramasByNivelAcademico(watchUniversidad, watchNivel)
        .then((response) =>
          Cache.setItem(
            `PROGRAMS_${watchUniversidad}_${watchNivel}`,
            response?.concat([
              {
                value: 'otro',
                label: 'Otro',
              },
            ])
          )
        )
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
      setInputLoading('programCod', false);
      setValue('programCod', getValues('programCod'));
    }
  };

  useEffect(() => {
    fetchProgramas();
  }, [watchUniversidad, watchNivel]);

  const fetchPeriodos = async () => {
    setPeriodos();
    setTipoPeriodo();
    if (watchPrograma && !isOtroPrograma) {
      setInputLoading('period', true);
      await guest
        .getPrograma(watchPrograma)
        .then((response) => {
          setTipoPeriodo(response.tipoPeriodo);
          setValueProduct(response.valorSemestre);
          const periodos = [];
          for (let i = 0; i < response.numeroPeriodos; i++)
            periodos.push({
              value: i + 1,
              label: i + 1,
            });
          setPeriodos(periodos);
        })
        .catch((error) => {
          if (
            error.response.data.message !== 'crédito not found' &&
            watchPrograma !== '0'
          ) {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }
        });
    }
  };

  useEffect(() => {
    fetchPeriodos();
  }, [watchPrograma]);

  useEffect(() => {
    if (periodos) {
      setValue('period', getValues('period'));
      setInputLoading('period', false);
    }
  }, [periodos]);

  useEffect(() => {
    if (loading && formConstants) setLoading(false);
  }, [formConstants]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await create({
      ...data,
      ...(data.programCod === 'otro'
        ? { programCod: undefined, period: undefined }
        : {}),
    });
  };

  // TODO: Remove this when the API is fixed
  const LINEA = useMemo(
    () => [
      watchUniversidad?.toString() === '4'
        ? { value: '6', label: 'Línea Tech' }
        : { value: '1', label: 'Línea Estrella' },
      ...(watchUniversidad?.toString() === '2' && watchNivel?.toString() === '4'
        ? [{ value: '2', label: 'Línea PRO' }]
        : []),
      ...(watchUniversidad?.toString() === '1' && watchNivel?.toString() === '4'
        ? [
            { value: '7', label: 'Línea Pacífico' },
            { value: '8', label: 'Línea Sultana' }
          ]
        : watchUniversidad?.toString() === '1'
        ? [{ value: '8', label: 'Línea Sultana' }]
        : []),
      ...(watchUniversidad?.toString() === '3' && watchNivel?.toString() === '4'
        ? [
            { value: '4', label: 'Línea Caribe' },
            { value: '5', label: 'Línea Heroica' },
          ]
        : watchUniversidad?.toString() === '3'
        ? [{ value: '4', label: 'Línea Caribe' }]
        : []),
    ],
    [watchUniversidad, watchNivel, watchTieneFinanciacion, watchPrograma]
  );

  const taxCategory = useMemo(() => {
    if( watchUniversidad?.toString() === '2' && tipoPeriodo === 'Trimestre' && watchNivel?.toString() !== '4') {
      return 'estrella_trimestre';
    }

    if (watchLinea === '2') {
      if (tipoPeriodo === 'Año') return 'pro_annual';
      return 'pro';
    } else if (watchLinea === '3') {
      return 'zero';
    } else if (watchLinea === '4') {
      return 'caribe';
    } else if (watchLinea === '5') {
      return 'heroica';
    } else if (watchLinea === '6') {
      return 'tech';
    } else if (watchLinea === '7') {
      return 'pacifico';
    } else if (watchLinea === '8') {
      return 'sultana';
    } else if (watchLinea === '9') {
      return 'andina';
    } else if (tipoPeriodo === 'Año') {
      return 'annual';
    } else return 'estrella';
  }, [watchLinea, tipoPeriodo]);

  useEffect(() => {
    setValue('taxCategory', taxCategory);
  }, [taxCategory]);

  const handleProgramaChange = () => {
    const inputElement = document.getElementById('registrationAmount');

    if (inputElement && typeof valueProduct === 'number') {
      const formattedValue = formatCurrencyES(valueProduct);
      // Utiliza setValue para actualizar el valor del campo 'valor_matricula'
      setValue('registrationAmount', formattedValue);
    }
  };
  useEffect(() => {
    if (Number(watchUniversidad) === 4) {
      SetUtbUniversity(true);
      handleProgramaChange();
    }
  }, [watchPrograma, valueProduct]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <section>
        <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
          Simulador {nameSimulator}
        </h2>
        <h4 className="font-bold">
          Cuéntanos cuál es tu sueño y elige la forma de hacerlo realidad
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 grid grid-cols-2 gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
            <FormInput
              {...InputProps('name')}
              placeholder="Nombre completo"
              type="text"
            />
            <FormInput
              {...InputProps('phone')}
              placeholder="Número celular"
              type="cell-phone"
            />
            <FormInput
              {...InputProps('email')}
              placeholder="Correo electrónico"
              type="email"
            />
            {!universityId && (
              <FormInput
                {...InputProps('universityId')}
                placeholder="Universidad"
                type="select"
                options={formConstants.UNIVERSITY.filter(item => item.value !== 0 && item.value !== 4)}
              />
            )}
            <FormInput
              {...InputProps('academicLevelId')}
              placeholder="Nivel académico"
              type="select"
              options={formConstants.ACADEMIC_LEVEL}
            />
            <FormInput
              {...InputProps('programCod')}
              placeholder="Programa"
              type="select"
              options={programas}
            />
            {!utbUniversity && (
              <>
                {!isOtroPrograma && showPeriodos && (
                  <FormInput
                    {...InputProps('period')}
                    placeholder={(tipoPeriodo || 'Periodo') + ' a cursar'}
                    type="select"
                    options={periodos}
                  />
                )}
              </>
            )}
          </div>

          <h4 className="font-bold">Información de financiación</h4>
          <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
            {watchUniversidad?.toString() === '1' && (
              <FormInput
                {...InputProps('hasFinancing')}
                placeholder="¿Ya tienes una financiación con Estrella?"
                type="select"
                options={BINARIO}
              />
            )}
            <FormInput
              {...InputProps('lineId')}
              placeholder="Línea de financiación"
              type="select"
              options={LINEA}
              className="col-start-1"
            />

            <p className="lg:col-span-2">
              {watchLinea?.toString() === '1' && (
                <>
                  Características de la Línea ESTRELLA -{' '}
                  <button
                    className="hover:underline"
                    onClick={() => showVideo('7WYOhHXfEAU')}
                  >
                    Ver video:{' '}
                    <Emoji symbol="video-camera" className="inline h-4" />
                  </button>
                </>
              )}
              {watchLinea?.toString() === '2' && (
                <>
                  Características de la Línea PRO -{' '}
                  <button
                    className="hover:underline"
                    onClick={() => showVideo('aXI3KDToKfo')}
                  >
                    Ver video:{' '}
                    <Emoji symbol="video-camera" className="inline h-4" />
                  </button>
                </>
              )}
            </p>

            <p className="col-start-1 font-bold">
              ¿Cuál es el valor de tu matrícula?:
            </p>
            {/* <FormInput
							{...InputProps('registrationAmount')}
							placeholder='Valor de tu matrícula'
							type='currency'
						/> */}
            <FormInput
              {...InputProps('registrationAmount')}
              placeholder={utbUniversity ? '' : 'Valor de tu matrícula'}
              type={utbUniversity ? 'text' : 'currency'}
              id="registrationAmount"
              value={inputValueProduct}
              disabled={utbUniversity}
            />
            {watchLinea?.toString() === '2' && (
              <>
                <p className="col-start-1 font-bold text-custom-purple dark:text-custom-green">
                  Este es el 35% condonable:
                </p>
                <DisplayValue
                  title="35% condonable"
                  value={formatCurrency(watchValorMatricula * 0.35)}
                />
                <p>
                  *El beneficio contempla condonar el 35% del valor de la
                  matrícula, (si cumples requisitos académicos).
                </p>

                <p className="col-start-1 font-bold text-custom-purple dark:text-custom-green">
                  Este el 65% financiable:
                </p>
                <DisplayValue
                  title="65% financiable"
                  value={formatCurrency(watchValorMatricula * 0.65)}
                />
                <p>*Puedes financiar este valor total o un monto parcial.</p>
              </>
            )}
            <p className="col-start-1 font-bold">
              ¿Cuál es el valor que necesitas financiar?:
            </p>
            <FormInput
              {...InputProps('amount')}
              placeholder="Valor a financiar"
              type="currency"
            />
            {watchLinea?.toString() === '2' ? (
              <p>
                *Aquí debes escribir, del <b>65% financiable</b>, cuánto $
                necesitas que te financiemos.
              </p>
            ) : (
              <p>
                *Recuerda tener en cuenta <b>todo</b> lo que dice tu{' '}
                <b>Orden de Matrícula</b>, fechas, descuentos, becas,
                beneficios, costos adicionales o cualquier otro rubro para el
                pago de tus estudios.
              </p>
            )}
            <p className="col-start-1 font-bold">
              ¡Llegó el momento de calcular el tiempo y cuota de tu
              financiación!
            </p>
            <div className="flex justify-center lg:justify-start">
              <Button
                type="submit"
                className="w-auto lg:w-full"
                loading={isSubmitting}
              >
                Simular
              </Button>
            </div>
          </div>
        </form>

        <div className="mt-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-4 lg:gap-6">
          <p className="col-span-1">
            <b>Selecciona el plazo</b> en la barra y{' '}
            <b>visualiza el valor aproximado</b> de tu cuota mensual:
          </p>
          <TermSlider
            {...InputProps('term')}
            classNameBar="lg:col-span-2 col-span-1"
          />
        </div>

        <div className="my-4 text-right">
          <Link to="/solicitar-financiacion">
            <Button>¡Solicitar financiación ahora!</Button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Simulator;
