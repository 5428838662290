import React, { createContext, useState, useContext } from 'react';

const NavbarContext = createContext({
  sidebar: true,
  setSidebar: () => {},
});

export function NavbarProvider({ children }) {
  const [sidebar, setSidebar] = useState(true);
  const value = { sidebar, setSidebar };
  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  );
}

export function useNavbar() {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error('useNavbar must be used within NavbarProvider');
  }
  const { sidebar, setSidebar } = context;
  return {
    sidebar,
    toggleSidebar: () => setSidebar((current) => !current),
    closeSidebar: () => setSidebar(false),
    openSidebar: () => setSidebar(true),
  };
}
