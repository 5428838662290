export const INFO_TITLE = {
  FECHA_PAGO:
    'Esta es la fecha que te aplica y con la que se llevará acabo tu estudio. Recuerda que tienes máximo 2 días posteriores al resultado del estudio para legalizar 100% tu financiación. Posteriormente Podrían cambiar las condiciones.',
  INCLUIR_SEGURO:
    'Incluir el valor de tu seguro universitario en la financiación, es opcional, si no lo incluyes, podrás pagarlo directamente a la Universidad.',
  VALOR_BASE:
    'Este es el valor a pagar en tu orden de matrícula, CON o SIN seguro, dependiendo tu elección anterior.',
  CONDONABLE:
    'El beneficio contempla condonar el 35% del valor de la matrícula, sin seguro. (Si cumples requisitos académicos).',
  FINANCIABLE: 'Puedes financiar este valor total o un monto parcial.',
  SOLICITAR:
    'Recuerda que lo máximo que puedes solicitar es el 65% financiable y lo mínimo es 1/2 salario MLV',
};
