import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import ConstantesSolicitudService from '../../services/shared/constantes-solicitud-service';
import FormInput from '../Input/FormInput';
import FileDrop from '../File/FileDrop';
import DisplayValue from '../shared/DisplayValue';
import { formatCurrency, formatCurrencyES } from '../../utils/format';
import Button from '../DynamicForm/Button';
import { useDialog } from '../../context/dialog-context';
import useTermSlider from '../../hooks/use-term-slider';
import useInput from '../../hooks/use-input';
import { DIALOG } from '../../constants/dialog/dialog';
import { PROGRAMA_FINANCIAR } from '../../constants/form/validations';
import { FILES } from '../../constants/files/files';
import { FILE_STATE } from '../../constants/files/fileState';

const SimuladorSinApi = ({ uploadProps, fetchedConsultCreditRenovation = null }) => {
  const isMounted = useRef(false);
  const [jornadas, setJornadas] = useState();
  const [periodos, setPeriodos] = useState();
  const [tipoPeriodo, setTipoPeriodo] = useState();
  const [valueProduct, setValueProduct] = useState();
  const [inputValueProduct] = useState('');
  const [utbUniversity, SetUtbUniversity] = useState(false);
  const dialog = useDialog();
  const [btnActivo, SetBtnActivo] = useState(false);
  const [searchInactive, setSearchInactive] = useState(false);

  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const watchUniversidad =  fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.universidad.id) : watch('universidad');
  const watchPrograma =  fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.programa.cod) : watch('programa');
  const watchNivel =  fetchedConsultCreditRenovation ? fetchedConsultCreditRenovation.nivelAcademico.id : watch('nivel');
  const watchValorMatricula = watch('valor_matricula');
  let watchValorFinanciar = watch('valor_financiar');
  const watchLinea =  fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.linea.id) : watch('linea');
  const isOtroPrograma = watchPrograma?.toString() === 'otro';
  const programas = Cache.getItem(
    `PROGRAMAS_${watchUniversidad}_${watchNivel}`
  );
  const { TermSlider, calculate } = useTermSlider(fetchedConsultCreditRenovation);
  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: PROGRAMA_FINANCIAR,
  });

  const fetchProgramas = async () => {
    if (watchUniversidad && watchNivel && programas === null) {
      setLoading('programa', true);
      await ConstantesSolicitudService.getProgramasByNivelAcademico(
        watchUniversidad,
        watchNivel
      )
        .then((response) =>
          Cache.setItem(
            `PROGRAMAS_${watchUniversidad}_${watchNivel}`,
            response?.concat([
              {
                value: 'otro',
                label: 'Otro',
              },
            ])
          )
        )
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
      setLoading('programa', false);
      setValue('programa', getValues('programa'));
    }
  };

  useEffect(() => {
    fetchProgramas();
  }, [watchUniversidad, watchNivel]);

  const fetchPeriodoAndJornada = async () => {
    setPeriodos();
    setJornadas();
    setTipoPeriodo();
    if (watchPrograma && !isOtroPrograma) {
      setLoading('periodo_cursa', true);
      setLoading('jornada', true);
      await Promise.all([
        ConstantesSolicitudService.getPrograma(watchPrograma)
          .then((response) => {
            setTipoPeriodo(response.tipoPeriodo);
            setValueProduct(response.valorSemestre);
            const periodos = [];
            for (let i = 0; i < response.numeroPeriodos; i++)
              periodos.push({
                value: i + 1,
                label: i + 1,
              });
            setPeriodos(periodos);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
        ConstantesSolicitudService.getJornadasByPrograma(watchPrograma)
          .then((response) => {
            setJornadas(response);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
      ]);
    }
  };

  useEffect(() => {
    fetchPeriodoAndJornada();
  }, [watchPrograma]);

  useEffect(() => {
    if (periodos) {
      setValue('periodo_cursa', getValues('periodo_cursa'));
      setLoading('periodo_cursa', false);
    }
  }, [periodos]);

  useEffect(() => {
    if (jornadas) {
      setValue('jornada', getValues('jornada'));
      setLoading('jornada', false);
    }
  }, [jornadas]);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const taxCategory = useMemo(() => {
    if( watchUniversidad?.toString() === '2' && tipoPeriodo === 'Trimestre' && watchNivel?.toString() !== '4') {
      return 'estrella_trimestre';
    }

    if (watchLinea === '2') {
      if (tipoPeriodo === 'Año') return 'pro_annual';
      return 'pro';
    } else if (watchLinea === '3') {
      return 'zero';
    } else if (watchLinea === '4') {
      return 'caribe';
    } else if (watchLinea === '5') {
      return 'heroica';
    } else if (watchLinea === '6') {
      return 'tech';
    } else if (watchLinea === '7') {
      return 'pacifico';
    } else if (watchLinea === '8') {
      return 'sultana';
    } else if (watchLinea === '9') {
      return 'andina';
    } else if (tipoPeriodo === 'Año') {
      return 'annual';
    } else return 'estrella';
  }, [watchLinea, tipoPeriodo]);

  useEffect(() => {
    setValue('tax_category', taxCategory);
  }, [taxCategory]);

  const handleCalculatevalue = (valueFinance) => {
    let valueWatchValorMatricula = '';
    if (typeof watchValorMatricula !== 'undefined') {
      valueWatchValorMatricula = watchValorMatricula;
    } else {
      valueWatchValorMatricula =
        document.getElementById('valor_matricula').value;
    }

    let actualValorFinanciar =
      watchLinea?.toString() === '2'
        ? formatCurrency(valueWatchValorMatricula * 0.65)
        : formatCurrency(
            typeof valueWatchValorMatricula === 'string'
              ? valueWatchValorMatricula.replace(/[$.,' ']/g, '')
              : valueWatchValorMatricula
          );
    let inputValueFinance = valueFinance
      ? valueFinance.target.value
      : watchValorFinanciar;

    actualValorFinanciar = actualValorFinanciar.replace(/[$.,' ']/g, '');
    inputValueFinance = String(inputValueFinance).replace(/[$.,' ']/g, '');

    actualValorFinanciar = Number(actualValorFinanciar);
    inputValueFinance = Number(inputValueFinance);

    if (
      actualValorFinanciar >= inputValueFinance &&
      inputValueFinance >= 580000
    ) {
      SetBtnActivo(true);
      setSearchInactive(true);
    } else {
      watchValorFinanciar = 0;
      document.getElementById('btnCalculate').click();
      setSearchInactive(false);
      SetBtnActivo(false);
      console.log(
        'el valor tiene que ser mayor de ' +
          inputValueFinance +
          ' y menor que ' +
          actualValorFinanciar
      );
    }
  };
  useEffect(() => {
    const updateUrl = async () => {
      if (Number(watchValorFinanciar) === 0 || !searchInactive) {
        watchValorFinanciar = 0;
        document.getElementById('btnCalculate').click();
        await calculate({
          universityId: watchUniversidad,
          amount: watchValorFinanciar,
          taxCategory,
        });
        
      }
    };
    updateUrl();
    if (watchValorMatricula) {
      handleCalculatevalue();
    }
  }, [watchValorMatricula, searchInactive]);
  const handleProgramaChange = () => {
    const inputElement = document.getElementById('valor_matricula');

    if (inputElement && typeof valueProduct === 'number') {
      const formattedValue = formatCurrencyES(valueProduct);
      // Utiliza setValue para actualizar el valor del campo 'valor_matricula'
      setValue('valor_matricula', formattedValue);
    }
  };
  useEffect(() => {
    if (Number(watchUniversidad) === 4) {
      SetUtbUniversity(true);
      handleProgramaChange();
    }
  }, [watchPrograma, valueProduct]);
  return (
    <>
      <section>
        {Number(watchUniversidad) !== 4 && (
          <>
            {watchUniversidad !== '2' && getValues('nomostra') !== true && (
              <div className="my-4 grid grid-cols-2 gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6 mb-10">
                <div className="text-center">
                  <p>
                    <strong>¡Es momento de subir tu orden de matrícula!</strong>
                  </p>
                  <p>
                    Para continuar con tu solicitud de financiación, es
                    necesario que la Universidad te haya generado una Orden de
                    Matrícula vigente (PDF)
                  </p>
                </div>
                <div className="mb-6">
                  <div className="col-span-2 h-full lg:col-span-1">
                    <FileDrop
                      className="col-span-4 md:col-span-2 lg:col-span-1"
                      fileState={FILE_STATE.TO_BE_SENT}
                      uploadProps={uploadProps}
                      {...FILES.ORDEN_MATRICULA}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {utbUniversity && (
          <div className="my-4 grid grid-cols-2 gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
            <p className="col-span-1">
              <b>Selecciona el Producto</b>
            </p>
            <div className="col-span-2 h-full lg:col-span-1">
              <FormInput
                {...InputProps('programa')}
                placeholder="Programa"
                type="select"
                options={programas}
                className="col-span-2"
              />
            </div>
          </div>
        )}
        <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
          <p className="col-start-1 font-bold">
            ¿Cuál es el valor de tu matrícula?:
          </p>
          <FormInput
            {...InputProps('valor_matricula')}
            placeholder={utbUniversity ? '' : 'Valor de tu matrícula'}
            type={utbUniversity ? 'text' : 'currency'}
            id="valor_matricula"
            value={inputValueProduct}
            disabled={utbUniversity}
          />
          {watchLinea?.toString() === '2' && (
            <>
              <p className="col-start-1 font-bold text-custom-purple dark:text-custom-green">
                Este es el 35% condonable:
              </p>
              <DisplayValue
                title="35% condonable"
                value={formatCurrency(watchValorMatricula * 0.35)}
              />
              <p>
                *El beneficio contempla condonar el 35% del valor de la
                matrícula, (si cumples requisitos académicos).
              </p>

              <p className="col-start-1 font-bold text-custom-purple dark:text-custom-green">
                Este el 65% financiable:
              </p>
              <DisplayValue
                title="65% financiable"
                value={formatCurrency(watchValorMatricula * 0.65)}
              />
              <p>*Puedes financiar este valor total o un monto parcial.</p>
            </>
          )}
          <p className="col-start-1 font-bold">
            ¿Cuál es el valor que necesitas financiar?:
          </p>
          <FormInput
            {...InputProps('valor_financiar')}
            placeholder="Valor a financiar"
            type="currency"
            onChange={handleCalculatevalue}
          />
          {watchLinea?.toString() === '2' ? (
            <p>
              *Aquí debes escribir, del <b>65% financiable</b>, cuánto $
              necesitas que te financiemos.
            </p>
          ) : (
            <p>
              *Recuerda tener en cuenta <b>todo</b> lo que dice tu{' '}
              <b>Orden de Matrícula</b>, fechas, descuentos, becas, beneficios,
              costos adicionales o cualquier otro rubro para el pago de tus
              estudios.
            </p>
          )}
          <p className="col-start-1 font-bold">
            ¡Llegó el momento de calcular el tiempo y cuota de tu financiación!
          </p>
          <div className="flex justify-center lg:justify-start">
            <Button
              id="btnCalculate"
              disabled={!btnActivo}
              onClick={() =>
                trigger([
                  'universidad',
                  'nivel',
                  'tiene_financiacion',
                  'valor_matricula',
                  'valor_financiar',
                  'linea',
                  'programa',
                ]).then(async (isValid) => {
                  if (isValid)
                    await calculate({
                      universityId: watchUniversidad,
                      amount: watchValorFinanciar,
                      taxCategory,
                    });
                })
              }
            >
              Calcular
            </Button>
          </div>
        </div>

        <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-4 lg:gap-6">
          <p className="col-span-1">
            <b>Selecciona el plazo</b> en la barra y{' '}
            <b>visualiza el valor aproximado</b> de tu cuota mensual:
          </p>
          <TermSlider
            {...InputProps('plazo')}
            classNameBar="lg:col-span-2 col-span-1"
          />
        </div>
      </section>
    </>
  );
};
export default SimuladorSinApi;
