import React from 'react';

const StepUploadOrderMatricula = () => {
  return (
    <div className=" text-center">
      <p className=" text-center">
        <strong>Cuando la Universidad expida tu orden de matrícula, </strong>
        podrás ingresar aquí nuevamente y seleccionarla para completar tu
        proceso.
        <br />
        <br />
      </p>
    </div>
  );
};
export default StepUploadOrderMatricula;
