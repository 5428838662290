import React from 'react';
import tooltipImag from '../../assets/images/financial-statement/OBJECTS.png';

const PairView = ({ title, value, tooltipText }) => {
  return (
    <div className="flex space-x-4">
      {tooltipText && (
        <div className="tooltip" title={tooltipText}>
          <img src={tooltipImag} alt="Info" />
        </div>
      )}
      <div className="font-bold">{title}</div>
      <div>{value}</div>
    </div>
  );
};

export default PairView;
