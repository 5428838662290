import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const initialState = {
  authenticated: undefined,
  authorized: undefined,
  user: undefined,
  universidadId: undefined,
};

const AuthContext = createContext({
  ...initialState,
  updateAuthContext: () => {},
});

export function AuthProvider({ children }) {
  const [state, setState] = useState(initialState);

  const updateAuthContext = () =>
    Auth.currentAuthenticatedUser()
      .then(async (user) =>
        setState({
          authenticated: true,
          authorized:
            user.signInUserSession.accessToken.payload[
              'cognito:groups'
            ]?.includes('student') || false,
          user: user.attributes,
          universidadId: user.signInUserSession.idToken.payload.universidadId,
        })
      )
      .catch(async (error) => {
        console.error(error);
        setState({
          authenticated: false,
          authorized: false,
          user: undefined,
          universidadId: undefined,
        });
      });

  useEffect(() => {
    updateAuthContext();
  }, []);
  return (
    <AuthContext.Provider value={{ ...state, updateAuthContext }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  const { authenticated, authorized, user, universidadId, updateAuthContext } =
    context;

  const signIn = ({ email, password }) =>
    Auth.signIn(email, password).then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const userAttributes = user.challengeParam.userAttributes;
        return {
          name: !userAttributes.given_name,
          lastName: !userAttributes.family_name,
          phone: !userAttributes.phone_number,
          document: !userAttributes['custom:document'],
        };
      }
      updateAuthContext();
    });

  const completeNewPassword = async ({
    email,
    password,
    newPassword,
    name,
    lastName,
    phone,
    document,
  }) =>
    Auth.signIn(email, password).then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return Auth.completeNewPassword(user, newPassword, {
          given_name: name,
          family_name: lastName,
          phone_number: phone,
          'custom:document': document,
        });
      }
      updateAuthContext();
    });

  const signOut = () => Auth.signOut().then(() => updateAuthContext());

  const signUp = async ({
    name,
    lastName,
    password,
    email,
    phone,
    document,
    university,
  }) =>
    Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: name,
        family_name: lastName,
        email,
        phone_number: phone,
        'custom:document': document,
      },
      clientMetadata: {
        universidadId: university,
      },
    });

  const confirmSignUp = ({ email, code }) => Auth.confirmSignUp(email, code);

  const forgotPassword = ({ email }) => Auth.forgotPassword(email);

  const forgotPasswordSubmit = ({ email, code, password }) =>
    Auth.forgotPasswordSubmit(email, code, password);

  const resendSignUp = ({ email }) => Auth.resendSignUp(email);

  return {
    signIn,
    completeNewPassword,
    signOut,
    signUp,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    resendSignUp,
    authenticated,
    authorized,
    user, // {custom:document, email, family_name, given_name, phone_number, sub}
    universidadId,
  };
}
