import { API } from 'aws-amplify';

const postInsertHistorialEstadoEtapas = (body) =>
  // esto debe apuntar a create-doc-autorization sin el body zapsign
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'consult',
    },
  });
const postConsultCreditRenovation = (body) =>
  // esto debe apuntar a create-doc-autorization sin el body zapsign
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'consult',
    },
  });
const ConsultGeneral = {
  postInsertHistorialEstadoEtapas,
  postConsultCreditRenovation,
};

export default ConsultGeneral;
