import React, { useState, useEffect } from 'react';
import Item from '../DynamicForm/Item';
import DragDropFile from './DragDropFile';
import Textarea from '../DynamicForm/Textarea';
import { useAuth } from '../../context/auth-context';
import OptionList from '../DynamicForm/OptionList';
import { FORM_ITEMS } from '../../constants/form/dynamic-form';

const SupportFile = ({
  loanId,
  name,
  displayName,
  prefix,
  descriptionPlaceholder,
  descriptionLimit,
  items,
  onChange,
  setAttachment,
  required = true,
}) => {
  const [displayAll, setDisplayAll] = useState(required ? true : undefined);

  const { user } = useAuth();

  const [state, setState] = useState(
    items?.reduce(
      (current, item) => ({ ...current, [item.title]: undefined }),
      { descripción: undefined, archivo: undefined }
    ) || {}
  );

  const getOnChange = (title) => (value) =>
    setState((current) => ({ ...current, [title]: value }));

  useEffect(() => {
    if (displayAll) {
      const required = Object.keys(state).find(
        (key) => state[key] === undefined || state[key] === ''
      );
      onChange(required ? undefined : { ...state, respuesta: 'Sí' });
    } else onChange(displayAll === undefined ? undefined : { respuesta: 'No' });
  }, [state, displayAll]);

  return (
    <div className="grid w-full grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
      <div className="space-y-2">
        {!required && (
          <OptionList
            type={FORM_ITEMS.SINGLE_CHOICE}
            options={['Sí', 'No']}
            cols={2}
            onChange={(value) =>
              value !== undefined && setDisplayAll(value === 'Sí')
            }
          />
        )}
        <div className={'space-y-2' + (displayAll ? '' : ' hidden')}>
          {items?.map((field, fieldIndex) => (
            <div key={fieldIndex}>
              {field.title && (
                <h3 className="mb-2">
                  {field.title}:{' '}
                  <span className="font-normal">{field.subTitle}</span>
                </h3>
              )}

              <Item
                {...field}
                onChange={getOnChange(field.title)}
                value={state[field.title]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={'space-y-2' + (displayAll ? '' : ' hidden')}>
        <DragDropFile
          loanId={loanId}
          fileName={`${user['custom:document']}_${name}`}
          fileBaseName={name}
          displayName={displayName}
          prefix={prefix}
          onChange={getOnChange('archivo')}
          setAttachment={setAttachment}
        />
        <Textarea
          placeholder={descriptionPlaceholder}
          onChange={getOnChange('descripción')}
          limit={descriptionLimit}
        />
      </div>
    </div>
  );
};

export default SupportFile;
