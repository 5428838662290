import React from 'react';
import { FORM_ITEMS } from '../../constants/form/dynamic-form';
import EntityList from './EntityList';
import Input from './Input';
import InputFormat from './InputFormat';
import OptionList from './OptionList';
import Select from './Select';
import SupportFile from '../File/SupportFile';
import Items from './Items';
import { Controller } from 'react-hook-form';

const Item = ({ control, index, ...props }) => {
  let ItemComponent;
  switch (props.type) {
    case FORM_ITEMS.SINGLE_CHOICE:
    case FORM_ITEMS.MULTIPLE_CHOICE:
      ItemComponent = OptionList;
      break;
    case FORM_ITEMS.SUPPORT_FILE:
      ItemComponent = SupportFile;
      break;
    case FORM_ITEMS.ENTITY_LIST:
      ItemComponent = EntityList;
      break;
    case FORM_ITEMS.SELECT:
      ItemComponent = Select;
      break;
    case FORM_ITEMS.TEXT:
      ItemComponent = Input;
      break;
    case FORM_ITEMS.CURRENCY:
      ItemComponent = InputFormat;
      break;
    case FORM_ITEMS.ITEMS:
      ItemComponent = Items;
      break;
    case FORM_ITEMS.RAW_TEXT:
      // eslint-disable-next-line react/display-name
      ItemComponent = ({ body }) => <p>{body}</p>;
      break;
    default:
      break;
  }

  return (
    <div className="flex">
      {control ? (
        <Controller
          control={control}
          name={index}
          rules={{
            required: { value: true, message: 'Requerido' },
          }}
          render={({ field: { ...controllerProps } }) => (
            <ItemComponent {...controllerProps} {...props} />
          )}
        />
      ) : (
        <ItemComponent {...props} />
      )}
    </div>
  );
};

export default React.memo(Item);
