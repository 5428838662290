import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import useUpload from '../../hooks/use-upload';
import prettyBytes from 'pretty-bytes';
import { MdClose } from 'react-icons/md';
import { ReactComponent as UploadIcon } from '../../assets/images/financing-request/upload.svg';
import Button from '../DynamicForm/Button';
import ProgressBar from '../shared/ProgressBar';
import { useDialog } from '../../context/dialog-context';
import { DIALOG } from '../../constants/dialog/dialog';

const DragDropFile = ({
  loanId,
  fileName,
  fileBaseName,
  displayName,
  prefix,
  onChange,
  setAttachment,
}) => {
  const dialog = useDialog();
  const [state, setState] = useState('load');
  const { upload, load, clearData, uploadProgress, fileData } = useUpload({
    loanId,
    fileName,
    prefix,
  });

  useEffect(() => {
    setState(fileData ? 'upload' : 'load');
  }, [fileData]);

  const handleUpload = () =>
    upload()
      .then((key) => {
        setState('uploaded');
        onChange(key);
        setAttachment(fileBaseName, key);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const onDrop = useCallback((acceptedFiles) => load(acceptedFiles[0]), []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: { 'application/pdf': ['.pdf'] },
      disabled: state !== 'load',
    });

  const renderLoad = (
    <>
      <p className="font-bold">
        Subir <UploadIcon className="inline h-5 w-auto fill-current" /> Cargar
      </p>
      <p className="text-sm">({displayName})</p>
      {isDragActive ? (
        isDragAccept ? (
          <p className="font-bold">Suelta aquí....</p>
        ) : (
          <p className="font-bold">Documento inválido</p>
        )
      ) : (
        <p className="font-bold">Selecciona o arrastra aquí</p>
      )}
    </>
  );

  const renderUpload = (
    <>
      <p className="font-bold">
        Subir <UploadIcon className="inline h-5 w-auto fill-current" /> Cargar
      </p>
      <p className="text-sm">({displayName})</p>
      <p className="inline-flex">
        {fileData?.path} - {prettyBytes(fileData?.size || 0, {})}
        <button
          onClick={() => clearData()}
          type="button"
          disabled={uploadProgress !== undefined}
          title="Remover"
        >
          <MdClose className="h-6 w-auto fill-current text-error" />
        </button>
      </p>
      <Button className="mt-2" onClick={handleUpload} type="button">
        Confirmar
      </Button>
      <p className="mt-2 block text-xs text-error">
        *Debes confirmar el envió de tu documento
      </p>
    </>
  );

  const renderUploaded = (
    <>
      <p className="font-bold text-custom-green">Enviado</p>
      <p className="text-sm">({displayName})</p>
      <p className="inline-flex">
        {fileData?.path} - {prettyBytes(fileData?.size || 0, {})}
      </p>
    </>
  );

  const body = {
    load: renderLoad,
    upload: renderUpload,
    uploaded: renderUploaded,
  };

  return (
    <div
      {...getRootProps()}
      className="relative flex aspect-video w-full flex-col items-center justify-center rounded-3xl bg-inherit p-4 text-center text-custom-gray shadow-light dark:shadow-dark"
    >
      <input {...getInputProps()} />
      {body[state]}
      {uploadProgress && (
        <div className="absolute bottom-0 left-0 w-full px-4 py-2">
          <ProgressBar progress={uploadProgress}></ProgressBar>
        </div>
      )}
    </div>
  );
};

export default DragDropFile;
