import React from 'react';

const Input = ({ title: placeholder, onChange, value = '', type = 'text' }) => {
  return (
    <input
      id={placeholder}
      title={placeholder}
      placeholder={placeholder}
      defaultValue={value}
      type={type}
      onChange={(e) => onChange(e.target.value)}
      className="w-full overflow-hidden text-ellipsis rounded-full bg-inherit py-2 px-4 shadow-light dark:shadow-dark"
    />
  );
};

export default Input;
