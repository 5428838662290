import React from 'react';
import Button from '../../components/DynamicForm/Button';
import { ReactComponent as SigningSucceeded } from '../../assets/images/documents/success-sign.svg';
import { ReactComponent as ProgressSigning } from '../../assets/images/documents/progress-sign.svg';
import { ReactComponent as PendingSigning } from '../../assets/images/documents/pending-sign.svg';
import { useDialog } from '../../context/dialog-context';
import Emoji from '../../components/shared/Emoji';
import { useState, useEffect } from 'react';
import ZapsignService from '../../services/process/zapsign-service';
import { Cache } from 'aws-amplify';
import ShareURL from '../../components/Identity/ShareURL';
import Loading from '../../components/shared/Loading';

const SeguroVida = (props) => {
  const dialog = useDialog();
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  const [loading, setLoading] = useState(true);
  const [creditInfo, setCreditInfo] = useState([]);
  const fetchCreditData = async () => {
    let allNew = true;
    if (cacheCredit.numero !== null) {
      const body = {
        creditId: cacheCredit.numero,
        rol: 'consultLifeInsurance',
      };
      await ZapsignService.postSeguroVidaZapsign(body)
        .then((res) => {
          let result = res.listZapsignUser.find(
            (item) => item.idStudiant === cacheCredit.estudiante.id
          );
          const infos = [];
          if (result !== undefined) {
            const inf = {
              name:
                cacheCredit.estudiante.nombre +
                ' ' +
                cacheCredit.estudiante.apellido,
              documento: cacheCredit.estudiante.documento,
              status: result.status,
              signUrl: result.signUrl,
              type: 'studiant',
            };
            if (result.status !== 'signed') {
              allNew = false;
            }
            infos.push(inf);
          }
          if (cacheCredit?.codeudores[0]?.id !== undefined) {
            result = res.listZapsignUser.find(
              (item) => item.idCodeudor === cacheCredit.codeudores[0].id
            );
            if (result !== undefined) {
              const inf = {
                name:
                  cacheCredit.codeudores[0].nombre +
                  ' ' +
                  cacheCredit.codeudores[0].apellido,
                documento: cacheCredit.codeudores[0].documento,
                status: result.status,
                signUrl: result.signUrl,
                type: 'codeudor',
              };
              if (result.status !== 'signed') {
                allNew = false;
              }
              infos.push(inf);
            }
          }
          if (cacheCredit?.codeudores[1]?.id !== undefined) {
            result = res.listZapsignUser.find(
              (item) => item.idCodeudor === cacheCredit.codeudores[1].id
            );
            if (result !== undefined) {
              const inf = {
                name:
                  cacheCredit.codeudores[1].nombre +
                  ' ' +
                  cacheCredit.codeudores[1].apellido,
                documento: cacheCredit.codeudores[1].documento,
                status: result.status,
                signUrl: result.signUrl,
                type: 'codeudor',
              };
              if (result.status !== 'signed') {
                allNew = false;
              }
              infos.push(inf);
            }
          }
          setCreditInfo(infos);
          setLoading(false);
          if (allNew) {
            handleProcessComplete();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    fetchCreditData();
    setInterval(fetchCreditData, 10000);
  }, []);
  const handleProcessComplete = () => {
    props.onComplete();
  };
  const showDialog = (url) =>
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p>
          No olvides REGRESAR a este punto luego de completar tu firma y de
          compartir con tus codeudores el enlace.
        </p>
        <p className="font-bold">
          ¡Todavía falta continuar y completar tu proceso!
        </p>
      </div>,
      { onConfirm: () => window.open(url, '_blank') }
    );
  return loading ? (
    <Loading />
  ) : (
    <section>
      <div className="mt-4 grid grid-cols-1 divide-x-0 divide-y-2 lg:grid-cols-3 lg:divide-x-2 lg:divide-y-0">
        {creditInfo.map((req, index) => {
          const isStudent = req.type === 'studiant';
          return (
            <div
              key={index}
              className="flex flex-col space-y-6 px-14 py-4 text-center lg:py-0"
            >
              {isStudent ? (
                <>
                  <div className="font-bold">
                    <p>
                      <b>1. {req.name}</b>
                    </p>
                    <p>
                      <b>{req.documento}</b>
                    </p>
                  </div>
                  <div>
                    <Button onClick={() => showDialog(req.signUrl)}>
                      ¡Firmar!
                    </Button>
                  </div>
                  <p className="flex flex-1 items-center">
                    <i>
                      Se te pedirá una selfie y una foto de tu documento de
                      identidad para firmar
                    </i>
                  </p>
                  {req.status === 'pending' || req.status === 'link-opened' ? (
                    // Si req.status es 'pending'
                    <PendingSigning className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : req.status === 'new' ? (
                    // Si req.status es 'new'
                    <ProgressSigning className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-custom-green" />
                  ) : (
                    // Si req.status es otra cosa
                    <SigningSucceeded className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  )}
                  <div>
                    <p>Estado de la firma:</p>
                    {req.status === 'pending' ||
                    req.status === 'link-opened' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Pendiente
                      </p>
                    ) : req.status === 'new' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        En curso
                      </p>
                    ) : (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Exitosa
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="font-bold">
                    <p>
                      <b>
                        {index + 1}. {req.name}
                      </b>
                    </p>
                    <p>
                      <b>{req.documento}</b>
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <ShareURL URL={req.signUrl} />
                  </div>
                  <p>
                    Tu codeudor debe firmar <i>(</i>
                    <span className="underline">
                      <b>Compártele el enlace</b>
                    </span>
                    <i>
                      , se le pedirá una selfie y una foto de su documento de
                      identidad)
                    </i>
                  </p>

                  {req.status === 'pending' || req.status === 'link-opened' ? (
                    // Si req.status es 'pending'
                    <PendingSigning className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : req.status === 'new' ? (
                    // Si req.status es 'new'
                    <ProgressSigning className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  ) : (
                    // Si req.status es otra cosa
                    <SigningSucceeded className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                  )}
                  <div>
                    <p className="w-auto">Estado de la firma:</p>
                    {req.status === 'pending' ||
                    req.status === 'link-opened' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Pendiente
                      </p>
                    ) : req.status === 'new' ? (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        En curso
                      </p>
                    ) : (
                      <p className="mb-4 text-center text-2xl font-bold text-red-500">
                        Exitosa
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="mx-auto my-8 space-y-8 text-center lg:max-w-2xl">
        <p>
          Comprueba que tu firma y la de tus codeudores diga <br />
          <span className="font-bold">
            <i>
              {' '}
              {'"'}Estado de la firma Exitosa{'"'},
            </i>
          </span>{' '}
          <span className="underline">solo hasta ese momento</span>{' '}
          <span className="font-bold">
            <i>
              habrás
              <br /> terminado tu proceso de legalización.
            </i>
          </span>
        </p>
        <p>
          Después, en máximo 48 horas revisaremos las firmas <br />
          y te notificaremos por correo electrónico que <br />
          todo haya quedado correcto.
        </p>
        <p>
          Simultáneamente, le avisaremos a la Universidad <br />y haremos el
          desembolso.
        </p>
      </div>
    </section>
  );
};

export default SeguroVida;
