export const STEPALERTADMIN = {
  ARREARS: 'En mora',
  INCOMPLETE: 'Autorización',
  FACTORY: 'Fábrica',
  PREAPPROVED: 'Pre-Aprobado',
  LEGALIZATION: 'Legalización',
  LEGALIZED: 'Legalizado',
  COMMITTEE: 'Comité',
  REJECTED: 'Rechazado',
  CANCELED: 'Cancelado',
  RETURNED: 'Devuelto',
  LINECHANGE: 'Cambio de línea',
  PREAPPROVEDANDINA: 'Pre-Aprobado Andina',
};
