import React from 'react';
import { ReactComponent as Tea } from '../../assets/images/tea.svg';
import '../../styles/Loading.css';

function Loading({ loading = true, children, fullScreen = false }) {
  return loading ? (
    <div
      className={`z-40 flex flex-col items-center justify-center space-y-2 bg-light p-4 text-center font-montserrat font-bold text-light dark:bg-dark dark:text-dark ${
        fullScreen ? 'fixed top-0 left-0 h-screen w-screen' : 'h-full w-full'
      }`}
    >
      <Tea className="tea h-24" />
      <span>Cargando...
        <p>
          Esto puede tomar algunos segundos... 
        </p>
        <p>
          Por favor, NO actualices ni cierres esta página. 
        </p> 
        ¡Gracias!</span>
    </div>
  ) : (
    children
  );
}

export default Loading;
