import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import { INFO_ACT_ECO_CODEUDOR } from '../../../constants/form/validations';
import { DIALOG } from '../../../constants/dialog/dialog';
import { useDialog } from '../../../context/dialog-context';
import ConstantesSolicitudService from '../../../services/shared/constantes-solicitud-service';
import FormInput from '../../../components/Input/FormInput';
import useInput from '../../../hooks/use-input';
import ChangeWords from '../../../utils/changeWords';

const InfoActEcoCodeudor = ({
  formConstants,
  number,
  optional = false,
  disabledInput,
}) => {
  const isMounted = useRef(false);
  const dialog = useDialog();
  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const departamentos = [
    `departamento_nacimiento_cod${number}`,
    `departamento_expedicion_cod${number}`,
    `departamento_residencia_cod${number}`,
  ];
  const ciudades = [
    `ciudad_nacimiento_cod${number}`,
    `ciudad_expedicion_cod${number}`,
    `ciudad_residencia_cod${number}`,
  ];

  const watchDepartamentos = getValues(departamentos);
  const watchDisable = watch(`disabled_cod${number}`);
  const extranjero = watch(`tipo_documento_cod${number}`)?.toString() === '3';
  watch(ciudades);

  const documentos = ['documento'];
  const correos = ['correo_electronico'];
  const celulares = ['celular'];

  for (let i = 0; i < number; i++) {
    documentos.push(`documento_cod${i}`);
    correos.push(`correo_electronico_cod${i}`);
    celulares.push(`celular_cod${i}`);
  }
  const watchDocumentos = watch(documentos);
  const watchCorreos = watch(correos);
  const watchCelulares = watch(celulares);

  const validations = useMemo(
    () =>
      INFO_ACT_ECO_CODEUDOR(
        number,
        watchDisable,
        watchDocumentos,
        watchCorreos,
        watchCelulares
      ),
    [number, watchDisable, watchDocumentos, watchCorreos, watchCelulares]
  );

  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations,
    disabled: watchDisable,
  });
  const fetchCiudades = async (i) => {
    const departamento = watchDepartamentos[i];
    if (departamento && isMounted.current) {
      setValue(ciudades[i]);
      if (!Cache.getItem(`CIUDADES_${departamento}`)) {
        setLoading(ciudades[i], true);
        await ConstantesSolicitudService.getCiudades(departamento)
          .then(async (response) =>
            Cache.setItem(`CIUDADES_${departamento}`, response)
          )
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          });
        setLoading(ciudades[i], false);
        setValue(ciudades[i], getValues(ciudades[i]));
      }
    }
  };
  // creates a useEffect for each departamento in watchDepartamentos
  for (let i = 0; i < watchDepartamentos.length; i++) {
    useEffect(() => {
      fetchCiudades(i);
    }, [watchDepartamentos[i]]);
  }

  useEffect(() => {
    if (watchDisable) trigger();
  }, [watchDisable]);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const [formValues, setFormValues] = useState({
    nombres_cod: watch(`nombres_cod${number}`),
    apellidos_cod: watch(`apellidos_cod${number}`),
    correo_electronico_cod: watch(`correo_electronico_cod${number}`),
  });

  const handleChangeToUpperCase = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: ChangeWords.changeFirstInUpperCase(value),
    }));
  };

  const handleChangeToLowerCase = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: ChangeWords.ChangeAllInLowerCase(value),
    }));
  };

  return (
    <>
      <section>
        {optional && (
          <div className="my-4 grid grid-cols-2 gap-4 lg:my-6 lg:grid-cols-6 lg:gap-6">
            <div className="flex flex-row items-center text-xs">
              <FormInput
                {...InputProps(`disabled_cod${number}`)}
                type="checkbox"
                disabled={false}
              />
              <p htmlFor={`disabled_cod${number}`}>
                No deseo agregar un codeudor{number > 0 ? ' adicional' : ''}
              </p>
            </div>
          </div>
        )}
        <h4 className="font-bold">Información del Codeudor {number + 1}</h4>
        <div className="my-4 grid grid-cols-4 gap-4 gap-y-6 lg:my-6 lg:grid-cols-12 lg:gap-6">
          <FormInput
            {...InputProps(`nombres_cod${number}`)}
            disabled={disabledInput}
            placeholder="Nombres"
            type="textChangeWord"
            className="col-span-4 lg:col-span-3"
            value={
              `nombres_cod${number}` in formValues
                ? formValues[`nombres_cod${number}`]
                : formValues.nombres_cod
            }
            onChange={handleChangeToUpperCase}
          />
          <FormInput
            {...InputProps(`apellidos_cod${number}`)}
            disabled={disabledInput}
            placeholder="Apellidos"
            type="textChangeWord"
            className="col-span-2 lg:col-span-3"
            value={
              `apellidos_cod${number}` in formValues
                ? formValues[`apellidos_cod${number}`]
                : formValues.apellidos_cod
            }
            onChange={handleChangeToUpperCase}
          />
          <FormInput
            {...InputProps(`tipo_documento_cod${number}`)}
            disabled={disabledInput}
            placeholder="Tipo documento"
            type="select"
            options={formConstants.TIPO_DOCUMENTO}
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps(`documento_cod${number}`)}
            disabled={disabledInput}
            placeholder="No. Documento"
            type="document"
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps(`fecha_expedicion_cod${number}`)}
            disabled={disabledInput}
            placeholder="Fecha expedición"
            type="date"
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`departamento_expedicion_cod${number}`)}
            disabled={disabledInput}
            placeholder="Departamento de expedición"
            type="select"
            options={formConstants.DEPARTAMENTO}
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`ciudad_expedicion_cod${number}`)}
            disabled={disabledInput}
            placeholder="Ciudad expedición"
            type="select"
            options={Cache.getItem(
              `CIUDADES_${watch(`departamento_expedicion_cod${number}`)}`
            )}
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`fecha_nacimiento_cod${number}`)}
            disabled={disabledInput}
            placeholder="Fecha nacimiento"
            type="date"
            className="col-span-2 lg:col-span-3"
          />
          {!extranjero && (
            <>
              <FormInput
                {...InputProps(`departamento_nacimiento_cod${number}`)}
                disabled={disabledInput}
                placeholder="Departamento de nacimiento"
                type="select"
                options={formConstants.DEPARTAMENTO}
                className="col-span-2 lg:col-span-3"
              />
              <FormInput
                {...InputProps(`ciudad_nacimiento_cod${number}`)}
                disabled={disabledInput}
                placeholder="Ciudad nacimiento"
                type="select"
                options={Cache.getItem(
                  `CIUDADES_${watch(`departamento_nacimiento_cod${number}`)}`
                )}
                className="col-span-2"
              />
            </>
          )}
          <FormInput
            {...InputProps(`direccion_cod${number}`)}
            disabled={disabledInput}
            placeholder="Dirección residencia"
            type="text"
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps(`departamento_residencia_cod${number}`)}
            disabled={disabledInput}
            placeholder="Departamento residencia"
            type="select"
            options={formConstants.DEPARTAMENTO}
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps(`ciudad_residencia_cod${number}`)}
            disabled={disabledInput}
            placeholder="Ciudad residencia"
            type="select"
            options={Cache.getItem(
              `CIUDADES_${watch(`departamento_residencia_cod${number}`)}`
            )}
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`telefono_cod${number}`)}
            disabled={disabledInput}
            placeholder="Teléfono fijo"
            type="home-phone"
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`estado_civil_cod${number}`)}
            disabled={disabledInput}
            placeholder="Estado civil"
            type="select"
            options={formConstants.ESTADO_CIVIL}
            className="col-span-2"
          />
          <FormInput
            {...InputProps(`celular_cod${number}`)}
            disabled={disabledInput}
            placeholder="Celular"
            type="cell-phone"
            className="col-span-2 lg:col-span-3"
          />
          <FormInput
            {...InputProps(`correo_electronico_cod${number}`)}
            disabled={disabledInput}
            placeholder="Correo"
            type="textChangeWord"
            className="col-span-2 lg:col-span-3"
            value={
              `correo_electronico_cod${number}` in formValues
                ? formValues[`correo_electronico_cod${number}`]
                : formValues.correo_electronico_cod
            }
            onChange={handleChangeToLowerCase}
          />
          <FormInput
            {...InputProps(`parentesco_cod${number}`)}
            disabled={disabledInput}
            placeholder="Relación con el estudiante"
            type="select"
            options={formConstants.PARENTESCO}
            className="col-span-2 lg:col-span-3"
          />
        </div>
      </section>
      <section>
        <h4 className="font-bold">
          Actividad económica del Codeudor {number + 1}
        </h4>
        <div className="my-6 grid grid-cols-4 gap-6 gap-y-6 lg:grid-cols-12">
          <div className="col-span-4 grid grid-cols-4 gap-6 gap-y-6 lg:col-span-8 lg:grid-cols-8">
            <FormInput
              {...InputProps(`ocupacion_cod${number}`)}
              disabled={disabledInput}
              placeholder="Ocupación y fuente de ingresos principal"
              type="select"
              options={formConstants.OCUPACION}
              className="col-span-4 lg:col-span-5"
            />
            <FormInput
              {...InputProps(`ingresos_cod${number}`)}
              disabled={disabledInput}
              placeholder="(+) INGRESOS mensuales"
              type="currency"
              className="col-span-4 lg:col-span-3"
            />
          </div>
          <FormInput
            {...InputProps(`egresos_cod${number}`)}
            disabled={disabledInput}
            placeholder="(-) GASTOS mensuales"
            type="currency"
            className="col-span-4 h-full lg:col-span-3"
          />
        </div>
      </section>
    </>
  );
};

export default InfoActEcoCodeudor;
