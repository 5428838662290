import React, { useEffect, useRef, useState } from 'react';
import { GoTriangleDown } from 'react-icons/go';

const Accordion = ({ title, children, index, activeIndex, setActiveIndex }) => {
  const [height, setHeight] = useState();
  const handleSetIndex = (index) =>
    activeIndex !== index && setActiveIndex(index);

  const elementRef = useRef();
  const observer = useRef(
    new ResizeObserver((entries) => setHeight(entries[0].contentRect.height))
  );

  useEffect(() => {
    if (elementRef.current) observer.current.observe(elementRef.current);
    return () => {
      if (elementRef.current) observer.current.unobserve(elementRef.current);
    };
  }, [elementRef, observer]);

  return (
    <>
      <div
        onClick={() => handleSetIndex(index)}
        className="flex cursor-pointer items-center justify-between rounded-full px-4 py-1 font-bold shadow-light-active transition duration-500 dark:shadow-dark-active"
      >
        <div className="font-bold">
          {index}. {title}
        </div>
        <div className={'flex items-center justify-center'}>
          <GoTriangleDown
            className={`w-4 text-custom-gray transition duration-500 ${
              activeIndex === index ? ' rotate-180' : ''
            }`}
          />
        </div>
      </div>

      <div
        className={
          'overflow-y-clip transition-all duration-300' +
          (activeIndex === index ? ' opacity-100' : ' opacity-0')
        }
        style={{
          maxHeight: activeIndex === index ? height + 'px' : 0,
        }}
      >
        <div ref={elementRef}>{children}</div>
      </div>
    </>
  );
};

export default Accordion;
