import { API } from 'aws-amplify';

const postConsultExternado = (body) =>
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'externado',
    },
  });

const validateStateHistoryStateEtapa = (body) =>
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'externado',
    },
  });

const postStatusExternado = (body) =>
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'externado',
    },
  });

const ExternadoService = {
  postConsultExternado,
  validateStateHistoryStateEtapa,
  postStatusExternado,
};

export default ExternadoService;
