import React, { useEffect, useState } from 'react';
import Item from './Item';

const EntityList = ({
  entity,
  items,
  number,
  cols,
  onChange,
  required = true,
}) => {
  const initialState = items?.reduce(
    (current, item) => ({ ...current, [item.title]: undefined }),
    {}
  );
  const [state, setState] = useState(
    [...Array(number)].map(() => initialState)
  );

  const getOnChange = (i, title) => (value) => {
    setState((current) => ({
      ...current,
      [i]: { ...current[i], [title]: value },
    }));
  };

  useEffect(() => {
    let newState = state;
    if (!required) {
      newState = Object.keys(state)
        .filter((entityKey) =>
          Object.values(state[entityKey]).find(
            (value) => value !== undefined && value !== ''
          )
        )
        .reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {});
    }
    const invalid =
      Object.keys(newState).find((entityKey) => {
        return Object.keys(newState[entityKey]).find((itemKey, j) => {
          const value = newState[entityKey][itemKey];
          const required = items[j].required === true;
          return required && (value === undefined || value === '');
        });
      }) !== undefined;
    onChange(invalid ? undefined : Object.values(state));
  }, [state]);

  const gridCols = {
    1: 'xl:grid-cols-1 lg:grid-cols-1 grid-cols-1',
    2: 'xl:grid-cols-2 lg:grid-cols-1 grid-cols-1',
    3: 'xl:grid-cols-3 lg:grid-cols-2 grid-cols-1',
    4: 'xl:grid-cols-4 lg:grid-cols-2 grid-cols-1',
  };

  return (
    <div className="w-full space-y-2">
      {[...Array(number)].map((_, i) => (
        <div key={i}>
          <h4 className="mb-2 text-sm font-bold">
            {entity} {i + 1}
          </h4>
          <div
            className={`grid grid-flow-dense gap-x-4 gap-y-2 ${gridCols[cols]}`}
          >
            {items.map((item, j) => (
              <Item {...item} onChange={getOnChange(i, item.title)} key={j} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EntityList;
