import { API } from 'aws-amplify';

const get = ({ creditoNumero, usuarioId }) =>
	API.get('estrella', '/identity', {
		queryStringParameters: {
			...(creditoNumero && { creditoNumero }),
			...(usuarioId && { usuarioId }),
		},
	});

const getURL = ({ creditoNumero, usuarioId, codeudorId, redirectURL }) =>
	API.get('estrella', '/identity/URL', {
		queryStringParameters: {
			...(creditoNumero && { creditoNumero }),
			...(usuarioId && { usuarioId }),
			...(codeudorId && { codeudorId }),
			redirectURL,
		},
	});

const IdentityService = {
	get,
	getURL,
};

export default IdentityService;
