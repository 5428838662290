import React from 'react';
import '../../styles/ToggleSwitch.css';

function ToggleSwitch({ darkMode, toggleDarkMode }) {
  return (
    <label className="switch">
      <input
        type="checkbox"
        defaultChecked={darkMode}
        onClick={() => toggleDarkMode()}
      />
      <span className="slider round"></span>
    </label>
  );
}

export default ToggleSwitch;
