import React from 'react';
import ConsultApiOrden from './ConsultApiOrden';
import { Cache } from 'aws-amplify';
import { useState } from 'react';
import Emoji from '../shared/Emoji';
import StepUploadOrderMatricula from './StepUploadOrderMatricula';
import StepFirstMenssage from './StepFirstMenssage';

const ProcessFinancingOrden = ({
  onSeleccionChange,
  univesidadId,
  stepUploadOrder,
}) => {
  const [lineasArray] = useState(Cache.getItem('ordenList'));
  let univesidadExternado = false;
  let stepUploadOrderMatricula = false;
  const handleSeleccionChange = (nuevaSeleccion) => {
    onSeleccionChange(nuevaSeleccion);
  };
  if (univesidadId === '2') {
    univesidadExternado = true;
  }
  if (
    univesidadId === 'stepUploadOrder' &&
    stepUploadOrder === 'Universidad Externado de Colombia'
  ) {
    univesidadExternado = true;
    stepUploadOrderMatricula = true;
  }
  return lineasArray.length > 0 ? (
    <ConsultApiOrden
      key="ConsultApiOrden"
      lineasArray={lineasArray}
      onSeleccionChange={handleSeleccionChange}
    />
  ) : univesidadExternado ? (
    <div className=" text-center processFinancingOrden">
      <h1>
        <b>
          ¡Hola
          <Emoji symbol="waving-hand" className="inline h-5" />
          <Emoji
            symbol="smiling-face-with-smiling-eyes"
            className="inline h-5"
          />
        </b>
      </h1>
      <br />
      <p className=" text-center">
        <b>
          Acabamos de conectarnos con tu Universidad
          <br />
          <br />
          y nos informa que no tienes órdenes de pago vigentes. Para continuar
          con tu solicitud de
          <br />
          financiación, regresa cuando te la hayan generado y realiza el
          proceso.
        </b>

        <br />
        <br />
        {stepUploadOrderMatricula ? (
          <StepUploadOrderMatricula />
        ) : (
          <StepFirstMenssage />
        )}
      </p>
    </div>
  ) : (
    <div className=" text-center processFinancingOrden">
      <h1>
        <b>
          ¡Hola
          <Emoji symbol="waving-hand" className="inline h-5" />
          <Emoji
            symbol="smiling-face-with-smiling-eyes"
            className="inline h-5"
          />
        </b>
      </h1>
      <br />
      <p className=" text-center">
        <b>¡Recuerda!</b> <br />
        Para continuar con tu solicitud de financiación,
        <br />
        es necesario que la Universidad te haya generado una Orden de Matrícula
        vigente (PDF).
        <br />
        <br />
        Si ya la tienes continúa, si aún no la has recibido, ¡Regresa cuando te
        la hayan entregado!
        <br />
      </p>
    </div>
  );
};
export default ProcessFinancingOrden;
