import React, { useState } from 'react';
// import Input from '../Input/Input';
import Button from '../DynamicForm/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WhatsappShareButton, EmailShareButton } from 'react-share-rc-18';
import { ReactComponent as EmailIcon } from '../../assets/images/identity/email.svg';
import { BsWhatsapp } from 'react-icons/bs';

const ShareURL = ({ URL }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-row-reverse mr-4">
        {/* <Input value={URL} readOnly={true} /> */}
        <div className="flex justify-center space-x-4">
          <WhatsappShareButton url={URL}>
            <BsWhatsapp className="h-6 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
          </WhatsappShareButton>
          <EmailShareButton url={URL}>
            <EmailIcon className="h-6 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
          </EmailShareButton>
        </div>
      </div>
      <div>
        <CopyToClipboard text={URL} onCopy={() => setCopied(true)}>
          <Button>{copied ? 'Copiado!' : 'Compartir'}</Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ShareURL;
