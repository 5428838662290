import React, { useState, useEffect } from 'react';

function ProgressBar({ progress = 0 }) {
  const getProgressPercentage = (progress) => {
    if (progress < 0) return 0;
    else if (progress > 1) return 100;
    else return +(progress * 100).toFixed(2);
  };
  const [progressPercentage, setProgressPercentage] = useState(
    getProgressPercentage(progress)
  );
  useEffect(
    () => setProgressPercentage(getProgressPercentage(progress)),
    [progress]
  );
  return (
    <div className="h-1 w-full bg-custom-light-gray">
      <div
        className="h-full bg-custom-purple transition-width duration-300 dark:bg-custom-green"
        style={{ width: `${progressPercentage}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
