import React, { useState, useEffect } from 'react';
import Loading from '../../components/shared/Loading';
import ArchivoService from '../../services/file/archivo-service';
import { DIALOG } from '../../constants/dialog/dialog';
import { useDialog } from '../../context/dialog-context';
import { useAuth } from '../../context/auth-context';
import { FILE_STATE } from '../../constants/files/fileState';
import { FILES } from '../../constants/files/files';
import Button from '../../components/DynamicForm/Button';
import { Link } from 'react-router-dom';
import FormInput from '../../components/Input/FormInput';
import { useForm } from 'react-hook-form';
import { REQUEST_FORM_DOC } from '../../constants/form/validations';
import useInput from '../../hooks/use-input';
import CreditoService from '../../services/credit/credito-service';
import { formatCurrency } from '../../utils/format';
import FileDrop from '../../components/File/FileDrop';
import useUpload from '../../hooks/use-upload';
import { Cache } from 'aws-amplify';
import ProcessFinancingOrden from '../../components/FinancingRequest/ProcessFinancingOrden';
import ExternadoService from '../../services/stage/externado-service';
import moment from 'moment';
import SimuladorExternadoOrden from '../../components/FinancingRequest/SimuladorExternadoOrden';
import ConsultGeneral from '../../services/consult/consult-general';

const RequestFormDoc = () => {
  const [loading, setLoading] = useState(true);
  const dialog = useDialog();
  const { user } = useAuth();
  const [file, setFile] = useState();
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  const [formConstants] = useState(Cache.getItem('form-constants'));
  const [creditoNumero, setCreditoNumero] = useState();
  const [mensaje, setMensaje] = useState();
  const [mensajeArchive, setMensajeArchive] = useState('Archivo requerido');
  const [showMessage, setShowMessage] = useState(false);
  const [valorFinanciar, setValorFinanciar] = useState();
  const [showedDialogOnce, setShowedDialogOnce] = useState(false);
  const [showInfoOrden, setShowInfoOrden] = useState(false);
  const [showSimulador, setShowSimulador] = useState(false);
  const [infoOrdenMatricula, setInfoOrdenMatricula] = useState(false);

  function obtenerFechaNoVencida(fecha1, fecha2, status, VlPag, VlPagExt) {
    const fechaInicio = moment(fecha1, 'DD/MM/YYYY');
    const fechaFin = moment(fecha2, 'DD/MM/YYYY');
    const fechaHoy = moment();

    if (fechaHoy.isBefore(fechaInicio)) {
      if (status === 'fecha') {
        return fechaInicio.format('DD/MM/YYYY');
      } else {
        return VlPag;
      }
    }
    if (fechaHoy.isBefore(fechaFin)) {
      if (status === 'fecha') {
        return fechaFin.format('DD/MM/YYYY');
      } else {
        return VlPagExt;
      }
    }
    return '';
  }

  async function consultCredit(loanId) {
    await CreditoService.get(loanId)
      .then(async (res) => {
        // se deberia validar si la cache existe y si la data de la cache es la misma para no remplazar o posiblemente quitarnos una consulta
        Cache.setItem('requestCredit', res);
        Cache.setItem('cacheCredit', res);
        console.log(formConstants.TIPO_DOCUMENTO);
        const resultado = formConstants.TIPO_DOCUMENTO.find(
          (documentFind) => documentFind.label === res.estudiante.tipoDocumento
        );

        if (
          cacheCredit.universidad === 'Universidad Externado de Colombia' ||
          cacheCredit.universidadId === '2'
        ) {
          const arrayResp = [];
          const body = {
            doc: user['custom:document'],
            docType: resultado ? resultado.value : '1',
            rol: 'consult',
          };
          const resp = await ExternadoService.postConsultExternado(body);
          if (resp.success === true) {
            let count = 1;
            if (resp.info[0] === undefined) {
              console.log('errr');
            } else if (
              resp.info[0].EMensaje.includes(
                'No existen órdenes de pago para el '
              )
            ) {
              console.log('errrs');
            } else {
              for (const r of resp.info) {
                const VlPag = obtenerFechaNoVencida(
                  r.Fecord,
                  r.Fecext,
                  '',
                  r.VlPag,
                  r.VlPagExt
                );
                const linea = cacheCredit.linea;
                if (
                  (linea === 'Línea Pro' || linea === 'Línea Andina') &&
                  r.Origen === 'A' &&
                  VlPag !== undefined &&
                  parseInt(VlPag) > 580000
                ) {
                  arrayResp.push({
                    id: count,
                    nOrdenMatricula: r.OpbelTest,
                    fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
                    valorPago: VlPag,
                    typeOrigen: r.Txtorigen,
                    typeOrigenText: r.Text,
                    seguro: r.VlSeguro,
                    hide: false,
                    matricula: VlPag - r.VlSeguro,
                  });
                  count++;
                } else if (
                  linea === 'Línea Estrella' &&
                  (r.Origen === 'A' ||
                    r.Origen === 'B' ||
                    r.Origen === 'C' ||
                    r.Origen === 'E' ||
                    r.Origen === 'H' ||
                    r.Origen === 'J' ||
                    r.Origen === 'K') &&
                  VlPag !== undefined &&
                  parseInt(VlPag) > 580000
                ) {
                  arrayResp.push({
                    id: count,
                    nOrdenMatricula: r.OpbelTest,
                    fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
                    valorPago: VlPag,
                    typeOrigen: r.Txtorigen,
                    typeOrigenText: r.Text,
                    seguro: r.VlSeguro,
                    hide: false,
                    matricula: VlPag - r.VlSeguro,
                  });
                  count++;
                }
              }
            }
          }
          Cache.setItem('ordenList', arrayResp);
          setShowInfoOrden(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  }

  const fetchFile = () => {
    ArchivoService.getAll({ usuarioId: user.sub, tipoId: 'ORDEN_MATRICULA' })
      .then((response) => {
        if (
          response.estudiante.archivos[0] &&
          (cacheCredit.universidad === 'Universidad Externado de Colombia' ||
            cacheCredit.universidadId === '2')
        ) {
          // revisar validacion para diferenciar cuando si tengo la orde antes y cuando no
          if (response.estudiante.archivos[0].rutaEnBlanco) {
            ArchivoService.getAll({
              creditoNumero: cacheCredit.numero,
              tipoId: 'ORDEN_MATRICULA',
            })
              .then((response) => {
                const objetoString = JSON.stringify(infoOrdenMatricula);
                if (response.estudiante.archivos[0].id !== undefined)
                  ArchivoService.update({
                    archivoId: response.estudiante.archivos[0].id,
                    nombre: infoOrdenMatricula.nOrdenMatricula,
                    nombreOriginal: infoOrdenMatricula.nOrdenMatricula,
                    ruta: null,
                    estado: FILE_STATE.APPROVED,
                    rutaEnBlanco: objetoString,
                  });
              })
              .catch((error) => {
                console.error(error);
                dialog.info(DIALOG.N11, {
                  devInfo: error.message || error.code || error.type,
                });
              });
          }
        }
        setFile(response.estudiante.archivos[0]);
        setCreditoNumero(response.creditoNumero);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });
  };
  const {
    register,
    getValues,
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });
  const fetchValorFinanciar = () =>
    CreditoService.getValorFinanciar(creditoNumero)
      .then((response) => setValorFinanciar(response))
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });
  const updateValorFinanciar = (valor) =>
    CreditoService.updateValorFinanciar(creditoNumero, valor)
      .then((response) => {
        setValorFinanciar(response);
        setMensaje('Se actualizó correctamente el valor a financiar');
        setMensajeArchive('Archivo OK');
      })
      .catch((error) => {
        if (error.response.data.message !== 'error value was not updated') {
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        }
      });
  const showValorFinanciarDialog = () => {
    if (!showedDialogOnce) {
      dialog.info(DIALOG.N15, {
        onConfirm: () => setFocus('valorFinanciar'),
      });
      setShowedDialogOnce(true);
    }
  };
  const cambiarEstadoFabricaSP = async (creditId) => {
    try {
      const body = {
        loanIds: creditId,
        statusActual: 1,
        rol: 'cambiarEstadoFabricaSP',
      };
      return await ConsultGeneral.postInsertHistorialEstadoEtapas(body);
    } catch (error) {
      return error;
    }
    
  };
  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: REQUEST_FORM_DOC,
  });
  useEffect(() => {
    fetchFile();
  }, []);
  useEffect(() => {}, [file, creditoNumero, valorFinanciar]);
  useEffect(() => {
    if (creditoNumero) {
      fetchValorFinanciar();
      consultCredit(creditoNumero);
    }
  }, [creditoNumero]);
  const uploadProps = useUpload({});
  useEffect(() => {
    if (creditoNumero && file)
      uploadProps.setFileInfo({
        loanId: creditoNumero,
        fileId: file.id,
        fileName: `${user['custom:document']}_${FILES[file.tipoId]?.fileName}`,
      });
  }, [creditoNumero, file]);
  const onUpdateValorFinanciar = async (values, e) => {
    e.preventDefault();
    try {
      if (uploadProps.fileData) {
        await uploadProps.upload();
        await updateValorFinanciar(values.valorFinanciar);
        await cambiarEstadoFabricaSP(cacheCredit.numero);
        setFile((current) => ({
          ...current,
          estados: [{ estado: FILE_STATE.UNDER_REVIEW }],
        }));
      } else {
        setShowMessage(true);
      }
      if (
        cacheCredit.universidad === 'Universidad Externado de Colombia' ||
        cacheCredit.universidadId === '2'
      ) {
        ArchivoService.getAll({
          creditoNumero: cacheCredit.numero,
          tipoId: 'ORDEN_MATRICULA',
        })
          .then((response) => {
            const objetoString = JSON.stringify(infoOrdenMatricula);
            if (response.estudiante.archivos[0].id !== undefined)
              ArchivoService.update({
                archivoId: response.estudiante.archivos[0].id,
                nombre: infoOrdenMatricula.nOrdenMatricula,
                nombreOriginal: infoOrdenMatricula.nOrdenMatricula,
                ruta: null,
                estado: FILE_STATE.APPROVED,
                rutaEnBlanco: objetoString,
              });
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          });
        await updateValorFinanciar(values.valorFinanciar);
        setFile((current) => ({
          ...current,
          estados: [{ estado: FILE_STATE.UNDER_REVIEW }],
        }));
        // mostrar dialogo
      }
    } catch (error) {
      console.error(error);
      if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
      else
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
    }
  };
  const handleSeleccionChangeApi = (nuevaSeleccion) => {
    setShowSimulador(true);
    setInfoOrdenMatricula(nuevaSeleccion);
  };
  const handleSimuladorSeleccionChangeApi = (nuevaSeleccion) => {
    console.log(nuevaSeleccion);
  };

  const [btnActivo, SetBtnActivo] = useState(false);
  const handleCalculatevalue = (valueFinance) => {
    const actualValorFinanciar = valorFinanciar.valorFinanciar;
    let inputValue = valueFinance.target.value;
    const cleanedValue = inputValue.replace(/[$.,]/g, '');

    inputValue = Number(cleanedValue);
    const multiplicacion = actualValorFinanciar * 0.15;
    const suma = actualValorFinanciar + multiplicacion;
    const resta = actualValorFinanciar - multiplicacion;

    if (inputValue >= resta && inputValue <= suma) {
      SetBtnActivo(true);
    } else {
      SetBtnActivo(false);
      console.log(
        'el valor tiene que ser mayor de ' + resta + ' y menor que ' + suma
      );
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <>
      {showInfoOrden === false && (
        <section className="flex-1 space-y-8 py-8">
          <p className="font-bold">
            Estás a 1 paso de completar esta sección, ¡sólo hace falta tu
            documento “Orden de Matrícula”!
          </p>
          <p className="font-bold">¿Ya lo tienes? súbelo a continuación:</p>

          <div className="grid-layaut-agregar-o-m text-center ml-2">
            <div>
              <p>
                <b>¿Ya tienes tu Orden de Matrícula?</b>
                <p className="justify-center">
                  Recuerda sólo se formalizará el resultado cuando subas este
                  documento.
                </p>
              </p>
            </div>
            <div className="mb-6">
              {showMessage && (
                <p className="text-[#E7496C] ml-2 mb-3">
                  <div className=" text-center w-full">{mensajeArchive}</div>
                </p>
              )}
              <FileDrop
                className="col-span-4 md:col-span-2 lg:col-span-1"
                fileState={file.estados[0]?.estado}
                originalFileName={file.nombreOriginal}
                uploadProps={uploadProps}
                {...FILES[file.tipoId]}
              />
            </div>
            <div>
              <p className="text-center">
                <b>VALORES QUE SELECCIONASTE PARA EL ESTUDIO:</b>
                <br />
                Valor solicitado:{' '}
                <b>{formatCurrency(valorFinanciar?.valorFinanciar)}</b>
                <br />
                Plazo elegido: <b>{cacheCredit.plazo} meses</b>
              </p>
            </div>
          </div>

          <div>
            {mensaje && <p className="text-center">{mensaje}</p>}
            {(file.estados[0].estado === FILE_STATE.TO_BE_SENT ||
              file.estados[0].estado === FILE_STATE.REJECTED) && (
              <form
                className=" grid-layaut-agregar-o-m text-center justify-center ml-2"
                onSubmit={handleSubmit(onUpdateValorFinanciar)}
              >
                <div>
                  <p>
                    <b>¿Cuál es valor definitivo que necesitas financiar?:</b>
                  </p>
                </div>
                <span onClick={showValorFinanciarDialog} className="ml-2">
                  <FormInput
                    {...InputProps('valorFinanciar')}
                    placeholder="Valor definitivo a financiar"
                    type="currency"
                    onChange={handleCalculatevalue}
                  />
                </span>

                <p className="text-[#E7496C] ml-2 ">
                  <div className=" text-justify w-full">
                    ¡Recuerda!
                    <ol>
                      <li>
                        1. El Valor definitivo a financiar no puede variar más
                        de un 15% del valor solicitado en el estudio.
                      </li>
                      <li>
                        2. El plazo y línea de financiación no pueden ser
                        modificados.
                      </li>
                      <li>
                        3. La información de aplicación del estudiante y
                        codeudor(es) no puede ser cambiada.
                      </li>
                    </ol>
                    <p className=" text-light dark:text-dark">
                      En caso de requerir estas modificaciones, deberás
                      presentar una nueva solicitud desde 0.
                    </p>
                  </div>
                </p>
                <div className=" col-start-1 col-span-3">
                  <Button
                    type="submit"
                    loading={uploadProps.uploadProgress || isSubmitting}
                    className=" disabled:opacity-50:"
                    // disabled={disabled || isLoading}
                    disabled={!btnActivo}
                  >
                    Confirmar y enviar
                  </Button>
                </div>
              </form>
            )}
          </div>
        </section>
      )}
      {showInfoOrden === true && showSimulador === false && (
        <ProcessFinancingOrden
          key="ProcessFinancingOrden"
          onSeleccionChange={handleSeleccionChangeApi}
          univesidadId={'stepUploadOrder'}
          stepUploadOrder={cacheCredit.universidad}
        />
      )}
      {showSimulador === true && (
        <SimuladorExternadoOrden
          key="FinancialSimulator"
          uploadProps={uploadProps}
          showLinea={true}
          infoOrdenMatricula={infoOrdenMatricula}
          onSeleccionChange={handleSimuladorSeleccionChangeApi}
        />
      )}
      {showSimulador === true && (
        <form
          onSubmit={handleSubmit(onUpdateValorFinanciar)}
          className="flex flex-col items-center"
        >
          <p className="my-2">
            Este es el valor previo que nos habías indicado:
          </p>
          <p>{formatCurrency(valorFinanciar.valorFinanciar)}</p>
          <p className="my-2 text-rose-500">
            <b>VALOR DEFINITIVO A FINANCIAR:</b>
          </p>
          <div className="grid grid-cols-2 items-center gap-4">
            <span onClick={showValorFinanciarDialog}>
              <FormInput
                {...InputProps('valorFinanciar')}
                placeholder="Valor definitivo a financiar"
                type="currency"
                onChange={handleCalculatevalue}
              />
            </span>

            <Button
              type="submit"
              loading={uploadProps.uploadProgress || isSubmitting}
              disabled={!btnActivo}
            >
              Confirmar y enviar
            </Button>
          </div>
          <br />
          <div>
            <p className="text-[#E7496C] ml-2 ">
              <div className=" text-center w-full">
                ¡Recuerda!
                <ol>
                  <li>
                    1. El Valor definitivo a financiar no puede variar más de un
                    15% del valor solicitado en el estudio.
                  </li>
                  <li>
                    2. El plazo y línea de financiación no pueden ser
                    modificados.
                  </li>
                  <li>
                    3. La información de aplicación del estudiante y
                    codeudor(es) no puede ser cambiada.
                  </li>
                </ol>
                <p className=" text-light dark:text-dark">
                  En caso de requerir estas modificaciones, deberás presentar
                  una nueva solicitud desde 0.
                </p>
              </div>
            </p>
          </div>
        </form>
      )}
      <div className="flex justify-center p-6">
        <Link to="..">
          <Button className="w-full" onClick={() => {}}>
            {'<'} Regresar
          </Button>
        </Link>
      </div>
    </>
  );
};

export default RequestFormDoc;
